import { formatDate, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';
import { useOne } from '@refinedev/core';

import { Announcement, ModificationEntityType } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<Announcement>({
  resourceName: resourceNames.announcement,
});

export const formatAnnouncementStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Inactive':
      return 'danger';
    default:
      return 'pending';
  }
};

export const announcementResource = createResource({
  name: resourceNames.announcement,
  label: 'Announcement',
  fields: defineFields([
    'id',
    'title',
    'content',
    'ctaLink',
    'startDate',
    'endDate',
    'status',
    'createdAt',
    'updatedAt',
  ]),
  allowDownload: true,
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityType: ModificationEntityType.Announcement,
    },
  },
  defaultValues: {},
  defaultSorter: [
    {
      field: 'createdAt',
      order: 'desc',
    },
  ],
  controls: {
    components: {
      image: {
        type: 'image-upload',
        config: {
          label: 'Image',
          span: 2,
        },
      },
      title: {
        type: 'text',
        config: {
          label: 'Title',
        },
      },
      content: {
        type: 'textarea',
        config: {
          label: 'Content',
        },
      },
      ctaLink: {
        type: 'text',
        config: {
          label: 'CTA Link',
        },
      },
      startDate: {
        type: 'date',
        config: {
          label: 'Start Date',
        },
      },
      endDate: {
        type: 'date',
        config: {
          label: 'End Date',
        },
      },
      status: {
        type: 'select',
        config: {
          label: 'Status',
          options: [
            {
              label: 'Active',
              value: 'Active',
            },
            {
              label: 'Inactive',
              value: 'Inactive',
            },
          ],
        },
      },
    },
    componentConfigDefaults: {
      span: 2,
    },
  },
  filterControls: {
    title: {
      type: 'text',
      config: {
        label: 'Title',
      },
      operator: 'contains',
    },
    startDate: {
      type: 'date',
      config: {
        label: 'Start Date',
      },
      operator: 'gte',
    },
    endDate: {
      type: 'date',
      config: {
        label: 'End Date',
      },
      operator: 'lte',
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: [
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
        ],
      },
      operator: 'eq',
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'title',
      header: 'Title',
      accessorKey: 'title',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'startDate',
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'endDate',
      header: 'End Date',
      accessorKey: 'endDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.getValue<string>();
        return <StatusField status={formatAnnouncementStatus(status)} label={status} />;
      },
    },
  ],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Description',
        fields: [
          'title',
          'content',
          'ctaLink',
          'startDate',
          'endDate',
          'status',
          'createdAt',
          'updatedAt',
        ],
        displays: {
          ctaLink: {
            label: 'CTA Link',
            render: (data: Announcement, modifications) => {
              if (modifications?.newValues?.ctaLink) {
                return (
                  <modifications.ModificationDiff
                    oldValue={modifications.oldValues.ctaLink}
                    newValue={modifications.newValues.ctaLink}
                  />
                );
              }

              if (!data.ctaLink) return <></>;
              return (
                <a
                  href={data.ctaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {data.ctaLink}
                </a>
              );
            },
          },
        },
      }),
      defineCardSection({
        title: 'Image',
        collapsible: 'expand-by-default',
        fields: ['imageId'],
        displays: {
          imageId: {
            span: 2,
            label: '',
            render: (data: Announcement, modifications) => {
              const ImageComponent = ({ id, alt }: { id: string; alt: string }) => {
                const { data: imageData } = useOne({
                  resource: resourceNames.document,
                  id,
                  meta: { fields: ['encodedData'] },
                });
                return imageData?.data ? (
                  <img
                    src={imageData.data.encodedData}
                    alt={alt}
                    className="max-w-full max-h-[400px] object-contain rounded-md"
                  />
                ) : null;
              };

              if (!data.imageId && !modifications?.newValues?.imageId) return 'No image';

              return (
                <div className="flex flex-col gap-4 sm:flex-row sm:gap-8">
                  {modifications?.newValues?.imageId && (
                    <div>
                      <p className="font-semibold mb-2">New Image:</p>
                      <ImageComponent
                        id={modifications.newValues.imageId}
                        alt="New announcement image"
                      />
                    </div>
                  )}
                  {modifications?.oldValues?.imageId && (
                    <div>
                      <p className="font-semibold mb-2">Old Image:</p>
                      <ImageComponent
                        id={modifications.oldValues.imageId}
                        alt="Old announcement image"
                      />
                    </div>
                  )}
                  {!modifications?.newValues?.imageId &&
                    !modifications?.oldValues?.imageId &&
                    data.imageId && <ImageComponent id={data.imageId} alt="Announcement image" />}
                </div>
              );
            },
          },
        },
      }),
    ],
  }),
});
