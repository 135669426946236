import { Avatar, AvatarImage, AvatarFallback } from '@amfintech/react-admin-ui';

export function RecentSales() {
  return (
    <div className="space-y-8">
      <div className="flex items-center">
        <Avatar className="h-9 w-9">
          <AvatarImage src="/avatars/01.png" alt="Avatar" />
          <AvatarFallback>OM</AvatarFallback>
        </Avatar>
        <div className="ml-4 space-y-1">
          <p className="text-sm font-medium leading-none">Berjaya Corporation</p>
          <p className="text-sm text-muted-foreground">26 Aug 2024 14:32:22</p>
        </div>
        <div className="ml-auto font-medium text-green-500">MYR 14,100,000.00</div>
      </div>
      <div className="flex items-center">
        <Avatar className="flex h-9 w-9 items-center justify-center space-y-0 border">
          <AvatarImage src="/avatars/02.png" alt="Avatar" />
          <AvatarFallback>JL</AvatarFallback>
        </Avatar>
        <div className="ml-4 space-y-1">
          <p className="text-sm font-medium leading-none">ANT Sdn Bhd</p>
          <p className="text-sm text-muted-foreground">26 Aug 2024 14:32:22</p>
        </div>
        <div className="ml-auto font-medium text-green-500">MYR 14,000.00</div>
      </div>
      <div className="flex items-center">
        <Avatar className="h-9 w-9">
          <AvatarImage src="/avatars/03.png" alt="Avatar" />
          <AvatarFallback>IN</AvatarFallback>
        </Avatar>
        <div className="ml-4 space-y-1">
          <p className="text-sm font-medium leading-none">Isabella Nguyen Florist</p>
          <p className="text-sm text-muted-foreground">25 Aug 2024 11:02:22</p>
        </div>
        <div className="ml-auto font-medium text-green-500">MYR 12,999.00</div>
      </div>
      <div className="flex items-center">
        <Avatar className="h-9 w-9">
          <AvatarImage src="/avatars/04.png" alt="Avatar" />
          <AvatarFallback>WK</AvatarFallback>
        </Avatar>
        <div className="ml-4 space-y-1">
          <p className="text-sm font-medium leading-none">William Kim Associate</p>
          <p className="text-sm text-muted-foreground">25 Aug 2024 07:32:22</p>
        </div>
        <div className="ml-auto font-medium text-green-500">MYR 132,999.00</div>
      </div>
      <div className="flex items-center">
        <Avatar className="h-9 w-9">
          <AvatarImage src="/avatars/05.png" alt="Avatar" />
          <AvatarFallback>SD</AvatarFallback>
        </Avatar>
        <div className="ml-4 space-y-1">
          <p className="text-sm font-medium leading-none">Tropicana Group</p>
          <p className="text-sm text-muted-foreground">25 Aug 2024 04:32:22</p>
        </div>
        <div className="ml-auto font-medium text-green-500">MYR 12,999.00</div>
      </div>
    </div>
  );
}
