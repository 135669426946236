import { HiOutlineEye } from 'react-icons/hi';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  formatDate,
  StatusField,
} from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import {
  ModificationEntityType,
  OrderStatus,
  Payment,
  Signatory,
  SignatoryStatus,
  Transaction,
  TransactionStatus,
  type Order,
} from '~/api';
import { useApproveOrderMutation } from '~/hooks/use-approve-order';
import { useRejectOrderMutation } from '~/hooks/use-reject-order';

import { resourceNames } from './resource-names';
import { formatTransactionStatus } from './transaction-resource';

const { defineFields, defineCardSection, defineShowPage, defineRelatedResourceSection } =
  createHelpers<Order>({
    resourceName: resourceNames.order,
  });

export const formatOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.Approved:
      return 'success';
    case OrderStatus.Pending:
      return 'info';
    case OrderStatus.Rejected:
      return 'danger';
    default:
      return 'pending';
  }
};

export const formatSignatoryStatus = (status: SignatoryStatus) => {
  switch (status) {
    case SignatoryStatus.Completed:
      return 'success';
    default:
      return 'pending';
  }
};

export const orderResource = createResource({
  name: resourceNames.order,
  label: 'Orders',
  fields: defineFields([
    'id',
    'referenceNumber',
    'originId',
    'userId',
    {
      user: ['email', 'name'],
    },
    'tocSignedAt',
    'fatcaSignedAt',
    'status',
    {
      transactions: [
        'referenceNumber',
        'transactionDatetime',
        'transactionType',
        'amount',
        'transactionStatus',
      ],
    },
    {
      payments: [
        'referenceNumber',
        'amount',
        'currency',
        'transactedAt',
        'paymentMethod',
        'status',
      ],
    },
    {
      signatories: ['name', 'signature', 'status', 'provider'],
    },
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {},
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityType: ModificationEntityType.Order,
    },
  },
  filterControls: {
    referenceNumber: {
      type: 'text',
      config: {
        label: 'Reference Number',
      },
      operator: 'contains',
    },
    status: {
      type: 'select',
      config: {
        label: 'Order Status',
        options: Object.entries(OrderStatus).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  allowDownload: true,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'referenceNumber',
      header: 'Reference Number',
      accessorKey: 'referenceNumber',
      cell: (data) => {
        const refNumber = data.cell.getValue<string>();
        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {refNumber}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.cell.getValue<OrderStatus>();
        return <StatusField status={formatOrderStatus(status)} label={status} />;
      },
    },
    {
      id: 'tocSignedAt',
      header: 'TOC Signed',
      accessorKey: 'tocSignedAt',
      cell: (data) => {
        const datetime = data.cell.getValue<Date>();
        return datetime ? formatDate(datetime, { formatType: 'dateAndTime' }) : '-';
      },
    },
    {
      id: 'fatcaSignedAt',
      header: 'FATCA Signed',
      accessorKey: 'fatcaSignedAt',
      cell: (data) => {
        const datetime = data.cell.getValue<Date>();
        return datetime ? formatDate(datetime, { formatType: 'dateAndTime' }) : '-';
      },
    },
    {
      id: 'createdAt',
      header: 'Created At',
      accessorKey: 'createdAt',
      cell: (data) => {
        const datetime = data.cell.getValue<Date>();
        return <>{formatDate(datetime, { formatType: 'dateAndTime' })}</>;
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    actions: (_, option) => {
      const { mutate: approveMutation } = useApproveOrderMutation();
      const { mutate: rejectMutation } = useRejectOrderMutation();

      return (
        <>
          {option?.data?.status === OrderStatus.Pending && !option?.modification && (
            <div className="flex gap-2">
              <Button onClick={() => approveMutation(option?.id)}>Approve</Button>
              <Button variant="danger" onClick={() => rejectMutation(option?.id)}>
                Reject
              </Button>
            </div>
          )}
        </>
      );
    },
    sections: [
      defineCardSection({
        title: 'Order Details',
        collapsible: 'expand-by-default',
        fields: [
          'referenceNumber',
          'originId',
          'tocSignedAt',
          'fatcaSignedAt',
          'status',
          'createdAt',
          'updatedAt',
        ],
        displays: {
          referenceNumber: {
            label: 'Reference Number',
          },
          originId: {
            label: 'Origin ID',
          },
          tocSignedAt: {
            label: 'TOC Signed At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          fatcaSignedAt: {
            label: 'FATCA Signed At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          status: {
            label: 'Status',
          },
          createdAt: {
            label: 'Created At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          updatedAt: {
            label: 'Updated At',
            type: 'date',
            formatType: 'dateAndTime',
          },
        },
      }),
      defineRelatedResourceSection<Transaction>({
        relatedResourceName: resourceNames.transaction,
        fields: ['id'],
        relatedResourceFields: [
          'id',
          'referenceNumber',
          'transactionDatetime',
          'transactionType',
          'amount',
          'transactionStatus',
        ],
        title: 'Transactions',
        filterConfig: {
          collapsible: 'expand-by-default',
        },
        columns: ({ LinkToDetails }) => [
          {
            id: 'referenceNumber',
            header: 'Reference Number',
            accessorKey: 'referenceNumber',
            cell: (data) => {
              const refNumber = data.cell.getValue<string>();
              return (
                <LinkToDetails className="font-semibold" resourceId={data.row.original.id}>
                  {refNumber}
                </LinkToDetails>
              );
            },
          },
          {
            id: 'transactionType',
            header: 'Transaction Type',
            accessorKey: 'transactionType',
          },
          {
            id: 'amount',
            header: 'Amount',
            accessorKey: 'amount',
          },
          {
            id: 'transactionStatus',
            header: 'Transaction Status',
            accessorKey: 'transactionStatus',
            cell: (data) => {
              const status = data.cell.getValue<TransactionStatus>();
              return <StatusField status={formatTransactionStatus(status)} label={status} />;
            },
          },
          {
            id: 'transactionDatetime',
            header: 'Transaction Datetime',
            accessorKey: 'transactionDatetime',
            cell: (data) => {
              const datetime = data.cell.getValue<Date>();
              return datetime ? formatDate(datetime, { formatType: 'dateAndTime' }) : '-';
            },
          },
        ],
        getFilterConfig: ({ id }) => ({
          permanent: [
            {
              field: 'orderId',
              operator: 'eq',
              value: id,
            },
          ],
        }),
      }),
      defineRelatedResourceSection<Payment>({
        relatedResourceName: resourceNames.payment,
        fields: ['id'],
        relatedResourceFields: ['id', 'referenceNumber', 'amount', 'currency', 'paymentProof'],
        title: 'Payments',
        filterConfig: {
          collapsible: 'expand-by-default',
        },
        columns: () => [
          {
            id: 'referenceNumber',
            header: 'Reference Number',
            accessorKey: 'referenceNumber',
          },
          {
            id: 'amount',
            header: 'Amount',
            accessorKey: 'amount',
          },
          {
            id: 'currency',
            header: 'Currency',
            accessorKey: 'currency',
          },
          {
            id: 'paymentProof',
            header: 'Payment Proof',
            accessorKey: 'paymentProof',
            cell: (data) => {
              const paymentProof = data.cell.getValue<string>();
              if (!paymentProof) return 'No payment proof';
              return (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant={'solid'} size="sm" className="gap-2">
                      <HiOutlineEye className="h-4 w-4" />
                      View
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <div className="flex justify-center items-center h-full">
                      <img
                        src={paymentProof}
                        alt="Payment Proof"
                        className="max-w-full max-h-full"
                      />
                    </div>
                  </DialogContent>
                </Dialog>
              );
            },
          },
        ],
        getFilterConfig: ({ id }) => ({
          permanent: [
            {
              field: 'orderId',
              operator: 'eq',
              value: id,
            },
          ],
        }),
      }),
      defineRelatedResourceSection<Signatory>({
        relatedResourceName: resourceNames.signatory,
        fields: ['id'],
        relatedResourceFields: ['name', 'signature', 'status', 'provider'],
        title: 'Signatories',
        filterConfig: {
          collapsible: 'expand-by-default',
        },
        columns: ({ LinkToDetails }) => [
          {
            id: 'name',
            header: 'Name',
            accessorKey: 'name',
          },
          {
            id: 'provider',
            header: 'Provider',
            accessorKey: 'provider',
          },
          {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            cell: (data) => {
              const status = data.cell.getValue<SignatoryStatus>();
              return <StatusField status={formatSignatoryStatus(status)} label={status} />;
            },
          },
          {
            id: 'signature',
            header: 'Signature',
            accessorKey: 'signature',
            cell: (data) => {
              const signature = data.cell.getValue<string>();
              if (!signature) return 'No signature';
              return (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant={'solid'} size="sm" className="gap-2">
                      <HiOutlineEye className="h-4 w-4" />
                      View
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <div className="flex justify-center items-center h-full">
                      <img src={signature} alt="Signature" className="max-w-full max-h-full" />
                    </div>
                  </DialogContent>
                </Dialog>
              );
            },
          },
        ],
        getFilterConfig: ({ id }) => ({
          permanent: [
            {
              field: 'orderId',
              operator: 'eq',
              value: id,
            },
          ],
        }),
      }),
    ],
  }),
});
