import { createHelpers, createResource, sentenceCase } from '@amfintech/refine-react-admin';

import {
  AccountType,
  ModificationEntityType,
  Profile,
  Status
} from '~/api';
import { ProfileShowPage } from '~/pages/profile/profile-show';

import { resourceNames } from './resource-names';

const {
  defineFields,
  createTableComponent,
  createResourceEditDialog,
  createResourceCreateButton,
} = createHelpers<Profile>({
  resourceName: resourceNames.profile,
});

export const formatUserStatus = (status?: Status) => {
  switch (status) {
    case Status.Active:
      return 'success';
    case Status.Inactive:
      return 'pending';
    case Status.Closed:
      return 'danger';
    case Status.ProfileLocked:
      return 'warn';
    default:
      return 'pending';
  }
};

export const customerResource = createResource({
  identifier: resourceNames.customer,
  name: resourceNames.profile,
  label: 'Profiles',
  fields: defineFields([
    'id',
    'type',
    'fullName',
    'salutation',
    'phoneNo',
    'nationality',
    'idType',
    'idNumber',
    'idExpiryDate',
    'dateOfBirth',
    'cityOfBirth',
    'countryOfBirth',
    'gender',
    'bumiputera',
    'race',
    'maritalStatus',
    'religion',
    'mothersMaidenName',
    'email',
    'permanentAddress',
    'mailingAddress',
    'city',
    'state',
    'postcode',
    'employmentStatus',
    'occupation',
    'jobIndustry',
    'profession',
    'companyName',
    'natureOfBusiness',
    'companyAddress',
    'officePhoneNo',
    'estimatedNetWorth',
    'annualIncome',
    'sourceOfFund',
    'purposeOfInvestment',
    'expectedInvestmentAmount',
    'frequencyOfTransaction',
    'politicalExposedPerson',
    'prominentRole',
    'taxResidency',
    'declaration',
    'safRating',
    'marketingPush',
    'amlSanctionScreeningPoint',
    'employmentType',
    'monthlyIncome',
    'sourceOfWealth',
    { users: ['id'] },
  ]),
  defaultValues: {},
  list: {
    filters: {
      required: true,
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityType: ModificationEntityType.User,
    },
  },
  filterControls: {
    idNumber: {
      type: 'text',
      config: {
        label: 'ID Number',
      },
      operator: 'contains',
    },
    type: {
      type: 'select',
      config: {
        label: 'Account Type',
        options: [
          { label: 'Individual', value: AccountType.Individual },
          { label: 'Corporate', value: AccountType.Corporate },
        ],
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'idNumber',
      header: 'ID Number',
      accessorKey: 'idNumber',
      cell: (data) => {
        const idNumber = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {idNumber}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'idType',
      header: 'ID Type',
      accessorKey: 'idType',
      cell: (data) => {
        return sentenceCase(data.cell.getValue<string>());
      },
    },
    {
      id: 'email',
      header: 'Email Address',
      accessorKey: 'email',
    },
    {
      id: 'users',
      header: 'User ID',
      accessorKey: 'users',
      cell: (data) => {
        return data.row.original.users.map((user) => user.id).join(', ');
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: {
    title: 'Customer Profile',
    fields: [
      'id',
      'salutation',
      'fullName',
      'mothersMaidenName',
      'gender',
      'religion',
      'race',
      'maritalStatus',
      'bumiputera',
      'nationality',
      'idType',
      'idNumber',
      'idExpiryDate',
      'dateOfBirth',
      'cityOfBirth',
      'countryOfBirth',
      'phoneNo',
      'email',
      'permanentAddress',
      'mailingAddress',
      'city',
      'state',
      'postcode',
      { accounts: ['masterAccountNo'] },
      { users: ['status'] },
      'type',
      'employmentStatus',
      'occupation',
      'jobIndustry',
      'profession',
      'companyName',
      'natureOfBusiness',
      'companyAddress',
      'officePhoneNo',
      'estimatedNetWorth',
      'annualIncome',
      'sourceOfFund',
      'purposeOfInvestment',
      'expectedInvestmentAmount',
      'frequencyOfTransaction',
      'politicalExposedPerson',
      'prominentRole',
      'taxResidency',
      'declaration',
      'safRating',
      'marketingPush',
      'amlSanctionScreeningPoint',
      'employmentType',
      'monthlyIncome',
      'sourceOfWealth',
      'highNetWorth',
    ],
    component: ({ queryResult }) => (
      <ProfileShowPage
        profile={queryResult?.data?.data}
        createTableComponent={createTableComponent}
        createResourceEditDialog={createResourceEditDialog}
        createResourceCreateButton={createResourceCreateButton}
      />
    ),
  },
});
