import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { Document } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<Document>({
  resourceName: resourceNames.document,
});

export const documentResource = createResource({
  name: resourceNames.document,
  label: 'Document',
  meta: {
    hide: true,
  },
  fields: defineFields([
    'id',
    'name',
    'mimeType',
    'data',
  ]),
  defaultValues: {},
  columns: () => [],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          'name',
          'mimeType',
          'data',
        ],
      }),
    ],
  }),
});
