import { HiOutlineEye } from 'react-icons/hi';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  formatDate,
  StatusField,
  Switch,
} from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { AccountType, TransactionStatus, type Transaction } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<Transaction>({
  resourceName: resourceNames.transaction,
});

export const formatTransactionStatus = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.Void:
      return 'warn';
    case TransactionStatus.Approved:
      return 'success';
    case TransactionStatus.Submitted:
      return 'info';
    case TransactionStatus.Rejected:
      return 'danger';
    default:
      return 'pending';
  }
};

export const transactionResource = createResource({
  name: resourceNames.transaction,
  label: 'Transactions',
  fields: defineFields([
    'id',
    'referenceNumber',
    'transactionDatetime',
    'transactionType',
    {
      account: [
        'masterAccountNo',
        {
          profile: ['type', 'companyName', 'fullName', 'idNumber', 'idType'],
        },
      ],
    },
    { fund: ['fundName'] },
    { switchToFund: ['fundName'] },
    'amount',
    'navPrice',
    'quantity',
    'salesCharge',
    'transactionStatus',
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {},
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
  },
  filterControls: {
    '.account.is.profile.is.type': {
      type: 'toggle-switch',
      config: {
        label: 'Account Type',
        required: true,
        toggleItems: Object.entries(AccountType).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
    },
    referenceNumber: {
      type: 'text',
      config: {
        label: 'Transaction Reference Number',
      },
      operator: 'contains',
    },
    transactionStatus: {
      type: 'select',
      config: {
        label: 'Transaction Status',
        options: Object.entries(TransactionStatus).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  allowDownload: true,
  defaultSorter: [{ field: 'transactionDatetime', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'referenceNumber',
      header: 'Transaction Reference Number',
      accessorKey: 'referenceNumber',
      cell: (data) => {
        const refNumber = data.cell.getValue<string>();
        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {refNumber}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'transactionDatetime',
      header: 'Transaction DateTime',
      accessorKey: 'transactionDatetime',
      cell: (data) => {
        const datetime = data.cell.getValue<Date>();
        return <>{formatDate(datetime, { formatType: 'dateAndTime' })}</>;
      },
    },
    {
      id: 'transactionType',
      header: 'Action',
      accessorKey: 'transactionType',
    },
    {
      id: 'customerName',
      header: 'Client/ Customer Name',
      accessorKey: 'account.profile',
      cell: (data) => {
        const profile = data.cell.getValue();
        return profile?.type === AccountType.Corporate ? profile.companyName : profile.fullName;
      },
    },
    {
      id: 'idNumber',
      header: 'ID Number',
      accessorKey: 'account.profile.idNumber',
    },
    {
      id: 'masterAccountNo',
      header: 'Master Account Number',
      accessorKey: 'account.masterAccountNo',
    },
    {
      id: 'fundName',
      header: 'Fund Name',
      accessorKey: 'fund.fundName',
    },
    {
      id: 'amount',
      header: 'Amount/Units',
      accessorKey: 'amount',
      cell: (data) => {
        const amount = data.cell.getValue<number>();
        return Number(amount)?.toLocaleString('en-US', {
          style: 'decimal',
          currency: 'MYR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      id: 'transactionStatus',
      header: 'Transaction Status',
      accessorKey: 'transactionStatus',
      cell: (data) => {
        const status = data.cell.getValue<TransactionStatus>();
        return <StatusField status={formatTransactionStatus(status)} label={status} />;
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Transaction Details',
        collapsible: 'expand-by-default',
        fields: [
          'referenceNumber',
          'transactionDatetime',
          'transactionType',
          {
            account: [
              'masterAccountNo',
              { profile: ['companyName', 'fullName', 'idNumber', 'idType'] },
            ],
          },
          { fund: ['fundName'] },
          { switchToFund: ['fundName'] },
          'amount',
          'navPrice',
          'quantity',
          'transactionStatus',
          'salesCharge',
          'updatedAt',
          'createdAt',
        ],
        displays: {
          referenceNumber: {
            label: 'Transaction Reference Number',
          },
          transactionDatetime: {
            label: 'Transaction Date',
            type: 'date',
            formatType: 'dateAndTime',
          },
          transactionType: {
            label: 'Transaction Type',
          },
          'fund.fundName': {
            label: 'Fund Name',
          },
          'switchToFund.fundName': {
            label: 'Switch To Fund Name',
          },
          amount: {
            label: 'Amount/ Units',
            render: (data: Transaction) =>
              data.amount?.toLocaleString('en-US', { style: 'currency', currency: 'MYR' }),
          },
          navPrice: {
            label: 'Nav Price',
          },
          quantity: {
            label: 'Unit Switch',
          },
          salesCharge: {
            label: 'Sales Charge (%)',
            render: (data: Transaction) => `${data.salesCharge}%`,
          },
          transactionStatus: {
            label: 'Transaction Status',
          },
          createdAt: {
            label: 'Created At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          updatedAt: {
            label: 'Last Updated At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          'account.profile.highNetWorth': {
            label: 'High Net Worth',
            render: (data: Transaction) => {
              return <Switch checked={data.account.profile?.highNetWorth ?? false} disabled />;
            },
          },
        },
      }),
    ],
  }),
});
