import { Button, Card, CardContent } from '@amfintech/react-admin-ui';
import { useNavigation } from '@refinedev/core';

import { resourceNames } from '../../resources/resource-names';

interface AdminRoleCardProps {
  roleId: string;
  role: string;
  description: string;
  totalUsers: number;
}

export const AdminRoleCard = ({ roleId, role, description, totalUsers }: AdminRoleCardProps) => {
  const navigateTo = useNavigation();

  return (
    <Card>
      <CardContent className="p-4">
        <div className="flex items-center space-x-4 mb-4">
          <div className="flex-1">
            <h3 className="text-lg font-semibold">{role}</h3>
          </div>
        </div>
        <div className="mb-4">
          <span className="block text-sm font-medium text-gray-700 mb-1">Description</span>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
        <div className="text-sm text-gray-500 mb-4">Assigned to {totalUsers} users</div>
        <Button
          variant="twoTone"
          className="w-full"
          onClick={() => navigateTo.show(resourceNames.adminRole, roleId)}
        >
          View
        </Button>
      </CardContent>
    </Card>
  );
};
