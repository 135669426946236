import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { FundDocument } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<FundDocument>({
  resourceName: resourceNames.fundDocument,
});

export const fundDocumentResource = createResource({
  name: resourceNames.fundDocument,
  label: 'Fund Document',
  meta: {
    hide: true,
  },
  fields: defineFields([
    'id',
    'displayName',
    'documentLink',
    'fundCode',
  ]),
  defaultValues: {},
  columns: () => [],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          'displayName',
          'documentLink',
          'fundCode',
        ],
      }),
    ],
  }),
});
