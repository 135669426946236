import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { User } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<User>({
  resourceName: resourceNames.user,
});

export const userResource = createResource({
  name: resourceNames.user,
  label: 'User',
  meta: {
    hide: true,
  },
  fields: defineFields(['id', 'name', 'username', 'email', 'status']),
  defaultValues: {},
  columns: () => [],
  show: defineShowPage({
    makerChecker: {
      redirectToResource: resourceNames.customer,
    },
    sections: [
      defineCardSection({
        title: 'Details',
        fields: ['name', 'username', 'email', 'status'],
      }),
    ],
  }),
});
