import { formatDate, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { AccountType, ConsentStatus, ConsentType, ModificationEntityType, ProfileConsent } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<ProfileConsent>({
  resourceName: resourceNames.profileConsent,
});

export const formatConsentStatus = (status: ConsentStatus) => {
  switch (status) {
    case ConsentStatus.Active:
      return 'success';
    case ConsentStatus.Revoked:
      return 'danger';
    default:
      return 'pending';
  }
};

export const profileConsentResource = createResource({
  name: resourceNames.profileConsent,
  label: 'Profile Consents',
  fields: defineFields([
    'id',
    'issuerProfileId',
    {
      issuerProfile: ['id', 'type', 'companyName', 'fullName'],
    },
    'recipientProfileId',
    {
      recipientProfile: ['id', 'type', 'companyName', 'fullName'],
    },
    'consentType',
    'status',
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {},
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityType: ModificationEntityType.ProfileConsent,
    },
  },
  filterControls: {
    consentType: {
      type: 'select',
      config: {
        label: 'Consent Type',
        options: Object.entries(ConsentType).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
      operator: 'eq',
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: Object.entries(ConsentStatus).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  allowDownload: true,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'id',
      header: '#',
      accessorKey: 'id',
      cell: (data) => {
        return <LinkToDetails resourceId={data.row.original.id}>{data.row.index + 1}</LinkToDetails>;
      },
    },
    {
      id: 'recipientProfile',
      header: 'Recipient',
      accessorKey: 'recipientProfile',
      cell: (data) => {
        const profile = data.cell.getValue<ProfileConsent['recipientProfile']>();
        const name = profile?.type === 'Corporate' ? profile?.companyName : profile?.fullName;
        return name;
      },
    },
    {
      id: 'issuerProfile',
      header: 'Issuer',
      accessorKey: 'issuerProfile',
      cell: (data) => {
        const profile = data.cell.getValue<ProfileConsent['issuerProfile']>();
        const name = profile?.type === 'Corporate' ? profile?.companyName : profile?.fullName;
        return name;
      },
    },
    {
      id: 'consentType',
      header: 'Consent Type',
      accessorKey: 'consentType',
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.cell.getValue<ConsentStatus>();
        return <StatusField status={formatConsentStatus(status)} label={status} />;
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Consent Details',
        collapsible: 'expand-by-default',
        fields: [
          { issuerProfile: ['type', 'companyName', 'fullName', 'idNumber'] },
          { recipientProfile: ['type', 'companyName', 'fullName', 'idNumber'] },
          'consentType',
          'status',
          'createdAt',
          'updatedAt',
        ],
        displays: {
          issuerProfile: {
            label: 'Issuer',
            render: (data, modifications) => {
              const profile = data.issuerProfile ?? { type: AccountType.Corporate, companyName: '-', fullName: '-' };
              const field = profile?.type === AccountType.Corporate ? 'companyName' : 'fullName';
              const name = profile?.[field];

              if (modifications) {
                return (
                  <modifications.ModificationDiff
                    oldValue={modifications.oldValues.issuerProfile?.id}
                    newValue={modifications.newValues.issuerProfile?.id}
                    getModifiedResource={{
                      resourceName: resourceNames.profile,
                      id: modifications.newValues.issuerProfile.connect.id,
                      field,
                    }}
                  />
                );
              }

              return <span>{name}</span>;
            },
          },
          recipientProfile: {
            label: 'Recipient',
            render: (data, modifications) => {
              const profile = data.recipientProfile ?? { type: AccountType.Corporate, companyName: '-', fullName: '-' };
              const field = profile?.type === AccountType.Corporate ? 'companyName' : 'fullName';
              const name = profile?.[field];

              if (modifications) {
                return (
                  <modifications.ModificationDiff
                    oldValue={modifications.oldValues.recipientProfile?.id}
                    newValue={modifications.newValues.recipientProfile?.id}
                    getModifiedResource={{
                      resourceName: resourceNames.profile,
                      id: modifications.newValues.recipientProfile.connect.id,
                      field,
                    }}
                  />
                );
              }

              return <span>{name}</span>;
            },
          },
          consentType: {
            label: 'Consent Type',
          },
          status: {
            label: 'Status',
          },
          createdAt: {
            label: 'Created At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          updatedAt: {
            label: 'Updated At',
            type: 'date',
            formatType: 'dateAndTime',
          },
        },
        additionalDisplayItems: [{
            label: 'Issuer ID Number',
            position: {
              after: 'recipientProfile',
            },
            value: (data) => {
            return data.issuerProfile?.idNumber;
          },
        }, {
          label: 'Recipient ID Number',
          position: {
            after: 'recipientProfile',
          },
          value: (data) => {
            return data.recipientProfile?.idNumber;
          },
        }],
      }),
    ],
  }),
});
