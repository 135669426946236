import {
  Bar,
  BarChart,
  CartesianGrid,
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
  XAxis,
  YAxis,
} from '@amfintech/react-admin-ui';

const chartData = [
  { month: 'January', individual: 186, corporate: 300 },
  { month: 'February', individual: 305, corporate: 520 },
  { month: 'March', individual: 237, corporate: 480 },
  { month: 'April', individual: 73, corporate: 400 },
  { month: 'May', individual: 209, corporate: 370 },
  { month: 'June', individual: 214, corporate: 350 },
];
const chartConfig = {
  individual: {
    label: 'Individual',
    color: 'hsl(var(--chart-1))',
  },
  corporate: {
    label: 'Corporate',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig;

export function Overview() {
  return (
    <ChartContainer config={chartConfig}>
      <BarChart accessibilityLayer data={chartData}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartLegend content={<ChartLegendContent />} />
        <YAxis tickLine={false} axisLine={false} tickMargin={8} tickCount={5} />
        <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="dashed" />} />
        <Bar dataKey="individual" fill="var(--color-individual)" radius={4} />
        <Bar dataKey="corporate" fill="var(--color-corporate)" radius={4} />
      </BarChart>
    </ChartContainer>
  );
}
