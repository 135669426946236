import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { Signatory } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<Signatory>({
  resourceName: resourceNames.signatory,
});

export const signatoryResource = createResource({
  name: resourceNames.signatory,
  label: 'Signatory',
  meta: {
    hide: true,
  },
  fields: defineFields([
    'id',
    'name',
    'signature',
    'status',
    'provider',
    'orderId',
  ]),
  defaultValues: {},
  columns: () => [],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          'name',
          'signature',
          'status',
          'provider',
          'orderId',
        ],
      }),
    ],
  }),
}); 