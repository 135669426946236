import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Byte: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  _count?: Maybe<AccountCount>;
  accountPortfolios: Array<AccountPortfolio>;
  applicationDate?: Maybe<Scalars['DateTime']['output']>;
  applicationRefId?: Maybe<Scalars['String']['output']>;
  applicationStatus?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  masterAccountNo: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['String']['output']>;
  statements: Array<Statement>;
  transaction: Array<Transaction>;
  updatedAt: Scalars['DateTime']['output'];
};


export type AccountAccountPortfoliosArgs = {
  cursor?: InputMaybe<AccountPortfolioWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccountPortfolioScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccountPortfolioOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountPortfolioWhereInput>;
};


export type AccountProfileArgs = {
  where?: InputMaybe<ProfileWhereInput>;
};


export type AccountStatementsArgs = {
  cursor?: InputMaybe<StatementWhereUniqueInput>;
  distinct?: InputMaybe<Array<StatementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StatementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StatementWhereInput>;
};


export type AccountTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type AccountCount = {
  __typename?: 'AccountCount';
  accountPortfolios: Scalars['Int']['output'];
  statements: Scalars['Int']['output'];
  transaction: Scalars['Int']['output'];
};

export type AccountCountAggregate = {
  __typename?: 'AccountCountAggregate';
  _all: Scalars['Int']['output'];
  applicationDate: Scalars['Int']['output'];
  applicationRefId: Scalars['Int']['output'];
  applicationStatus: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  masterAccountNo: Scalars['Int']['output'];
  profileId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AccountCreateManyProfileInput = {
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo: Scalars['String']['input'];
};

export type AccountCreateManyProfileInputEnvelope = {
  data: Array<AccountCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<AccountCreateManyProfileInputEnvelope>;
};

export type AccountCreateNestedOneWithoutTransactionInput = {
  connect?: InputMaybe<AccountWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AccountCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<AccountUncheckedCreateWithoutTransactionInput>;
};

export type AccountCreateOrConnectWithoutProfileInput = {
  create: AccountUncheckedCreateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateOrConnectWithoutTransactionInput = {
  create: AccountUncheckedCreateWithoutTransactionInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateWithoutProfileInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioCreateNestedManyWithoutAccountInput>;
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo: Scalars['String']['input'];
  statements?: InputMaybe<StatementCreateNestedManyWithoutAccountInput>;
  transaction?: InputMaybe<TransactionCreateNestedManyWithoutAccountInput>;
};

export type AccountListRelationFilter = {
  every?: InputMaybe<AccountWhereInput>;
  none?: InputMaybe<AccountWhereInput>;
  some?: InputMaybe<AccountWhereInput>;
};

export type AccountMaxAggregate = {
  __typename?: 'AccountMaxAggregate';
  applicationDate?: Maybe<Scalars['DateTime']['output']>;
  applicationRefId?: Maybe<Scalars['String']['output']>;
  applicationStatus?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  masterAccountNo?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AccountMinAggregate = {
  __typename?: 'AccountMinAggregate';
  applicationDate?: Maybe<Scalars['DateTime']['output']>;
  applicationRefId?: Maybe<Scalars['String']['output']>;
  applicationStatus?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  masterAccountNo?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AccountOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AccountOrderByWithRelationInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioOrderByRelationAggregateInput>;
  applicationDate?: InputMaybe<SortOrderInput>;
  applicationRefId?: InputMaybe<SortOrderInput>;
  applicationStatus?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  masterAccountNo?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByWithRelationInput>;
  profileId?: InputMaybe<SortOrderInput>;
  statements?: InputMaybe<StatementOrderByRelationAggregateInput>;
  transaction?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AccountPortfolio = {
  __typename?: 'AccountPortfolio';
  account: Account;
  accountId: Scalars['String']['output'];
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  fund: Fund;
  fundCode: Scalars['String']['output'];
  holdingUnits: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  profitLoss: Scalars['Decimal']['output'];
  updatedAt: Scalars['DateTime']['output'];
  weightedAvgNavPrice: Scalars['Decimal']['output'];
};

export type AccountPortfolioAvgAggregate = {
  __typename?: 'AccountPortfolioAvgAggregate';
  amount?: Maybe<Scalars['Decimal']['output']>;
  holdingUnits?: Maybe<Scalars['Decimal']['output']>;
  profitLoss?: Maybe<Scalars['Decimal']['output']>;
  weightedAvgNavPrice?: Maybe<Scalars['Decimal']['output']>;
};

export type AccountPortfolioCountAggregate = {
  __typename?: 'AccountPortfolioCountAggregate';
  _all: Scalars['Int']['output'];
  accountId: Scalars['Int']['output'];
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  fundCode: Scalars['Int']['output'];
  holdingUnits: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  profitLoss: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  weightedAvgNavPrice: Scalars['Int']['output'];
};

export type AccountPortfolioCreateManyAccountInput = {
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fundCode: Scalars['String']['input'];
  holdingUnits: Scalars['Decimal']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss: Scalars['Decimal']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightedAvgNavPrice: Scalars['Decimal']['input'];
};

export type AccountPortfolioCreateManyAccountInputEnvelope = {
  data: Array<AccountPortfolioCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountPortfolioCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<AccountPortfolioWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountPortfolioCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<AccountPortfolioCreateWithoutAccountInput>>;
  createMany?: InputMaybe<AccountPortfolioCreateManyAccountInputEnvelope>;
};

export type AccountPortfolioCreateOrConnectWithoutAccountInput = {
  create: AccountPortfolioUncheckedCreateWithoutAccountInput;
  where: AccountPortfolioWhereUniqueInput;
};

export type AccountPortfolioCreateWithoutAccountInput = {
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fund: FundCreateNestedOneWithoutAccountPortfoliosInput;
  holdingUnits: Scalars['Decimal']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss: Scalars['Decimal']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightedAvgNavPrice: Scalars['Decimal']['input'];
};

export type AccountPortfolioListRelationFilter = {
  every?: InputMaybe<AccountPortfolioWhereInput>;
  none?: InputMaybe<AccountPortfolioWhereInput>;
  some?: InputMaybe<AccountPortfolioWhereInput>;
};

export type AccountPortfolioMaxAggregate = {
  __typename?: 'AccountPortfolioMaxAggregate';
  accountId?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  holdingUnits?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  profitLoss?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weightedAvgNavPrice?: Maybe<Scalars['Decimal']['output']>;
};

export type AccountPortfolioMinAggregate = {
  __typename?: 'AccountPortfolioMinAggregate';
  accountId?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  holdingUnits?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  profitLoss?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weightedAvgNavPrice?: Maybe<Scalars['Decimal']['output']>;
};

export type AccountPortfolioOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AccountPortfolioOrderByWithRelationInput = {
  account?: InputMaybe<AccountOrderByWithRelationInput>;
  accountId?: InputMaybe<SortOrder>;
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fund?: InputMaybe<FundOrderByWithRelationInput>;
  fundCode?: InputMaybe<SortOrder>;
  holdingUnits?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profitLoss?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weightedAvgNavPrice?: InputMaybe<SortOrder>;
};

export enum AccountPortfolioScalarFieldEnum {
  AccountId = 'accountId',
  Amount = 'amount',
  CreatedAt = 'createdAt',
  FundCode = 'fundCode',
  HoldingUnits = 'holdingUnits',
  Id = 'id',
  ProfitLoss = 'profitLoss',
  UpdatedAt = 'updatedAt',
  WeightedAvgNavPrice = 'weightedAvgNavPrice'
}

export type AccountPortfolioScalarWhereInput = {
  AND?: InputMaybe<Array<AccountPortfolioScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccountPortfolioScalarWhereInput>>;
  OR?: InputMaybe<Array<AccountPortfolioScalarWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fundCode?: InputMaybe<StringFilter>;
  holdingUnits?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  profitLoss?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightedAvgNavPrice?: InputMaybe<DecimalFilter>;
};

export type AccountPortfolioSumAggregate = {
  __typename?: 'AccountPortfolioSumAggregate';
  amount?: Maybe<Scalars['Decimal']['output']>;
  holdingUnits?: Maybe<Scalars['Decimal']['output']>;
  profitLoss?: Maybe<Scalars['Decimal']['output']>;
  weightedAvgNavPrice?: Maybe<Scalars['Decimal']['output']>;
};

export type AccountPortfolioUncheckedCreateInput = {
  accountId: Scalars['String']['input'];
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fundCode: Scalars['String']['input'];
  holdingUnits: Scalars['Decimal']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss: Scalars['Decimal']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightedAvgNavPrice: Scalars['Decimal']['input'];
};

export type AccountPortfolioUncheckedCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<AccountPortfolioWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountPortfolioCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<AccountPortfolioCreateWithoutAccountInput>>;
  createMany?: InputMaybe<AccountPortfolioCreateManyAccountInputEnvelope>;
};

export type AccountPortfolioUncheckedCreateWithoutAccountInput = {
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fundCode: Scalars['String']['input'];
  holdingUnits: Scalars['Decimal']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss: Scalars['Decimal']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightedAvgNavPrice: Scalars['Decimal']['input'];
};

export type AccountPortfolioUncheckedUpdateInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  holdingUnits?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightedAvgNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
};

export type AccountPortfolioUncheckedUpdateManyWithoutAccountInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  holdingUnits?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightedAvgNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
};

export type AccountPortfolioUncheckedUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<AccountPortfolioWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountPortfolioCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<AccountPortfolioCreateWithoutAccountInput>>;
  createMany?: InputMaybe<AccountPortfolioCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<AccountPortfolioWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccountPortfolioScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccountPortfolioWhereUniqueInput>>;
  set?: InputMaybe<Array<AccountPortfolioWhereUniqueInput>>;
  update?: InputMaybe<Array<AccountPortfolioUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<AccountPortfolioUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<AccountPortfolioUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AccountPortfolioUncheckedUpdateWithoutAccountInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  holdingUnits?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightedAvgNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
};

export type AccountPortfolioUpdateManyWithWhereWithoutAccountInput = {
  data: AccountPortfolioUncheckedUpdateManyWithoutAccountInput;
  where: AccountPortfolioScalarWhereInput;
};

export type AccountPortfolioUpdateWithWhereUniqueWithoutAccountInput = {
  data: AccountPortfolioUncheckedUpdateWithoutAccountInput;
  where: AccountPortfolioWhereUniqueInput;
};

export type AccountPortfolioUpsertWithWhereUniqueWithoutAccountInput = {
  create: AccountPortfolioUncheckedCreateWithoutAccountInput;
  update: AccountPortfolioUncheckedUpdateWithoutAccountInput;
  where: AccountPortfolioWhereUniqueInput;
};

export type AccountPortfolioWhereInput = {
  AND?: InputMaybe<Array<AccountPortfolioWhereInput>>;
  NOT?: InputMaybe<Array<AccountPortfolioWhereInput>>;
  OR?: InputMaybe<Array<AccountPortfolioWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<StringFilter>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  holdingUnits?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  profitLoss?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightedAvgNavPrice?: InputMaybe<DecimalFilter>;
};

export type AccountPortfolioWhereUniqueInput = {
  AND?: InputMaybe<Array<AccountPortfolioWhereInput>>;
  NOT?: InputMaybe<Array<AccountPortfolioWhereInput>>;
  OR?: InputMaybe<Array<AccountPortfolioWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<StringFilter>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  holdingUnits?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  profitLoss?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightedAvgNavPrice?: InputMaybe<DecimalFilter>;
};

export type AccountRelationFilter = {
  is?: InputMaybe<AccountWhereInput>;
  isNot?: InputMaybe<AccountWhereInput>;
};

export type AccountRequest = {
  __typename?: 'AccountRequest';
  accountCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  approvalStatus: ApprovalStatus;
  contactEmail: Scalars['String']['output'];
  contactName?: Maybe<Scalars['String']['output']>;
  contactNumber: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  idNumber: Scalars['String']['output'];
  idType: IdType;
  invitationCode?: Maybe<Scalars['String']['output']>;
  modeOfContact?: Maybe<ModeOfContact>;
  name: Scalars['String']['output'];
  referenceNumber: Scalars['String']['output'];
  type: AccountType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AccountRequestCountAggregate = {
  __typename?: 'AccountRequestCountAggregate';
  _all: Scalars['Int']['output'];
  accountCreatedDate: Scalars['Int']['output'];
  approvalStatus: Scalars['Int']['output'];
  contactEmail: Scalars['Int']['output'];
  contactName: Scalars['Int']['output'];
  contactNumber: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  idNumber: Scalars['Int']['output'];
  idType: Scalars['Int']['output'];
  invitationCode: Scalars['Int']['output'];
  modeOfContact: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  referenceNumber: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AccountRequestMaxAggregate = {
  __typename?: 'AccountRequestMaxAggregate';
  accountCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idType?: Maybe<IdType>;
  invitationCode?: Maybe<Scalars['String']['output']>;
  modeOfContact?: Maybe<ModeOfContact>;
  name?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AccountType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AccountRequestMinAggregate = {
  __typename?: 'AccountRequestMinAggregate';
  accountCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idType?: Maybe<IdType>;
  invitationCode?: Maybe<Scalars['String']['output']>;
  modeOfContact?: Maybe<ModeOfContact>;
  name?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AccountType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AccountRequestOrderByWithRelationInput = {
  accountCreatedDate?: InputMaybe<SortOrderInput>;
  approvalStatus?: InputMaybe<SortOrder>;
  contactEmail?: InputMaybe<SortOrder>;
  contactName?: InputMaybe<SortOrderInput>;
  contactNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idNumber?: InputMaybe<SortOrder>;
  idType?: InputMaybe<SortOrder>;
  invitationCode?: InputMaybe<SortOrderInput>;
  modeOfContact?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  referenceNumber?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AccountRequestUncheckedCreateInput = {
  accountCreatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  approvalStatus?: InputMaybe<ApprovalStatus>;
  contactEmail: Scalars['String']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  idNumber: Scalars['String']['input'];
  idType: IdType;
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  modeOfContact?: InputMaybe<ModeOfContact>;
  name: Scalars['String']['input'];
  referenceNumber: Scalars['String']['input'];
  type: AccountType;
};

export type AccountRequestUncheckedUpdateInput = {
  accountCreatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  approvalStatus?: InputMaybe<ApprovalStatus>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<IdType>;
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  modeOfContact?: InputMaybe<ModeOfContact>;
  name?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AccountType>;
};

export type AccountRequestWhereInput = {
  AND?: InputMaybe<Array<AccountRequestWhereInput>>;
  NOT?: InputMaybe<Array<AccountRequestWhereInput>>;
  OR?: InputMaybe<Array<AccountRequestWhereInput>>;
  accountCreatedDate?: InputMaybe<DateTimeNullableFilter>;
  approvalStatus?: InputMaybe<EnumApprovalStatusFilter>;
  contactEmail?: InputMaybe<StringFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactNumber?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  idNumber?: InputMaybe<StringFilter>;
  idType?: InputMaybe<EnumIdTypeFilter>;
  invitationCode?: InputMaybe<StringNullableFilter>;
  modeOfContact?: InputMaybe<EnumModeOfContactNullableFilter>;
  name?: InputMaybe<StringFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAccountTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountRequestWhereUniqueInput = {
  AND?: InputMaybe<Array<AccountRequestWhereInput>>;
  NOT?: InputMaybe<Array<AccountRequestWhereInput>>;
  OR?: InputMaybe<Array<AccountRequestWhereInput>>;
  accountCreatedDate?: InputMaybe<DateTimeNullableFilter>;
  approvalStatus?: InputMaybe<EnumApprovalStatusFilter>;
  contactEmail?: InputMaybe<StringFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactNumber?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<StringFilter>;
  idType?: InputMaybe<EnumIdTypeFilter>;
  invitationCode?: InputMaybe<StringNullableFilter>;
  modeOfContact?: InputMaybe<EnumModeOfContactNullableFilter>;
  name?: InputMaybe<StringFilter>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumAccountTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum AccountScalarFieldEnum {
  ApplicationDate = 'applicationDate',
  ApplicationRefId = 'applicationRefId',
  ApplicationStatus = 'applicationStatus',
  CreatedAt = 'createdAt',
  CreationStatus = 'creationStatus',
  DeletedAt = 'deletedAt',
  Id = 'id',
  MasterAccountNo = 'masterAccountNo',
  ProfileId = 'profileId',
  UpdatedAt = 'updatedAt'
}

export type AccountScalarWhereInput = {
  AND?: InputMaybe<Array<AccountScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccountScalarWhereInput>>;
  OR?: InputMaybe<Array<AccountScalarWhereInput>>;
  applicationDate?: InputMaybe<DateTimeNullableFilter>;
  applicationRefId?: InputMaybe<StringNullableFilter>;
  applicationStatus?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  id?: InputMaybe<StringFilter>;
  masterAccountNo?: InputMaybe<StringFilter>;
  profileId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum AccountType {
  Corporate = 'Corporate',
  Individual = 'Individual'
}

export type AccountUncheckedCreateInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioUncheckedCreateNestedManyWithoutAccountInput>;
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo: Scalars['String']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
  statements?: InputMaybe<StatementUncheckedCreateNestedManyWithoutAccountInput>;
  transaction?: InputMaybe<TransactionUncheckedCreateNestedManyWithoutAccountInput>;
};

export type AccountUncheckedCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<AccountCreateManyProfileInputEnvelope>;
};

export type AccountUncheckedCreateWithoutProfileInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioUncheckedCreateNestedManyWithoutAccountInput>;
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo: Scalars['String']['input'];
  statements?: InputMaybe<StatementUncheckedCreateNestedManyWithoutAccountInput>;
  transaction?: InputMaybe<TransactionUncheckedCreateNestedManyWithoutAccountInput>;
};

export type AccountUncheckedCreateWithoutTransactionInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioUncheckedCreateNestedManyWithoutAccountInput>;
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo: Scalars['String']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
  statements?: InputMaybe<StatementUncheckedCreateNestedManyWithoutAccountInput>;
};

export type AccountUncheckedUpdateInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioUncheckedUpdateManyWithoutAccountNestedInput>;
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  statements?: InputMaybe<StatementUncheckedUpdateManyWithoutAccountNestedInput>;
  transaction?: InputMaybe<TransactionUncheckedUpdateManyWithoutAccountNestedInput>;
};

export type AccountUncheckedUpdateManyWithoutProfileInput = {
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo?: InputMaybe<Scalars['String']['input']>;
};

export type AccountUncheckedUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<AccountCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<AccountWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccountScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  set?: InputMaybe<Array<AccountWhereUniqueInput>>;
  update?: InputMaybe<Array<AccountUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<AccountUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<AccountUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type AccountUncheckedUpdateWithoutProfileInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioUncheckedUpdateManyWithoutAccountNestedInput>;
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationRefId?: InputMaybe<Scalars['String']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo?: InputMaybe<Scalars['String']['input']>;
  statements?: InputMaybe<StatementUncheckedUpdateManyWithoutAccountNestedInput>;
  transaction?: InputMaybe<TransactionUncheckedUpdateManyWithoutAccountNestedInput>;
};

export type AccountUpdateManyWithWhereWithoutProfileInput = {
  data: AccountUncheckedUpdateManyWithoutProfileInput;
  where: AccountScalarWhereInput;
};

export type AccountUpdateWithWhereUniqueWithoutProfileInput = {
  data: AccountUncheckedUpdateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpsertWithWhereUniqueWithoutProfileInput = {
  create: AccountUncheckedCreateWithoutProfileInput;
  update: AccountUncheckedUpdateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountWhereInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  accountPortfolios?: InputMaybe<AccountPortfolioListRelationFilter>;
  applicationDate?: InputMaybe<DateTimeNullableFilter>;
  applicationRefId?: InputMaybe<StringNullableFilter>;
  applicationStatus?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  id?: InputMaybe<StringFilter>;
  masterAccountNo?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileNullableRelationFilter>;
  profileId?: InputMaybe<StringNullableFilter>;
  statements?: InputMaybe<StatementListRelationFilter>;
  transaction?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountWhereUniqueInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  accountPortfolios?: InputMaybe<AccountPortfolioListRelationFilter>;
  applicationDate?: InputMaybe<DateTimeNullableFilter>;
  applicationRefId?: InputMaybe<StringNullableFilter>;
  applicationStatus?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  masterAccountNo?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileNullableRelationFilter>;
  profileId?: InputMaybe<StringNullableFilter>;
  statements?: InputMaybe<StatementListRelationFilter>;
  transaction?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ActionStatus {
  Failure = 'Failure',
  Success = 'Success',
  Warning = 'Warning'
}

export type AdminLoginInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type AdminPermission = {
  __typename?: 'AdminPermission';
  action?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** prisma-lint-ignore-model require-field-type */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resource?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminPermissionAvgAggregate = {
  __typename?: 'AdminPermissionAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type AdminPermissionCountAggregate = {
  __typename?: 'AdminPermissionCountAggregate';
  _all: Scalars['Int']['output'];
  action: Scalars['Int']['output'];
  code: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  resource: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AdminPermissionMaxAggregate = {
  __typename?: 'AdminPermissionMaxAggregate';
  action?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminPermissionMinAggregate = {
  __typename?: 'AdminPermissionMinAggregate';
  action?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminPermissionOrderByWithRelationInput = {
  action?: InputMaybe<SortOrderInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resource?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdminPermissionSumAggregate = {
  __typename?: 'AdminPermissionSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type AdminPermissionWhereInput = {
  AND?: InputMaybe<Array<AdminPermissionWhereInput>>;
  NOT?: InputMaybe<Array<AdminPermissionWhereInput>>;
  OR?: InputMaybe<Array<AdminPermissionWhereInput>>;
  action?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  resource?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  _count?: Maybe<AdminRoleCount>;
  admins: Array<AdminUser>;
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  description?: Maybe<Scalars['String']['output']>;
  /** prisma-lint-ignore-model require-field-type */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissionGrants?: Maybe<Array<PermissionGrant>>;
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  userCount?: Maybe<Scalars['Float']['output']>;
};


export type AdminRoleAdminsArgs = {
  cursor?: InputMaybe<AdminUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdminUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminUserWhereInput>;
};

export type AdminRoleAvgAggregate = {
  __typename?: 'AdminRoleAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type AdminRoleCount = {
  __typename?: 'AdminRoleCount';
  admins: Scalars['Int']['output'];
};

export type AdminRoleCountAggregate = {
  __typename?: 'AdminRoleCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  permissions: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AdminRoleCreateInputV2 = {
  creationStatus?: InputMaybe<CreationStatus>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdminRoleMaxAggregate = {
  __typename?: 'AdminRoleMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminRoleMinAggregate = {
  __typename?: 'AdminRoleMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminRoleNullableRelationFilter = {
  is?: InputMaybe<AdminRoleWhereInput>;
  isNot?: InputMaybe<AdminRoleWhereInput>;
};

export type AdminRoleOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  permissions?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdminRoleSumAggregate = {
  __typename?: 'AdminRoleSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type AdminRoleUpdateInputV2 = {
  creationStatus?: InputMaybe<CreationStatus>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdminRoleWhereInput = {
  AND?: InputMaybe<Array<AdminRoleWhereInput>>;
  NOT?: InputMaybe<Array<AdminRoleWhereInput>>;
  OR?: InputMaybe<Array<AdminRoleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRoleWhereUniqueInput = {
  AND?: InputMaybe<Array<AdminRoleWhereInput>>;
  NOT?: InputMaybe<Array<AdminRoleWhereInput>>;
  OR?: InputMaybe<Array<AdminRoleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  role?: Maybe<AdminRole>;
  roleId?: Maybe<Scalars['ID']['output']>;
  status: AdminUserStatus;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};


export type AdminUserRoleArgs = {
  where?: InputMaybe<AdminRoleWhereInput>;
};

export type AdminUserAvgAggregate = {
  __typename?: 'AdminUserAvgAggregate';
  roleId?: Maybe<Scalars['Float']['output']>;
};

export type AdminUserCountAggregate = {
  __typename?: 'AdminUserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  profilePicture: Scalars['Int']['output'];
  roleId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  username: Scalars['Int']['output'];
};

export type AdminUserCreateInputV2 = {
  creationStatus?: InputMaybe<CreationStatus>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<AdminUserStatus>;
  username: Scalars['String']['input'];
};

export type AdminUserMaxAggregate = {
  __typename?: 'AdminUserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<AdminUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AdminUserMinAggregate = {
  __typename?: 'AdminUserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<AdminUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AdminUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  profilePicture?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<AdminRoleOrderByWithRelationInput>;
  roleId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export enum AdminUserScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreationStatus = 'creationStatus',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  ProfilePicture = 'profilePicture',
  RoleId = 'roleId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export enum AdminUserStatus {
  Active = 'Active',
  Suspended = 'Suspended'
}

export type AdminUserSumAggregate = {
  __typename?: 'AdminUserSumAggregate';
  roleId?: Maybe<Scalars['Int']['output']>;
};

export type AdminUserUpdateInputV2 = {
  creationStatus?: InputMaybe<CreationStatus>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<AdminUserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUserWhereInput = {
  AND?: InputMaybe<Array<AdminUserWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserWhereInput>>;
  OR?: InputMaybe<Array<AdminUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<AdminRoleNullableRelationFilter>;
  roleId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumAdminUserStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type AdminUserWhereUniqueInput = {
  AND?: InputMaybe<Array<AdminUserWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserWhereInput>>;
  OR?: InputMaybe<Array<AdminUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<AdminRoleNullableRelationFilter>;
  roleId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumAdminUserStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AmountValue = {
  __typename?: 'AmountValue';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
};

export type Announcement = {
  __typename?: 'Announcement';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  ctaLink?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  status: AnnouncementStatus;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AnnouncementCountAggregate = {
  __typename?: 'AnnouncementCountAggregate';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  ctaLink: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageId: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AnnouncementMaxAggregate = {
  __typename?: 'AnnouncementMaxAggregate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<AnnouncementStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnnouncementMinAggregate = {
  __typename?: 'AnnouncementMinAggregate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<AnnouncementStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnnouncementOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  ctaLink?: InputMaybe<SortOrderInput>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrderInput>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AnnouncementStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type AnnouncementUncheckedCreateInput = {
  content: Scalars['String']['input'];
  creationStatus?: InputMaybe<CreationStatus>;
  ctaLink?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<CreateOneDocumentInput>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  status?: InputMaybe<AnnouncementStatus>;
  title: Scalars['String']['input'];
};

export type AnnouncementUncheckedUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  ctaLink?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<CreateOneDocumentInput>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<AnnouncementStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AnnouncementWhereInput = {
  AND?: InputMaybe<Array<AnnouncementWhereInput>>;
  NOT?: InputMaybe<Array<AnnouncementWhereInput>>;
  OR?: InputMaybe<Array<AnnouncementWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  ctaLink?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  imageId?: InputMaybe<StringNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumAnnouncementStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnnouncementWhereUniqueInput = {
  AND?: InputMaybe<Array<AnnouncementWhereInput>>;
  NOT?: InputMaybe<Array<AnnouncementWhereInput>>;
  OR?: InputMaybe<Array<AnnouncementWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  ctaLink?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<StringNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumAnnouncementStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnnualisedReturn = {
  __typename?: 'AnnualisedReturn';
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum ApprovalStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type AuditTrail = {
  __typename?: 'AuditTrail';
  action: Scalars['String']['output'];
  appVersion?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  clientIp: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  details?: Maybe<Scalars['JSON']['output']>;
  environment: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interface: Interface;
  module: Scalars['String']['output'];
  relatedEntities?: Maybe<Scalars['JSON']['output']>;
  relatedIds?: Maybe<Scalars['JSON']['output']>;
  serverIp: Scalars['String']['output'];
  sessionId?: Maybe<Scalars['String']['output']>;
  status: ActionStatus;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
  userType: UserType;
  username: Scalars['String']['output'];
};

export type AuditTrailCountAggregate = {
  __typename?: 'AuditTrailCountAggregate';
  _all: Scalars['Int']['output'];
  action: Scalars['Int']['output'];
  appVersion: Scalars['Int']['output'];
  channel: Scalars['Int']['output'];
  clientIp: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  details: Scalars['Int']['output'];
  environment: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  interface: Scalars['Int']['output'];
  module: Scalars['Int']['output'];
  relatedEntities: Scalars['Int']['output'];
  relatedIds: Scalars['Int']['output'];
  serverIp: Scalars['Int']['output'];
  sessionId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  timestamp: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  userType: Scalars['Int']['output'];
  username: Scalars['Int']['output'];
};

export type AuditTrailMaxAggregate = {
  __typename?: 'AuditTrailMaxAggregate';
  action?: Maybe<Scalars['String']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  clientIp?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interface?: Maybe<Interface>;
  module?: Maybe<Scalars['String']['output']>;
  serverIp?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActionStatus>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userType?: Maybe<UserType>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AuditTrailMinAggregate = {
  __typename?: 'AuditTrailMinAggregate';
  action?: Maybe<Scalars['String']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  clientIp?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  environment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interface?: Maybe<Interface>;
  module?: Maybe<Scalars['String']['output']>;
  serverIp?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActionStatus>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userType?: Maybe<UserType>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AuditTrailOrderByWithRelationInput = {
  action?: InputMaybe<SortOrder>;
  appVersion?: InputMaybe<SortOrderInput>;
  channel?: InputMaybe<SortOrderInput>;
  clientIp?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrderInput>;
  environment?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  interface?: InputMaybe<SortOrder>;
  module?: InputMaybe<SortOrder>;
  relatedEntities?: InputMaybe<SortOrderInput>;
  relatedIds?: InputMaybe<SortOrderInput>;
  serverIp?: InputMaybe<SortOrder>;
  sessionId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  timestamp?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type AuditTrailUncheckedCreateInput = {
  action: Scalars['String']['input'];
  appVersion?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  clientIp: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  environment: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  interface: Interface;
  module: Scalars['String']['input'];
  relatedEntities?: InputMaybe<Scalars['JSON']['input']>;
  relatedIds?: InputMaybe<Scalars['JSON']['input']>;
  serverIp: Scalars['String']['input'];
  sessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ActionStatus>;
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  userType?: InputMaybe<UserType>;
  username: Scalars['String']['input'];
};

export type AuditTrailUncheckedUpdateInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  clientIp?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interface?: InputMaybe<Interface>;
  module?: InputMaybe<Scalars['String']['input']>;
  relatedEntities?: InputMaybe<Scalars['JSON']['input']>;
  relatedIds?: InputMaybe<Scalars['JSON']['input']>;
  serverIp?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ActionStatus>;
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<UserType>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AuditTrailWhereInput = {
  AND?: InputMaybe<Array<AuditTrailWhereInput>>;
  NOT?: InputMaybe<Array<AuditTrailWhereInput>>;
  OR?: InputMaybe<Array<AuditTrailWhereInput>>;
  action?: InputMaybe<StringFilter>;
  appVersion?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<StringNullableFilter>;
  clientIp?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<JsonNullableFilter>;
  environment?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  interface?: InputMaybe<EnumInterfaceFilter>;
  module?: InputMaybe<StringFilter>;
  relatedEntities?: InputMaybe<JsonNullableFilter>;
  relatedIds?: InputMaybe<JsonNullableFilter>;
  serverIp?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumActionStatusFilter>;
  timestamp?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  userType?: InputMaybe<EnumUserTypeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type AuditTrailWhereUniqueInput = {
  AND?: InputMaybe<Array<AuditTrailWhereInput>>;
  NOT?: InputMaybe<Array<AuditTrailWhereInput>>;
  OR?: InputMaybe<Array<AuditTrailWhereInput>>;
  action?: InputMaybe<StringFilter>;
  appVersion?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<StringNullableFilter>;
  clientIp?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<JsonNullableFilter>;
  environment?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  interface?: InputMaybe<EnumInterfaceFilter>;
  module?: InputMaybe<StringFilter>;
  relatedEntities?: InputMaybe<JsonNullableFilter>;
  relatedIds?: InputMaybe<JsonNullableFilter>;
  serverIp?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumActionStatusFilter>;
  timestamp?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  userType?: InputMaybe<EnumUserTypeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Float']['output'];
  refreshExpiresIn: Scalars['Float']['output'];
  /** JWT refresh token */
  refreshToken: Scalars['String']['output'];
};

export type AuthUserUnion = AdminUser | User;

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  beneficiaryName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  profile: Profile;
  profileId: Scalars['String']['output'];
  swiftCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BankAccountCountAggregate = {
  __typename?: 'BankAccountCountAggregate';
  _all: Scalars['Int']['output'];
  accountNumber: Scalars['Int']['output'];
  bankName: Scalars['Int']['output'];
  beneficiaryName: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  currency: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDefault: Scalars['Int']['output'];
  profileId: Scalars['Int']['output'];
  swiftCode: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type BankAccountCreateManyProfileInput = {
  accountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  beneficiaryName: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BankAccountCreateManyProfileInputEnvelope = {
  data: Array<BankAccountCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BankAccountCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<BankAccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankAccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<BankAccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<BankAccountCreateManyProfileInputEnvelope>;
};

export type BankAccountCreateOrConnectWithoutProfileInput = {
  create: BankAccountUncheckedCreateWithoutProfileInput;
  where: BankAccountWhereUniqueInput;
};

export type BankAccountCreateWithoutProfileInput = {
  accountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  beneficiaryName: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BankAccountListRelationFilter = {
  every?: InputMaybe<BankAccountWhereInput>;
  none?: InputMaybe<BankAccountWhereInput>;
  some?: InputMaybe<BankAccountWhereInput>;
};

export type BankAccountMaxAggregate = {
  __typename?: 'BankAccountMaxAggregate';
  accountNumber?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  beneficiaryName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  profileId?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BankAccountMinAggregate = {
  __typename?: 'BankAccountMinAggregate';
  accountNumber?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  beneficiaryName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  profileId?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BankAccountOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BankAccountOrderByWithRelationInput = {
  accountNumber?: InputMaybe<SortOrder>;
  bankName?: InputMaybe<SortOrder>;
  beneficiaryName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByWithRelationInput>;
  profileId?: InputMaybe<SortOrder>;
  swiftCode?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum BankAccountScalarFieldEnum {
  AccountNumber = 'accountNumber',
  BankName = 'bankName',
  BeneficiaryName = 'beneficiaryName',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Id = 'id',
  IsDefault = 'isDefault',
  ProfileId = 'profileId',
  SwiftCode = 'swiftCode',
  UpdatedAt = 'updatedAt'
}

export type BankAccountScalarWhereInput = {
  AND?: InputMaybe<Array<BankAccountScalarWhereInput>>;
  NOT?: InputMaybe<Array<BankAccountScalarWhereInput>>;
  OR?: InputMaybe<Array<BankAccountScalarWhereInput>>;
  accountNumber?: InputMaybe<StringFilter>;
  bankName?: InputMaybe<StringFilter>;
  beneficiaryName?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  profileId?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankAccountUncheckedCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<BankAccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankAccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<BankAccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<BankAccountCreateManyProfileInputEnvelope>;
};

export type BankAccountUncheckedCreateWithoutProfileInput = {
  accountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  beneficiaryName: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BankAccountUncheckedUpdateManyWithoutProfileInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  beneficiaryName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BankAccountUncheckedUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<BankAccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankAccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<BankAccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<BankAccountCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<BankAccountWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BankAccountScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BankAccountWhereUniqueInput>>;
  set?: InputMaybe<Array<BankAccountWhereUniqueInput>>;
  update?: InputMaybe<Array<BankAccountUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<BankAccountUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<BankAccountUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type BankAccountUncheckedUpdateWithoutProfileInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  beneficiaryName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BankAccountUpdateManyWithWhereWithoutProfileInput = {
  data: BankAccountUncheckedUpdateManyWithoutProfileInput;
  where: BankAccountScalarWhereInput;
};

export type BankAccountUpdateWithWhereUniqueWithoutProfileInput = {
  data: BankAccountUncheckedUpdateWithoutProfileInput;
  where: BankAccountWhereUniqueInput;
};

export type BankAccountUpsertWithWhereUniqueWithoutProfileInput = {
  create: BankAccountUncheckedCreateWithoutProfileInput;
  update: BankAccountUncheckedUpdateWithoutProfileInput;
  where: BankAccountWhereUniqueInput;
};

export type BankAccountWhereInput = {
  AND?: InputMaybe<Array<BankAccountWhereInput>>;
  NOT?: InputMaybe<Array<BankAccountWhereInput>>;
  OR?: InputMaybe<Array<BankAccountWhereInput>>;
  accountNumber?: InputMaybe<StringFilter>;
  bankName?: InputMaybe<StringFilter>;
  beneficiaryName?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankAccountWhereUniqueInput = {
  AND?: InputMaybe<Array<BankAccountWhereInput>>;
  NOT?: InputMaybe<Array<BankAccountWhereInput>>;
  OR?: InputMaybe<Array<BankAccountWhereInput>>;
  accountNumber?: InputMaybe<StringFilter>;
  bankName?: InputMaybe<StringFilter>;
  beneficiaryName?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<BoolFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BytesFilter = {
  equals?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<Scalars['Byte']['input']>>;
  not?: InputMaybe<NestedBytesFilter>;
  notIn?: InputMaybe<Array<Scalars['Byte']['input']>>;
};

export type CalendarYearReturn = {
  __typename?: 'CalendarYearReturn';
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CollectionBankAccount = {
  __typename?: 'CollectionBankAccount';
  _count?: Maybe<CollectionBankAccountCount>;
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  beneficiaryName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  funds: Array<Fund>;
  id: Scalars['ID']['output'];
  swiftCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CollectionBankAccountFundsArgs = {
  cursor?: InputMaybe<FundWhereUniqueInput>;
  distinct?: InputMaybe<Array<FundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FundWhereInput>;
};

export type CollectionBankAccountCount = {
  __typename?: 'CollectionBankAccountCount';
  funds: Scalars['Int']['output'];
};

export type CollectionBankAccountCountAggregate = {
  __typename?: 'CollectionBankAccountCountAggregate';
  _all: Scalars['Int']['output'];
  accountNumber: Scalars['Int']['output'];
  bankName: Scalars['Int']['output'];
  beneficiaryName: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  currency: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  swiftCode: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CollectionBankAccountMaxAggregate = {
  __typename?: 'CollectionBankAccountMaxAggregate';
  accountNumber?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  beneficiaryName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CollectionBankAccountMinAggregate = {
  __typename?: 'CollectionBankAccountMinAggregate';
  accountNumber?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  beneficiaryName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CollectionBankAccountOrderByWithRelationInput = {
  accountNumber?: InputMaybe<SortOrder>;
  bankName?: InputMaybe<SortOrder>;
  beneficiaryName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  funds?: InputMaybe<FundOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  swiftCode?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionBankAccountRelationFilter = {
  is?: InputMaybe<CollectionBankAccountWhereInput>;
  isNot?: InputMaybe<CollectionBankAccountWhereInput>;
};

export type CollectionBankAccountWhereInput = {
  AND?: InputMaybe<Array<CollectionBankAccountWhereInput>>;
  NOT?: InputMaybe<Array<CollectionBankAccountWhereInput>>;
  OR?: InputMaybe<Array<CollectionBankAccountWhereInput>>;
  accountNumber?: InputMaybe<StringFilter>;
  bankName?: InputMaybe<StringFilter>;
  beneficiaryName?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  funds?: InputMaybe<FundListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CompanyPerformance = {
  __typename?: 'CompanyPerformance';
  companyName: Scalars['String']['output'];
  currentMarketValues: Array<AmountValue>;
  profileId: Scalars['ID']['output'];
  profitAndLossValues: Array<ProfitAndLossValue>;
  totalInvestedValues: Array<AmountValue>;
};

export enum ConsentStatus {
  Active = 'Active',
  Revoked = 'Revoked'
}

export enum ConsentType {
  Transact = 'Transact',
  View = 'View'
}

export type ConsentedProfile = {
  __typename?: 'ConsentedProfile';
  isMain: Scalars['Boolean']['output'];
  permissions: Array<ConsentType>;
  profile: Profile;
};

export type CorporateAccountRequestCreateInput = {
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  contactNumber: Scalars['String']['input'];
  idNumber: Scalars['String']['input'];
  modeOfContact?: InputMaybe<ModeOfContact>;
  name: Scalars['String']['input'];
};

export type CorporateUserUncheckedCreateInput = {
  accountRequestId: Scalars['String']['input'];
  creationStatus?: InputMaybe<CreationStatus>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  profiles?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutUsersInput>;
  status?: InputMaybe<Status>;
  username: Scalars['String']['input'];
};

export type CreateOneDocumentInput = {
  data: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum CreationStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['Byte']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  encodedData?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DocumentCountAggregate = {
  __typename?: 'DocumentCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  data: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mimeType: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type DocumentMaxAggregate = {
  __typename?: 'DocumentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['Byte']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DocumentMinAggregate = {
  __typename?: 'DocumentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['Byte']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DocumentOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentUncheckedUpdateInput = {
  data?: InputMaybe<Scalars['Byte']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentWhereInput = {
  AND?: InputMaybe<Array<DocumentWhereInput>>;
  NOT?: InputMaybe<Array<DocumentWhereInput>>;
  OR?: InputMaybe<Array<DocumentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<BytesFilter>;
  id?: InputMaybe<StringFilter>;
  mimeType?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DocumentWhereUniqueInput = {
  AND?: InputMaybe<Array<DocumentWhereInput>>;
  NOT?: InputMaybe<Array<DocumentWhereInput>>;
  OR?: InputMaybe<Array<DocumentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<BytesFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EnumAccountTypeFilter = {
  equals?: InputMaybe<AccountType>;
  in?: InputMaybe<Array<AccountType>>;
  not?: InputMaybe<NestedEnumAccountTypeFilter>;
  notIn?: InputMaybe<Array<AccountType>>;
};

export type EnumActionStatusFilter = {
  equals?: InputMaybe<ActionStatus>;
  in?: InputMaybe<Array<ActionStatus>>;
  not?: InputMaybe<NestedEnumActionStatusFilter>;
  notIn?: InputMaybe<Array<ActionStatus>>;
};

export type EnumAdminUserStatusFilter = {
  equals?: InputMaybe<AdminUserStatus>;
  in?: InputMaybe<Array<AdminUserStatus>>;
  not?: InputMaybe<NestedEnumAdminUserStatusFilter>;
  notIn?: InputMaybe<Array<AdminUserStatus>>;
};

export type EnumAnnouncementStatusFilter = {
  equals?: InputMaybe<AnnouncementStatus>;
  in?: InputMaybe<Array<AnnouncementStatus>>;
  not?: InputMaybe<NestedEnumAnnouncementStatusFilter>;
  notIn?: InputMaybe<Array<AnnouncementStatus>>;
};

export type EnumApprovalStatusFilter = {
  equals?: InputMaybe<ApprovalStatus>;
  in?: InputMaybe<Array<ApprovalStatus>>;
  not?: InputMaybe<NestedEnumApprovalStatusFilter>;
  notIn?: InputMaybe<Array<ApprovalStatus>>;
};

export type EnumConsentStatusFilter = {
  equals?: InputMaybe<ConsentStatus>;
  in?: InputMaybe<Array<ConsentStatus>>;
  not?: InputMaybe<NestedEnumConsentStatusFilter>;
  notIn?: InputMaybe<Array<ConsentStatus>>;
};

export type EnumConsentTypeFilter = {
  equals?: InputMaybe<ConsentType>;
  in?: InputMaybe<Array<ConsentType>>;
  not?: InputMaybe<NestedEnumConsentTypeFilter>;
  notIn?: InputMaybe<Array<ConsentType>>;
};

export type EnumCreationStatusFilter = {
  equals?: InputMaybe<CreationStatus>;
  in?: InputMaybe<Array<CreationStatus>>;
  not?: InputMaybe<NestedEnumCreationStatusFilter>;
  notIn?: InputMaybe<Array<CreationStatus>>;
};

export type EnumFundCategoriesFilter = {
  equals?: InputMaybe<FundCategories>;
  in?: InputMaybe<Array<FundCategories>>;
  not?: InputMaybe<NestedEnumFundCategoriesFilter>;
  notIn?: InputMaybe<Array<FundCategories>>;
};

export type EnumFundRiskProfileFilter = {
  equals?: InputMaybe<FundRiskProfile>;
  in?: InputMaybe<Array<FundRiskProfile>>;
  not?: InputMaybe<NestedEnumFundRiskProfileFilter>;
  notIn?: InputMaybe<Array<FundRiskProfile>>;
};

export type EnumIdTypeFilter = {
  equals?: InputMaybe<IdType>;
  in?: InputMaybe<Array<IdType>>;
  not?: InputMaybe<NestedEnumIdTypeFilter>;
  notIn?: InputMaybe<Array<IdType>>;
};

export type EnumInterfaceFilter = {
  equals?: InputMaybe<Interface>;
  in?: InputMaybe<Array<Interface>>;
  not?: InputMaybe<NestedEnumInterfaceFilter>;
  notIn?: InputMaybe<Array<Interface>>;
};

export type EnumModeOfContactNullableFilter = {
  equals?: InputMaybe<ModeOfContact>;
  in?: InputMaybe<Array<ModeOfContact>>;
  not?: InputMaybe<NestedEnumModeOfContactNullableFilter>;
  notIn?: InputMaybe<Array<ModeOfContact>>;
};

export type EnumModificationEntityTypeFilter = {
  equals?: InputMaybe<ModificationEntityType>;
  in?: InputMaybe<Array<ModificationEntityType>>;
  not?: InputMaybe<NestedEnumModificationEntityTypeFilter>;
  notIn?: InputMaybe<Array<ModificationEntityType>>;
};

export type EnumModificationStatusFilter = {
  equals?: InputMaybe<ModificationStatus>;
  in?: InputMaybe<Array<ModificationStatus>>;
  not?: InputMaybe<NestedEnumModificationStatusFilter>;
  notIn?: InputMaybe<Array<ModificationStatus>>;
};

export type EnumModificationTypeFilter = {
  equals?: InputMaybe<ModificationType>;
  in?: InputMaybe<Array<ModificationType>>;
  not?: InputMaybe<NestedEnumModificationTypeFilter>;
  notIn?: InputMaybe<Array<ModificationType>>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumSignatoryProviderFilter = {
  equals?: InputMaybe<SignatoryProvider>;
  in?: InputMaybe<Array<SignatoryProvider>>;
  not?: InputMaybe<NestedEnumSignatoryProviderFilter>;
  notIn?: InputMaybe<Array<SignatoryProvider>>;
};

export type EnumSignatoryStatusFilter = {
  equals?: InputMaybe<SignatoryStatus>;
  in?: InputMaybe<Array<SignatoryStatus>>;
  not?: InputMaybe<NestedEnumSignatoryStatusFilter>;
  notIn?: InputMaybe<Array<SignatoryStatus>>;
};

export type EnumStatementStatusFilter = {
  equals?: InputMaybe<StatementStatus>;
  in?: InputMaybe<Array<StatementStatus>>;
  not?: InputMaybe<NestedEnumStatementStatusFilter>;
  notIn?: InputMaybe<Array<StatementStatus>>;
};

export type EnumStatementTypeFilter = {
  equals?: InputMaybe<StatementType>;
  in?: InputMaybe<Array<StatementType>>;
  not?: InputMaybe<NestedEnumStatementTypeFilter>;
  notIn?: InputMaybe<Array<StatementType>>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumTransactionStatusFilter = {
  equals?: InputMaybe<TransactionStatus>;
  in?: InputMaybe<Array<TransactionStatus>>;
  not?: InputMaybe<NestedEnumTransactionStatusFilter>;
  notIn?: InputMaybe<Array<TransactionStatus>>;
};

export type EnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type Faq = {
  __typename?: 'Faq';
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  subCategories: Array<SubCategory>;
  title: Scalars['String']['output'];
};

export type ForgetPasswordInput = {
  isRetry?: InputMaybe<Scalars['Boolean']['input']>;
  username: Scalars['String']['input'];
};

export type Fund = {
  __typename?: 'Fund';
  _count?: Maybe<FundCount>;
  accountPortfolios: Array<AccountPortfolio>;
  annualExpenseRatio: Scalars['Decimal']['output'];
  annualExpenseRatioDate: Scalars['DateTime']['output'];
  annualManagementFee: Scalars['Decimal']['output'];
  annualTrusteeFee: Scalars['Decimal']['output'];
  annualisedReturns?: Maybe<Array<AnnualisedReturn>>;
  assetAllocation: Scalars['String']['output'];
  benchmark: Scalars['String']['output'];
  calendarYearReturns?: Maybe<Array<CalendarYearReturn>>;
  cashManagement: Scalars['Boolean']['output'];
  category: FundCategories;
  classes: Scalars['String']['output'];
  collectionBankAccount: CollectionBankAccount;
  collectionBankAccountId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  distributionPolicy: Scalars['String']['output'];
  distributionType: Scalars['String']['output'];
  esgFlag: Scalars['Boolean']['output'];
  financialYearEnd: Scalars['String']['output'];
  fundCode: Scalars['String']['output'];
  fundDocuments: Array<FundDocument>;
  fundName: Scalars['String']['output'];
  fundPolicy?: Maybe<FundPolicy>;
  fundPrices: Array<FundPrice>;
  fundRiskProfile: FundRiskProfile;
  fundSize: Scalars['Int']['output'];
  fundSizeCurrency: Scalars['String']['output'];
  fundSizeDate: Scalars['DateTime']['output'];
  fundStatus: Scalars['String']['output'];
  fundType: Scalars['String']['output'];
  geographicalLocation: Scalars['String']['output'];
  holidays: Array<FundHoliday>;
  id: Scalars['ID']['output'];
  investmentObj: Scalars['String']['output'];
  launchDate: Scalars['DateTime']['output'];
  launchPrice: Scalars['Decimal']['output'];
  lipperMyClass: Scalars['String']['output'];
  localFlag: Scalars['Boolean']['output'];
  mtdPerformance?: Maybe<Scalars['Float']['output']>;
  navPrice?: Maybe<Scalars['Decimal']['output']>;
  pricingBasic: Scalars['String']['output'];
  salesChargePercent: Scalars['Decimal']['output'];
  shariahFlag: Scalars['Boolean']['output'];
  status: Status;
  switchFee: Scalars['Decimal']['output'];
  switchToTransactions: Array<Transaction>;
  totalUnitInCirculation: Scalars['Int']['output'];
  totalUnitInCirculationDate: Scalars['DateTime']['output'];
  transactionProcessFlow?: Maybe<TransactionProcessFlow>;
  transactions: Array<Transaction>;
  trusteeName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  wholeSaleFlag: Scalars['Boolean']['output'];
  ytdPerformance?: Maybe<Scalars['Float']['output']>;
};


export type FundAccountPortfoliosArgs = {
  cursor?: InputMaybe<AccountPortfolioWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccountPortfolioScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccountPortfolioOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountPortfolioWhereInput>;
};


export type FundFundDocumentsArgs = {
  cursor?: InputMaybe<FundDocumentWhereUniqueInput>;
  distinct?: InputMaybe<Array<FundDocumentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FundDocumentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FundDocumentWhereInput>;
};


export type FundFundPolicyArgs = {
  where?: InputMaybe<FundPolicyWhereInput>;
};


export type FundFundPricesArgs = {
  cursor?: InputMaybe<FundPriceWhereUniqueInput>;
  distinct?: InputMaybe<Array<FundPriceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FundPriceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FundPriceWhereInput>;
};


export type FundHolidaysArgs = {
  cursor?: InputMaybe<FundHolidayWhereUniqueInput>;
  distinct?: InputMaybe<Array<FundHolidayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FundHolidayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FundHolidayWhereInput>;
};


export type FundSwitchToTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type FundTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type FundAvgAggregate = {
  __typename?: 'FundAvgAggregate';
  annualExpenseRatio?: Maybe<Scalars['Decimal']['output']>;
  annualManagementFee?: Maybe<Scalars['Decimal']['output']>;
  annualTrusteeFee?: Maybe<Scalars['Decimal']['output']>;
  fundSize?: Maybe<Scalars['Float']['output']>;
  launchPrice?: Maybe<Scalars['Decimal']['output']>;
  salesChargePercent?: Maybe<Scalars['Decimal']['output']>;
  switchFee?: Maybe<Scalars['Decimal']['output']>;
  totalUnitInCirculation?: Maybe<Scalars['Float']['output']>;
};

export enum FundCategories {
  Equity = 'equity',
  Etf = 'etf',
  FixedIncome = 'fixed_income',
  MixedAssets = 'mixed_assets',
  MoneyMarket = 'money_market',
  Prs = 'prs'
}

export type FundCategory = {
  __typename?: 'FundCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FundCategorySummary = {
  __typename?: 'FundCategorySummary';
  fundCategory: Scalars['String']['output'];
  values: Array<AmountValue>;
};

export type FundCount = {
  __typename?: 'FundCount';
  accountPortfolios: Scalars['Int']['output'];
  fundDocuments: Scalars['Int']['output'];
  fundPrices: Scalars['Int']['output'];
  holidays: Scalars['Int']['output'];
  switchToTransactions: Scalars['Int']['output'];
  transactions: Scalars['Int']['output'];
};

export type FundCountAggregate = {
  __typename?: 'FundCountAggregate';
  _all: Scalars['Int']['output'];
  annualExpenseRatio: Scalars['Int']['output'];
  annualExpenseRatioDate: Scalars['Int']['output'];
  annualManagementFee: Scalars['Int']['output'];
  annualTrusteeFee: Scalars['Int']['output'];
  assetAllocation: Scalars['Int']['output'];
  benchmark: Scalars['Int']['output'];
  cashManagement: Scalars['Int']['output'];
  category: Scalars['Int']['output'];
  classes: Scalars['Int']['output'];
  collectionBankAccountId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  distributionPolicy: Scalars['Int']['output'];
  distributionType: Scalars['Int']['output'];
  esgFlag: Scalars['Int']['output'];
  financialYearEnd: Scalars['Int']['output'];
  fundCode: Scalars['Int']['output'];
  fundName: Scalars['Int']['output'];
  fundRiskProfile: Scalars['Int']['output'];
  fundSize: Scalars['Int']['output'];
  fundSizeCurrency: Scalars['Int']['output'];
  fundSizeDate: Scalars['Int']['output'];
  fundStatus: Scalars['Int']['output'];
  fundType: Scalars['Int']['output'];
  geographicalLocation: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  investmentObj: Scalars['Int']['output'];
  launchDate: Scalars['Int']['output'];
  launchPrice: Scalars['Int']['output'];
  lipperMyClass: Scalars['Int']['output'];
  localFlag: Scalars['Int']['output'];
  pricingBasic: Scalars['Int']['output'];
  salesChargePercent: Scalars['Int']['output'];
  shariahFlag: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  switchFee: Scalars['Int']['output'];
  totalUnitInCirculation: Scalars['Int']['output'];
  totalUnitInCirculationDate: Scalars['Int']['output'];
  trusteeName: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  wholeSaleFlag: Scalars['Int']['output'];
};

export type FundCreateNestedOneWithoutAccountPortfoliosInput = {
  connect?: InputMaybe<FundWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FundCreateOrConnectWithoutAccountPortfoliosInput>;
  create?: InputMaybe<FundUncheckedCreateWithoutAccountPortfoliosInput>;
};

export type FundCreateNestedOneWithoutSwitchToTransactionsInput = {
  connect?: InputMaybe<FundWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FundCreateOrConnectWithoutSwitchToTransactionsInput>;
  create?: InputMaybe<FundUncheckedCreateWithoutSwitchToTransactionsInput>;
};

export type FundCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<FundWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FundCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<FundUncheckedCreateWithoutTransactionsInput>;
};

export type FundCreateOrConnectWithoutAccountPortfoliosInput = {
  create: FundUncheckedCreateWithoutAccountPortfoliosInput;
  where: FundWhereUniqueInput;
};

export type FundCreateOrConnectWithoutSwitchToTransactionsInput = {
  create: FundUncheckedCreateWithoutSwitchToTransactionsInput;
  where: FundWhereUniqueInput;
};

export type FundCreateOrConnectWithoutTransactionsInput = {
  create: FundUncheckedCreateWithoutTransactionsInput;
  where: FundWhereUniqueInput;
};

export type FundDocument = {
  __typename?: 'FundDocument';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  displayName: Scalars['String']['output'];
  documentLink: Scalars['String']['output'];
  fund: Fund;
  fundCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FundDocumentCountAggregate = {
  __typename?: 'FundDocumentCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  displayName: Scalars['Int']['output'];
  documentLink: Scalars['Int']['output'];
  fundCode: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type FundDocumentCreateManyFundInput = {
  displayName: Scalars['String']['input'];
  documentLink: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FundDocumentCreateManyFundInputEnvelope = {
  data: Array<FundDocumentCreateManyFundInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FundDocumentCreateOrConnectWithoutFundInput = {
  create: FundDocumentUncheckedCreateWithoutFundInput;
  where: FundDocumentWhereUniqueInput;
};

export type FundDocumentCreateWithoutFundInput = {
  displayName: Scalars['String']['input'];
  documentLink: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FundDocumentListRelationFilter = {
  every?: InputMaybe<FundDocumentWhereInput>;
  none?: InputMaybe<FundDocumentWhereInput>;
  some?: InputMaybe<FundDocumentWhereInput>;
};

export type FundDocumentMaxAggregate = {
  __typename?: 'FundDocumentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  documentLink?: Maybe<Scalars['String']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundDocumentMinAggregate = {
  __typename?: 'FundDocumentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  documentLink?: Maybe<Scalars['String']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundDocumentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FundDocumentOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  documentLink?: InputMaybe<SortOrder>;
  fund?: InputMaybe<FundOrderByWithRelationInput>;
  fundCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum FundDocumentScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DisplayName = 'displayName',
  DocumentLink = 'documentLink',
  FundCode = 'fundCode',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type FundDocumentScalarWhereInput = {
  AND?: InputMaybe<Array<FundDocumentScalarWhereInput>>;
  NOT?: InputMaybe<Array<FundDocumentScalarWhereInput>>;
  OR?: InputMaybe<Array<FundDocumentScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  documentLink?: InputMaybe<StringFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundDocumentUncheckedCreateInput = {
  displayName: Scalars['String']['input'];
  documentLink: Scalars['String']['input'];
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FundDocumentUncheckedCreateNestedManyWithoutFundInput = {
  connect?: InputMaybe<Array<FundDocumentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FundDocumentCreateOrConnectWithoutFundInput>>;
  create?: InputMaybe<Array<FundDocumentCreateWithoutFundInput>>;
  createMany?: InputMaybe<FundDocumentCreateManyFundInputEnvelope>;
};

export type FundDocumentUncheckedCreateWithoutFundInput = {
  displayName: Scalars['String']['input'];
  documentLink: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FundDocumentUncheckedUpdateInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  documentLink?: InputMaybe<Scalars['String']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FundDocumentUncheckedUpdateManyWithoutFundInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  documentLink?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FundDocumentUncheckedUpdateManyWithoutFundNestedInput = {
  connect?: InputMaybe<Array<FundDocumentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FundDocumentCreateOrConnectWithoutFundInput>>;
  create?: InputMaybe<Array<FundDocumentCreateWithoutFundInput>>;
  createMany?: InputMaybe<FundDocumentCreateManyFundInputEnvelope>;
  delete?: InputMaybe<Array<FundDocumentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FundDocumentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FundDocumentWhereUniqueInput>>;
  set?: InputMaybe<Array<FundDocumentWhereUniqueInput>>;
  update?: InputMaybe<Array<FundDocumentUpdateWithWhereUniqueWithoutFundInput>>;
  updateMany?: InputMaybe<Array<FundDocumentUpdateManyWithWhereWithoutFundInput>>;
  upsert?: InputMaybe<Array<FundDocumentUpsertWithWhereUniqueWithoutFundInput>>;
};

export type FundDocumentUncheckedUpdateWithoutFundInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  documentLink?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FundDocumentUpdateManyWithWhereWithoutFundInput = {
  data: FundDocumentUncheckedUpdateManyWithoutFundInput;
  where: FundDocumentScalarWhereInput;
};

export type FundDocumentUpdateWithWhereUniqueWithoutFundInput = {
  data: FundDocumentUncheckedUpdateWithoutFundInput;
  where: FundDocumentWhereUniqueInput;
};

export type FundDocumentUpsertWithWhereUniqueWithoutFundInput = {
  create: FundDocumentUncheckedCreateWithoutFundInput;
  update: FundDocumentUncheckedUpdateWithoutFundInput;
  where: FundDocumentWhereUniqueInput;
};

export type FundDocumentWhereInput = {
  AND?: InputMaybe<Array<FundDocumentWhereInput>>;
  NOT?: InputMaybe<Array<FundDocumentWhereInput>>;
  OR?: InputMaybe<Array<FundDocumentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  documentLink?: InputMaybe<StringFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundDocumentWhereUniqueInput = {
  AND?: InputMaybe<Array<FundDocumentWhereInput>>;
  NOT?: InputMaybe<Array<FundDocumentWhereInput>>;
  OR?: InputMaybe<Array<FundDocumentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  documentLink?: InputMaybe<StringFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundHoliday = {
  __typename?: 'FundHoliday';
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['DateTime']['output'];
  fund?: Maybe<Fund>;
  fundId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type FundHolidayFundArgs = {
  where?: InputMaybe<FundWhereInput>;
};

export type FundHolidayCountAggregate = {
  __typename?: 'FundHolidayCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  fundId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type FundHolidayCreateManyFundInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type FundHolidayCreateManyFundInputEnvelope = {
  data: Array<FundHolidayCreateManyFundInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FundHolidayCreateOrConnectWithoutFundInput = {
  create: FundHolidayUncheckedCreateWithoutFundInput;
  where: FundHolidayWhereUniqueInput;
};

export type FundHolidayCreateWithoutFundInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type FundHolidayListRelationFilter = {
  every?: InputMaybe<FundHolidayWhereInput>;
  none?: InputMaybe<FundHolidayWhereInput>;
  some?: InputMaybe<FundHolidayWhereInput>;
};

export type FundHolidayMaxAggregate = {
  __typename?: 'FundHolidayMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fundId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundHolidayMinAggregate = {
  __typename?: 'FundHolidayMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fundId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundHolidayOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FundHolidayOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  fund?: InputMaybe<FundOrderByWithRelationInput>;
  fundId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum FundHolidayScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreationStatus = 'creationStatus',
  DeletedAt = 'deletedAt',
  EndDate = 'endDate',
  FundId = 'fundId',
  Id = 'id',
  StartDate = 'startDate',
  UpdatedAt = 'updatedAt'
}

export type FundHolidayScalarWhereInput = {
  AND?: InputMaybe<Array<FundHolidayScalarWhereInput>>;
  NOT?: InputMaybe<Array<FundHolidayScalarWhereInput>>;
  OR?: InputMaybe<Array<FundHolidayScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  fundId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundHolidayUncheckedCreateInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate: Scalars['DateTime']['input'];
  fundId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type FundHolidayUncheckedCreateNestedManyWithoutFundInput = {
  connect?: InputMaybe<Array<FundHolidayWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FundHolidayCreateOrConnectWithoutFundInput>>;
  create?: InputMaybe<Array<FundHolidayCreateWithoutFundInput>>;
  createMany?: InputMaybe<FundHolidayCreateManyFundInputEnvelope>;
};

export type FundHolidayUncheckedCreateWithoutFundInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type FundHolidayUncheckedUpdateInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  fundId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FundHolidayUncheckedUpdateManyWithoutFundInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FundHolidayUncheckedUpdateManyWithoutFundNestedInput = {
  connect?: InputMaybe<Array<FundHolidayWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FundHolidayCreateOrConnectWithoutFundInput>>;
  create?: InputMaybe<Array<FundHolidayCreateWithoutFundInput>>;
  createMany?: InputMaybe<FundHolidayCreateManyFundInputEnvelope>;
  delete?: InputMaybe<Array<FundHolidayWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FundHolidayScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FundHolidayWhereUniqueInput>>;
  set?: InputMaybe<Array<FundHolidayWhereUniqueInput>>;
  update?: InputMaybe<Array<FundHolidayUpdateWithWhereUniqueWithoutFundInput>>;
  updateMany?: InputMaybe<Array<FundHolidayUpdateManyWithWhereWithoutFundInput>>;
  upsert?: InputMaybe<Array<FundHolidayUpsertWithWhereUniqueWithoutFundInput>>;
};

export type FundHolidayUncheckedUpdateWithoutFundInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FundHolidayUpdateManyWithWhereWithoutFundInput = {
  data: FundHolidayUncheckedUpdateManyWithoutFundInput;
  where: FundHolidayScalarWhereInput;
};

export type FundHolidayUpdateWithWhereUniqueWithoutFundInput = {
  data: FundHolidayUncheckedUpdateWithoutFundInput;
  where: FundHolidayWhereUniqueInput;
};

export type FundHolidayUpsertWithWhereUniqueWithoutFundInput = {
  create: FundHolidayUncheckedCreateWithoutFundInput;
  update: FundHolidayUncheckedUpdateWithoutFundInput;
  where: FundHolidayWhereUniqueInput;
};

export type FundHolidayWhereInput = {
  AND?: InputMaybe<Array<FundHolidayWhereInput>>;
  NOT?: InputMaybe<Array<FundHolidayWhereInput>>;
  OR?: InputMaybe<Array<FundHolidayWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  fund?: InputMaybe<FundNullableRelationFilter>;
  fundId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundHolidayWhereUniqueInput = {
  AND?: InputMaybe<Array<FundHolidayWhereInput>>;
  NOT?: InputMaybe<Array<FundHolidayWhereInput>>;
  OR?: InputMaybe<Array<FundHolidayWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  fund?: InputMaybe<FundNullableRelationFilter>;
  fundId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundListRelationFilter = {
  every?: InputMaybe<FundWhereInput>;
  none?: InputMaybe<FundWhereInput>;
  some?: InputMaybe<FundWhereInput>;
};

export type FundMaxAggregate = {
  __typename?: 'FundMaxAggregate';
  annualExpenseRatio?: Maybe<Scalars['Decimal']['output']>;
  annualExpenseRatioDate?: Maybe<Scalars['DateTime']['output']>;
  annualManagementFee?: Maybe<Scalars['Decimal']['output']>;
  annualTrusteeFee?: Maybe<Scalars['Decimal']['output']>;
  assetAllocation?: Maybe<Scalars['String']['output']>;
  benchmark?: Maybe<Scalars['String']['output']>;
  cashManagement?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<FundCategories>;
  classes?: Maybe<Scalars['String']['output']>;
  collectionBankAccountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  distributionPolicy?: Maybe<Scalars['String']['output']>;
  distributionType?: Maybe<Scalars['String']['output']>;
  esgFlag?: Maybe<Scalars['Boolean']['output']>;
  financialYearEnd?: Maybe<Scalars['String']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  fundName?: Maybe<Scalars['String']['output']>;
  fundRiskProfile?: Maybe<FundRiskProfile>;
  fundSize?: Maybe<Scalars['Int']['output']>;
  fundSizeCurrency?: Maybe<Scalars['String']['output']>;
  fundSizeDate?: Maybe<Scalars['DateTime']['output']>;
  fundStatus?: Maybe<Scalars['String']['output']>;
  fundType?: Maybe<Scalars['String']['output']>;
  geographicalLocation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  investmentObj?: Maybe<Scalars['String']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  launchPrice?: Maybe<Scalars['Decimal']['output']>;
  lipperMyClass?: Maybe<Scalars['String']['output']>;
  localFlag?: Maybe<Scalars['Boolean']['output']>;
  pricingBasic?: Maybe<Scalars['String']['output']>;
  salesChargePercent?: Maybe<Scalars['Decimal']['output']>;
  shariahFlag?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Status>;
  switchFee?: Maybe<Scalars['Decimal']['output']>;
  totalUnitInCirculation?: Maybe<Scalars['Int']['output']>;
  totalUnitInCirculationDate?: Maybe<Scalars['DateTime']['output']>;
  trusteeName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wholeSaleFlag?: Maybe<Scalars['Boolean']['output']>;
};

export type FundMinAggregate = {
  __typename?: 'FundMinAggregate';
  annualExpenseRatio?: Maybe<Scalars['Decimal']['output']>;
  annualExpenseRatioDate?: Maybe<Scalars['DateTime']['output']>;
  annualManagementFee?: Maybe<Scalars['Decimal']['output']>;
  annualTrusteeFee?: Maybe<Scalars['Decimal']['output']>;
  assetAllocation?: Maybe<Scalars['String']['output']>;
  benchmark?: Maybe<Scalars['String']['output']>;
  cashManagement?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<FundCategories>;
  classes?: Maybe<Scalars['String']['output']>;
  collectionBankAccountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  distributionPolicy?: Maybe<Scalars['String']['output']>;
  distributionType?: Maybe<Scalars['String']['output']>;
  esgFlag?: Maybe<Scalars['Boolean']['output']>;
  financialYearEnd?: Maybe<Scalars['String']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  fundName?: Maybe<Scalars['String']['output']>;
  fundRiskProfile?: Maybe<FundRiskProfile>;
  fundSize?: Maybe<Scalars['Int']['output']>;
  fundSizeCurrency?: Maybe<Scalars['String']['output']>;
  fundSizeDate?: Maybe<Scalars['DateTime']['output']>;
  fundStatus?: Maybe<Scalars['String']['output']>;
  fundType?: Maybe<Scalars['String']['output']>;
  geographicalLocation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  investmentObj?: Maybe<Scalars['String']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  launchPrice?: Maybe<Scalars['Decimal']['output']>;
  lipperMyClass?: Maybe<Scalars['String']['output']>;
  localFlag?: Maybe<Scalars['Boolean']['output']>;
  pricingBasic?: Maybe<Scalars['String']['output']>;
  salesChargePercent?: Maybe<Scalars['Decimal']['output']>;
  shariahFlag?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Status>;
  switchFee?: Maybe<Scalars['Decimal']['output']>;
  totalUnitInCirculation?: Maybe<Scalars['Int']['output']>;
  totalUnitInCirculationDate?: Maybe<Scalars['DateTime']['output']>;
  trusteeName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wholeSaleFlag?: Maybe<Scalars['Boolean']['output']>;
};

export type FundNullableRelationFilter = {
  is?: InputMaybe<FundWhereInput>;
  isNot?: InputMaybe<FundWhereInput>;
};

export type FundOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FundOrderByWithRelationInput = {
  accountPortfolios?: InputMaybe<AccountPortfolioOrderByRelationAggregateInput>;
  annualExpenseRatio?: InputMaybe<SortOrder>;
  annualExpenseRatioDate?: InputMaybe<SortOrder>;
  annualManagementFee?: InputMaybe<SortOrder>;
  annualTrusteeFee?: InputMaybe<SortOrder>;
  assetAllocation?: InputMaybe<SortOrder>;
  benchmark?: InputMaybe<SortOrder>;
  cashManagement?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  classes?: InputMaybe<SortOrder>;
  collectionBankAccount?: InputMaybe<CollectionBankAccountOrderByWithRelationInput>;
  collectionBankAccountId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  distributionPolicy?: InputMaybe<SortOrder>;
  distributionType?: InputMaybe<SortOrder>;
  esgFlag?: InputMaybe<SortOrder>;
  financialYearEnd?: InputMaybe<SortOrder>;
  fundCode?: InputMaybe<SortOrder>;
  fundDocuments?: InputMaybe<FundDocumentOrderByRelationAggregateInput>;
  fundName?: InputMaybe<SortOrder>;
  fundPolicy?: InputMaybe<FundPolicyOrderByWithRelationInput>;
  fundPrices?: InputMaybe<FundPriceOrderByRelationAggregateInput>;
  fundRiskProfile?: InputMaybe<SortOrder>;
  fundSize?: InputMaybe<SortOrder>;
  fundSizeCurrency?: InputMaybe<SortOrder>;
  fundSizeDate?: InputMaybe<SortOrder>;
  fundStatus?: InputMaybe<SortOrder>;
  fundType?: InputMaybe<SortOrder>;
  geographicalLocation?: InputMaybe<SortOrder>;
  holidays?: InputMaybe<FundHolidayOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  investmentObj?: InputMaybe<SortOrder>;
  launchDate?: InputMaybe<SortOrder>;
  launchPrice?: InputMaybe<SortOrder>;
  lipperMyClass?: InputMaybe<SortOrder>;
  localFlag?: InputMaybe<SortOrder>;
  pricingBasic?: InputMaybe<SortOrder>;
  salesChargePercent?: InputMaybe<SortOrder>;
  shariahFlag?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  switchFee?: InputMaybe<SortOrder>;
  switchToTransactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  totalUnitInCirculation?: InputMaybe<SortOrder>;
  totalUnitInCirculationDate?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  trusteeName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wholeSaleFlag?: InputMaybe<SortOrder>;
};

export type FundPolicy = {
  __typename?: 'FundPolicy';
  coolingOffPeriodDays: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fund: Fund;
  id: Scalars['ID']['output'];
  minHoldingUnit: Scalars['Decimal']['output'];
  minInitInvestment: Scalars['Decimal']['output'];
  minRedemptionUnit: Scalars['Decimal']['output'];
  minSubsequentInvestment: Scalars['Decimal']['output'];
  purchasePeriodDays: Scalars['Int']['output'];
  redemptionPeriodDays: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FundPolicyAvgAggregate = {
  __typename?: 'FundPolicyAvgAggregate';
  coolingOffPeriodDays?: Maybe<Scalars['Float']['output']>;
  minHoldingUnit?: Maybe<Scalars['Decimal']['output']>;
  minInitInvestment?: Maybe<Scalars['Decimal']['output']>;
  minRedemptionUnit?: Maybe<Scalars['Decimal']['output']>;
  minSubsequentInvestment?: Maybe<Scalars['Decimal']['output']>;
  purchasePeriodDays?: Maybe<Scalars['Float']['output']>;
  redemptionPeriodDays?: Maybe<Scalars['Float']['output']>;
};

export type FundPolicyCountAggregate = {
  __typename?: 'FundPolicyCountAggregate';
  _all: Scalars['Int']['output'];
  coolingOffPeriodDays: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  currencyCode: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  fundCode: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  minHoldingUnit: Scalars['Int']['output'];
  minInitInvestment: Scalars['Int']['output'];
  minRedemptionUnit: Scalars['Int']['output'];
  minSubsequentInvestment: Scalars['Int']['output'];
  purchasePeriodDays: Scalars['Int']['output'];
  redemptionPeriodDays: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type FundPolicyCreateOrConnectWithoutFundInput = {
  create: FundPolicyUncheckedCreateWithoutFundInput;
  where: FundPolicyWhereUniqueInput;
};

export type FundPolicyMaxAggregate = {
  __typename?: 'FundPolicyMaxAggregate';
  coolingOffPeriodDays?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  minHoldingUnit?: Maybe<Scalars['Decimal']['output']>;
  minInitInvestment?: Maybe<Scalars['Decimal']['output']>;
  minRedemptionUnit?: Maybe<Scalars['Decimal']['output']>;
  minSubsequentInvestment?: Maybe<Scalars['Decimal']['output']>;
  purchasePeriodDays?: Maybe<Scalars['Int']['output']>;
  redemptionPeriodDays?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundPolicyMinAggregate = {
  __typename?: 'FundPolicyMinAggregate';
  coolingOffPeriodDays?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  minHoldingUnit?: Maybe<Scalars['Decimal']['output']>;
  minInitInvestment?: Maybe<Scalars['Decimal']['output']>;
  minRedemptionUnit?: Maybe<Scalars['Decimal']['output']>;
  minSubsequentInvestment?: Maybe<Scalars['Decimal']['output']>;
  purchasePeriodDays?: Maybe<Scalars['Int']['output']>;
  redemptionPeriodDays?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundPolicyNullableRelationFilter = {
  is?: InputMaybe<FundPolicyWhereInput>;
  isNot?: InputMaybe<FundPolicyWhereInput>;
};

export type FundPolicyOrderByWithRelationInput = {
  coolingOffPeriodDays?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  fund?: InputMaybe<FundOrderByWithRelationInput>;
  fundCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  minHoldingUnit?: InputMaybe<SortOrder>;
  minInitInvestment?: InputMaybe<SortOrder>;
  minRedemptionUnit?: InputMaybe<SortOrder>;
  minSubsequentInvestment?: InputMaybe<SortOrder>;
  purchasePeriodDays?: InputMaybe<SortOrder>;
  redemptionPeriodDays?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FundPolicySumAggregate = {
  __typename?: 'FundPolicySumAggregate';
  coolingOffPeriodDays?: Maybe<Scalars['Int']['output']>;
  minHoldingUnit?: Maybe<Scalars['Decimal']['output']>;
  minInitInvestment?: Maybe<Scalars['Decimal']['output']>;
  minRedemptionUnit?: Maybe<Scalars['Decimal']['output']>;
  minSubsequentInvestment?: Maybe<Scalars['Decimal']['output']>;
  purchasePeriodDays?: Maybe<Scalars['Int']['output']>;
  redemptionPeriodDays?: Maybe<Scalars['Int']['output']>;
};

export type FundPolicyUncheckedCreateInput = {
  coolingOffPeriodDays: Scalars['Int']['input'];
  currencyCode: Scalars['String']['input'];
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  minHoldingUnit: Scalars['Decimal']['input'];
  minInitInvestment: Scalars['Decimal']['input'];
  minRedemptionUnit: Scalars['Decimal']['input'];
  minSubsequentInvestment: Scalars['Decimal']['input'];
  purchasePeriodDays: Scalars['Int']['input'];
  redemptionPeriodDays: Scalars['Int']['input'];
};

export type FundPolicyUncheckedCreateNestedOneWithoutFundInput = {
  connect?: InputMaybe<FundPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FundPolicyCreateOrConnectWithoutFundInput>;
  create?: InputMaybe<FundPolicyUncheckedCreateWithoutFundInput>;
};

export type FundPolicyUncheckedCreateWithoutFundInput = {
  coolingOffPeriodDays: Scalars['Int']['input'];
  currencyCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  minHoldingUnit: Scalars['Decimal']['input'];
  minInitInvestment: Scalars['Decimal']['input'];
  minRedemptionUnit: Scalars['Decimal']['input'];
  minSubsequentInvestment: Scalars['Decimal']['input'];
  purchasePeriodDays: Scalars['Int']['input'];
  redemptionPeriodDays: Scalars['Int']['input'];
};

export type FundPolicyUncheckedUpdateInput = {
  coolingOffPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  minHoldingUnit?: InputMaybe<Scalars['Decimal']['input']>;
  minInitInvestment?: InputMaybe<Scalars['Decimal']['input']>;
  minRedemptionUnit?: InputMaybe<Scalars['Decimal']['input']>;
  minSubsequentInvestment?: InputMaybe<Scalars['Decimal']['input']>;
  purchasePeriodDays?: InputMaybe<Scalars['Int']['input']>;
  redemptionPeriodDays?: InputMaybe<Scalars['Int']['input']>;
};

export type FundPolicyUncheckedUpdateOneWithoutFundNestedInput = {
  connect?: InputMaybe<FundPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FundPolicyCreateOrConnectWithoutFundInput>;
  create?: InputMaybe<FundPolicyUncheckedCreateWithoutFundInput>;
  delete?: InputMaybe<FundPolicyWhereInput>;
  disconnect?: InputMaybe<FundPolicyWhereInput>;
  update?: InputMaybe<FundPolicyUncheckedUpdateWithoutFundInput>;
  upsert?: InputMaybe<FundPolicyUpsertWithoutFundInput>;
};

export type FundPolicyUncheckedUpdateWithoutFundInput = {
  coolingOffPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  minHoldingUnit?: InputMaybe<Scalars['Decimal']['input']>;
  minInitInvestment?: InputMaybe<Scalars['Decimal']['input']>;
  minRedemptionUnit?: InputMaybe<Scalars['Decimal']['input']>;
  minSubsequentInvestment?: InputMaybe<Scalars['Decimal']['input']>;
  purchasePeriodDays?: InputMaybe<Scalars['Int']['input']>;
  redemptionPeriodDays?: InputMaybe<Scalars['Int']['input']>;
};

export type FundPolicyUpsertWithoutFundInput = {
  create: FundPolicyUncheckedCreateWithoutFundInput;
  update: FundPolicyUncheckedUpdateWithoutFundInput;
  where?: InputMaybe<FundPolicyWhereInput>;
};

export type FundPolicyWhereInput = {
  AND?: InputMaybe<Array<FundPolicyWhereInput>>;
  NOT?: InputMaybe<Array<FundPolicyWhereInput>>;
  OR?: InputMaybe<Array<FundPolicyWhereInput>>;
  coolingOffPeriodDays?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  minHoldingUnit?: InputMaybe<DecimalFilter>;
  minInitInvestment?: InputMaybe<DecimalFilter>;
  minRedemptionUnit?: InputMaybe<DecimalFilter>;
  minSubsequentInvestment?: InputMaybe<DecimalFilter>;
  purchasePeriodDays?: InputMaybe<IntFilter>;
  redemptionPeriodDays?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundPolicyWhereUniqueInput = {
  AND?: InputMaybe<Array<FundPolicyWhereInput>>;
  NOT?: InputMaybe<Array<FundPolicyWhereInput>>;
  OR?: InputMaybe<Array<FundPolicyWhereInput>>;
  coolingOffPeriodDays?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  minHoldingUnit?: InputMaybe<DecimalFilter>;
  minInitInvestment?: InputMaybe<DecimalFilter>;
  minRedemptionUnit?: InputMaybe<DecimalFilter>;
  minSubsequentInvestment?: InputMaybe<DecimalFilter>;
  purchasePeriodDays?: InputMaybe<IntFilter>;
  redemptionPeriodDays?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundPrice = {
  __typename?: 'FundPrice';
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fund: Fund;
  id: Scalars['ID']['output'];
  price: Scalars['Decimal']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FundPriceAvgAggregate = {
  __typename?: 'FundPriceAvgAggregate';
  price?: Maybe<Scalars['Decimal']['output']>;
};

export type FundPriceCountAggregate = {
  __typename?: 'FundPriceCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  fundCode: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type FundPriceCreateManyFundInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Decimal']['input'];
};

export type FundPriceCreateManyFundInputEnvelope = {
  data: Array<FundPriceCreateManyFundInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FundPriceCreateOrConnectWithoutFundInput = {
  create: FundPriceUncheckedCreateWithoutFundInput;
  where: FundPriceWhereUniqueInput;
};

export type FundPriceCreateWithoutFundInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Decimal']['input'];
};

export type FundPriceListRelationFilter = {
  every?: InputMaybe<FundPriceWhereInput>;
  none?: InputMaybe<FundPriceWhereInput>;
  some?: InputMaybe<FundPriceWhereInput>;
};

export type FundPriceMaxAggregate = {
  __typename?: 'FundPriceMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundPriceMinAggregate = {
  __typename?: 'FundPriceMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundPriceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FundPriceOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  fund?: InputMaybe<FundOrderByWithRelationInput>;
  fundCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum FundPriceScalarFieldEnum {
  CreatedAt = 'createdAt',
  Date = 'date',
  DeletedAt = 'deletedAt',
  FundCode = 'fundCode',
  Id = 'id',
  Price = 'price',
  UpdatedAt = 'updatedAt'
}

export type FundPriceScalarWhereInput = {
  AND?: InputMaybe<Array<FundPriceScalarWhereInput>>;
  NOT?: InputMaybe<Array<FundPriceScalarWhereInput>>;
  OR?: InputMaybe<Array<FundPriceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundPriceSumAggregate = {
  __typename?: 'FundPriceSumAggregate';
  price?: Maybe<Scalars['Decimal']['output']>;
};

export type FundPriceUncheckedCreateInput = {
  date: Scalars['DateTime']['input'];
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Decimal']['input'];
};

export type FundPriceUncheckedCreateNestedManyWithoutFundInput = {
  connect?: InputMaybe<Array<FundPriceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FundPriceCreateOrConnectWithoutFundInput>>;
  create?: InputMaybe<Array<FundPriceCreateWithoutFundInput>>;
  createMany?: InputMaybe<FundPriceCreateManyFundInputEnvelope>;
};

export type FundPriceUncheckedCreateWithoutFundInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Decimal']['input'];
};

export type FundPriceUncheckedUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Decimal']['input']>;
};

export type FundPriceUncheckedUpdateManyWithoutFundInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Decimal']['input']>;
};

export type FundPriceUncheckedUpdateManyWithoutFundNestedInput = {
  connect?: InputMaybe<Array<FundPriceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FundPriceCreateOrConnectWithoutFundInput>>;
  create?: InputMaybe<Array<FundPriceCreateWithoutFundInput>>;
  createMany?: InputMaybe<FundPriceCreateManyFundInputEnvelope>;
  delete?: InputMaybe<Array<FundPriceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FundPriceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FundPriceWhereUniqueInput>>;
  set?: InputMaybe<Array<FundPriceWhereUniqueInput>>;
  update?: InputMaybe<Array<FundPriceUpdateWithWhereUniqueWithoutFundInput>>;
  updateMany?: InputMaybe<Array<FundPriceUpdateManyWithWhereWithoutFundInput>>;
  upsert?: InputMaybe<Array<FundPriceUpsertWithWhereUniqueWithoutFundInput>>;
};

export type FundPriceUncheckedUpdateWithoutFundInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Decimal']['input']>;
};

export type FundPriceUpdateManyWithWhereWithoutFundInput = {
  data: FundPriceUncheckedUpdateManyWithoutFundInput;
  where: FundPriceScalarWhereInput;
};

export type FundPriceUpdateWithWhereUniqueWithoutFundInput = {
  data: FundPriceUncheckedUpdateWithoutFundInput;
  where: FundPriceWhereUniqueInput;
};

export type FundPriceUpsertWithWhereUniqueWithoutFundInput = {
  create: FundPriceUncheckedCreateWithoutFundInput;
  update: FundPriceUncheckedUpdateWithoutFundInput;
  where: FundPriceWhereUniqueInput;
};

export type FundPriceWhereInput = {
  AND?: InputMaybe<Array<FundPriceWhereInput>>;
  NOT?: InputMaybe<Array<FundPriceWhereInput>>;
  OR?: InputMaybe<Array<FundPriceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundPriceWhereUniqueInput = {
  AND?: InputMaybe<Array<FundPriceWhereInput>>;
  NOT?: InputMaybe<Array<FundPriceWhereInput>>;
  OR?: InputMaybe<Array<FundPriceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FundRelationFilter = {
  is?: InputMaybe<FundWhereInput>;
  isNot?: InputMaybe<FundWhereInput>;
};

export enum FundRiskProfile {
  High = 'high',
  Low = 'low',
  LowMedium = 'low_medium',
  Medium = 'medium',
  MediumHigh = 'medium_high'
}

export enum FundScalarFieldEnum {
  AnnualExpenseRatio = 'annualExpenseRatio',
  AnnualExpenseRatioDate = 'annualExpenseRatioDate',
  AnnualManagementFee = 'annualManagementFee',
  AnnualTrusteeFee = 'annualTrusteeFee',
  AssetAllocation = 'assetAllocation',
  Benchmark = 'benchmark',
  CashManagement = 'cashManagement',
  Category = 'category',
  Classes = 'classes',
  CollectionBankAccountId = 'collectionBankAccountId',
  CreatedAt = 'createdAt',
  CreationStatus = 'creationStatus',
  DeletedAt = 'deletedAt',
  DistributionPolicy = 'distributionPolicy',
  DistributionType = 'distributionType',
  EsgFlag = 'esgFlag',
  FinancialYearEnd = 'financialYearEnd',
  FundCode = 'fundCode',
  FundName = 'fundName',
  FundRiskProfile = 'fundRiskProfile',
  FundSize = 'fundSize',
  FundSizeCurrency = 'fundSizeCurrency',
  FundSizeDate = 'fundSizeDate',
  FundStatus = 'fundStatus',
  FundType = 'fundType',
  GeographicalLocation = 'geographicalLocation',
  Id = 'id',
  InvestmentObj = 'investmentObj',
  LaunchDate = 'launchDate',
  LaunchPrice = 'launchPrice',
  LipperMyClass = 'lipperMyClass',
  LocalFlag = 'localFlag',
  PricingBasic = 'pricingBasic',
  SalesChargePercent = 'salesChargePercent',
  ShariahFlag = 'shariahFlag',
  Status = 'status',
  SwitchFee = 'switchFee',
  TotalUnitInCirculation = 'totalUnitInCirculation',
  TotalUnitInCirculationDate = 'totalUnitInCirculationDate',
  TrusteeName = 'trusteeName',
  UpdatedAt = 'updatedAt',
  WholeSaleFlag = 'wholeSaleFlag'
}

export type FundSumAggregate = {
  __typename?: 'FundSumAggregate';
  annualExpenseRatio?: Maybe<Scalars['Decimal']['output']>;
  annualManagementFee?: Maybe<Scalars['Decimal']['output']>;
  annualTrusteeFee?: Maybe<Scalars['Decimal']['output']>;
  fundSize?: Maybe<Scalars['Int']['output']>;
  launchPrice?: Maybe<Scalars['Decimal']['output']>;
  salesChargePercent?: Maybe<Scalars['Decimal']['output']>;
  switchFee?: Maybe<Scalars['Decimal']['output']>;
  totalUnitInCirculation?: Maybe<Scalars['Int']['output']>;
};

export type FundUncheckedCreateInput = {
  annualExpenseRatio: Scalars['Decimal']['input'];
  annualExpenseRatioDate: Scalars['DateTime']['input'];
  annualManagementFee: Scalars['Decimal']['input'];
  annualTrusteeFee: Scalars['Decimal']['input'];
  assetAllocation: Scalars['String']['input'];
  benchmark: Scalars['String']['input'];
  cashManagement: Scalars['Boolean']['input'];
  category: FundCategories;
  classes: Scalars['String']['input'];
  collectionBankAccountId: Scalars['String']['input'];
  creationStatus?: InputMaybe<CreationStatus>;
  distributionPolicy: Scalars['String']['input'];
  distributionType: Scalars['String']['input'];
  esgFlag: Scalars['Boolean']['input'];
  financialYearEnd: Scalars['String']['input'];
  fundCode: Scalars['String']['input'];
  fundDocuments?: InputMaybe<FundDocumentUncheckedCreateNestedManyWithoutFundInput>;
  fundName: Scalars['String']['input'];
  fundPolicy?: InputMaybe<FundPolicyUncheckedCreateNestedOneWithoutFundInput>;
  fundPrices?: InputMaybe<FundPriceUncheckedCreateNestedManyWithoutFundInput>;
  fundRiskProfile: FundRiskProfile;
  fundSize: Scalars['Int']['input'];
  fundSizeCurrency: Scalars['String']['input'];
  fundSizeDate: Scalars['DateTime']['input'];
  fundStatus: Scalars['String']['input'];
  fundType: Scalars['String']['input'];
  geographicalLocation: Scalars['String']['input'];
  holidays?: InputMaybe<FundHolidayUncheckedCreateNestedManyWithoutFundInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  investmentObj: Scalars['String']['input'];
  launchDate: Scalars['DateTime']['input'];
  launchPrice: Scalars['Decimal']['input'];
  lipperMyClass: Scalars['String']['input'];
  localFlag: Scalars['Boolean']['input'];
  pricingBasic: Scalars['String']['input'];
  salesChargePercent: Scalars['Decimal']['input'];
  shariahFlag: Scalars['Boolean']['input'];
  status?: InputMaybe<Status>;
  switchFee: Scalars['Decimal']['input'];
  totalUnitInCirculation: Scalars['Int']['input'];
  totalUnitInCirculationDate: Scalars['DateTime']['input'];
  trusteeName: Scalars['String']['input'];
  wholeSaleFlag: Scalars['Boolean']['input'];
};

export type FundUncheckedCreateWithoutAccountPortfoliosInput = {
  annualExpenseRatio: Scalars['Decimal']['input'];
  annualExpenseRatioDate: Scalars['DateTime']['input'];
  annualManagementFee: Scalars['Decimal']['input'];
  annualTrusteeFee: Scalars['Decimal']['input'];
  assetAllocation: Scalars['String']['input'];
  benchmark: Scalars['String']['input'];
  cashManagement: Scalars['Boolean']['input'];
  category: FundCategories;
  classes: Scalars['String']['input'];
  collectionBankAccountId: Scalars['String']['input'];
  creationStatus?: InputMaybe<CreationStatus>;
  distributionPolicy: Scalars['String']['input'];
  distributionType: Scalars['String']['input'];
  esgFlag: Scalars['Boolean']['input'];
  financialYearEnd: Scalars['String']['input'];
  fundCode: Scalars['String']['input'];
  fundDocuments?: InputMaybe<FundDocumentUncheckedCreateNestedManyWithoutFundInput>;
  fundName: Scalars['String']['input'];
  fundPolicy?: InputMaybe<FundPolicyUncheckedCreateNestedOneWithoutFundInput>;
  fundPrices?: InputMaybe<FundPriceUncheckedCreateNestedManyWithoutFundInput>;
  fundRiskProfile: FundRiskProfile;
  fundSize: Scalars['Int']['input'];
  fundSizeCurrency: Scalars['String']['input'];
  fundSizeDate: Scalars['DateTime']['input'];
  fundStatus: Scalars['String']['input'];
  fundType: Scalars['String']['input'];
  geographicalLocation: Scalars['String']['input'];
  holidays?: InputMaybe<FundHolidayUncheckedCreateNestedManyWithoutFundInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  investmentObj: Scalars['String']['input'];
  launchDate: Scalars['DateTime']['input'];
  launchPrice: Scalars['Decimal']['input'];
  lipperMyClass: Scalars['String']['input'];
  localFlag: Scalars['Boolean']['input'];
  pricingBasic: Scalars['String']['input'];
  salesChargePercent: Scalars['Decimal']['input'];
  shariahFlag: Scalars['Boolean']['input'];
  status?: InputMaybe<Status>;
  switchFee: Scalars['Decimal']['input'];
  totalUnitInCirculation: Scalars['Int']['input'];
  totalUnitInCirculationDate: Scalars['DateTime']['input'];
  trusteeName: Scalars['String']['input'];
  wholeSaleFlag: Scalars['Boolean']['input'];
};

export type FundUncheckedCreateWithoutSwitchToTransactionsInput = {
  annualExpenseRatio: Scalars['Decimal']['input'];
  annualExpenseRatioDate: Scalars['DateTime']['input'];
  annualManagementFee: Scalars['Decimal']['input'];
  annualTrusteeFee: Scalars['Decimal']['input'];
  assetAllocation: Scalars['String']['input'];
  benchmark: Scalars['String']['input'];
  cashManagement: Scalars['Boolean']['input'];
  category: FundCategories;
  classes: Scalars['String']['input'];
  collectionBankAccountId: Scalars['String']['input'];
  creationStatus?: InputMaybe<CreationStatus>;
  distributionPolicy: Scalars['String']['input'];
  distributionType: Scalars['String']['input'];
  esgFlag: Scalars['Boolean']['input'];
  financialYearEnd: Scalars['String']['input'];
  fundCode: Scalars['String']['input'];
  fundDocuments?: InputMaybe<FundDocumentUncheckedCreateNestedManyWithoutFundInput>;
  fundName: Scalars['String']['input'];
  fundPolicy?: InputMaybe<FundPolicyUncheckedCreateNestedOneWithoutFundInput>;
  fundPrices?: InputMaybe<FundPriceUncheckedCreateNestedManyWithoutFundInput>;
  fundRiskProfile: FundRiskProfile;
  fundSize: Scalars['Int']['input'];
  fundSizeCurrency: Scalars['String']['input'];
  fundSizeDate: Scalars['DateTime']['input'];
  fundStatus: Scalars['String']['input'];
  fundType: Scalars['String']['input'];
  geographicalLocation: Scalars['String']['input'];
  holidays?: InputMaybe<FundHolidayUncheckedCreateNestedManyWithoutFundInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  investmentObj: Scalars['String']['input'];
  launchDate: Scalars['DateTime']['input'];
  launchPrice: Scalars['Decimal']['input'];
  lipperMyClass: Scalars['String']['input'];
  localFlag: Scalars['Boolean']['input'];
  pricingBasic: Scalars['String']['input'];
  salesChargePercent: Scalars['Decimal']['input'];
  shariahFlag: Scalars['Boolean']['input'];
  status?: InputMaybe<Status>;
  switchFee: Scalars['Decimal']['input'];
  totalUnitInCirculation: Scalars['Int']['input'];
  totalUnitInCirculationDate: Scalars['DateTime']['input'];
  trusteeName: Scalars['String']['input'];
  wholeSaleFlag: Scalars['Boolean']['input'];
};

export type FundUncheckedCreateWithoutTransactionsInput = {
  annualExpenseRatio: Scalars['Decimal']['input'];
  annualExpenseRatioDate: Scalars['DateTime']['input'];
  annualManagementFee: Scalars['Decimal']['input'];
  annualTrusteeFee: Scalars['Decimal']['input'];
  assetAllocation: Scalars['String']['input'];
  benchmark: Scalars['String']['input'];
  cashManagement: Scalars['Boolean']['input'];
  category: FundCategories;
  classes: Scalars['String']['input'];
  collectionBankAccountId: Scalars['String']['input'];
  creationStatus?: InputMaybe<CreationStatus>;
  distributionPolicy: Scalars['String']['input'];
  distributionType: Scalars['String']['input'];
  esgFlag: Scalars['Boolean']['input'];
  financialYearEnd: Scalars['String']['input'];
  fundCode: Scalars['String']['input'];
  fundDocuments?: InputMaybe<FundDocumentUncheckedCreateNestedManyWithoutFundInput>;
  fundName: Scalars['String']['input'];
  fundPolicy?: InputMaybe<FundPolicyUncheckedCreateNestedOneWithoutFundInput>;
  fundPrices?: InputMaybe<FundPriceUncheckedCreateNestedManyWithoutFundInput>;
  fundRiskProfile: FundRiskProfile;
  fundSize: Scalars['Int']['input'];
  fundSizeCurrency: Scalars['String']['input'];
  fundSizeDate: Scalars['DateTime']['input'];
  fundStatus: Scalars['String']['input'];
  fundType: Scalars['String']['input'];
  geographicalLocation: Scalars['String']['input'];
  holidays?: InputMaybe<FundHolidayUncheckedCreateNestedManyWithoutFundInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  investmentObj: Scalars['String']['input'];
  launchDate: Scalars['DateTime']['input'];
  launchPrice: Scalars['Decimal']['input'];
  lipperMyClass: Scalars['String']['input'];
  localFlag: Scalars['Boolean']['input'];
  pricingBasic: Scalars['String']['input'];
  salesChargePercent: Scalars['Decimal']['input'];
  shariahFlag: Scalars['Boolean']['input'];
  status?: InputMaybe<Status>;
  switchFee: Scalars['Decimal']['input'];
  totalUnitInCirculation: Scalars['Int']['input'];
  totalUnitInCirculationDate: Scalars['DateTime']['input'];
  trusteeName: Scalars['String']['input'];
  wholeSaleFlag: Scalars['Boolean']['input'];
};

export type FundUncheckedUpdateInput = {
  annualExpenseRatio?: InputMaybe<Scalars['Decimal']['input']>;
  annualExpenseRatioDate?: InputMaybe<Scalars['DateTime']['input']>;
  annualManagementFee?: InputMaybe<Scalars['Decimal']['input']>;
  annualTrusteeFee?: InputMaybe<Scalars['Decimal']['input']>;
  assetAllocation?: InputMaybe<Scalars['String']['input']>;
  benchmark?: InputMaybe<Scalars['String']['input']>;
  cashManagement?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<FundCategories>;
  classes?: InputMaybe<Scalars['String']['input']>;
  collectionBankAccountId?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  distributionPolicy?: InputMaybe<Scalars['String']['input']>;
  distributionType?: InputMaybe<Scalars['String']['input']>;
  esgFlag?: InputMaybe<Scalars['Boolean']['input']>;
  financialYearEnd?: InputMaybe<Scalars['String']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  fundDocuments?: InputMaybe<FundDocumentUncheckedUpdateManyWithoutFundNestedInput>;
  fundName?: InputMaybe<Scalars['String']['input']>;
  fundPolicy?: InputMaybe<FundPolicyUncheckedUpdateOneWithoutFundNestedInput>;
  fundPrices?: InputMaybe<FundPriceUncheckedUpdateManyWithoutFundNestedInput>;
  fundRiskProfile?: InputMaybe<FundRiskProfile>;
  fundSize?: InputMaybe<Scalars['Int']['input']>;
  fundSizeCurrency?: InputMaybe<Scalars['String']['input']>;
  fundSizeDate?: InputMaybe<Scalars['DateTime']['input']>;
  fundStatus?: InputMaybe<Scalars['String']['input']>;
  fundType?: InputMaybe<Scalars['String']['input']>;
  geographicalLocation?: InputMaybe<Scalars['String']['input']>;
  holidays?: InputMaybe<FundHolidayUncheckedUpdateManyWithoutFundNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  investmentObj?: InputMaybe<Scalars['String']['input']>;
  launchDate?: InputMaybe<Scalars['DateTime']['input']>;
  launchPrice?: InputMaybe<Scalars['Decimal']['input']>;
  lipperMyClass?: InputMaybe<Scalars['String']['input']>;
  localFlag?: InputMaybe<Scalars['Boolean']['input']>;
  pricingBasic?: InputMaybe<Scalars['String']['input']>;
  salesChargePercent?: InputMaybe<Scalars['Decimal']['input']>;
  shariahFlag?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Status>;
  switchFee?: InputMaybe<Scalars['Decimal']['input']>;
  totalUnitInCirculation?: InputMaybe<Scalars['Int']['input']>;
  totalUnitInCirculationDate?: InputMaybe<Scalars['DateTime']['input']>;
  trusteeName?: InputMaybe<Scalars['String']['input']>;
  wholeSaleFlag?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FundWhereInput = {
  AND?: InputMaybe<Array<FundWhereInput>>;
  NOT?: InputMaybe<Array<FundWhereInput>>;
  OR?: InputMaybe<Array<FundWhereInput>>;
  accountPortfolios?: InputMaybe<AccountPortfolioListRelationFilter>;
  annualExpenseRatio?: InputMaybe<DecimalFilter>;
  annualExpenseRatioDate?: InputMaybe<DateTimeFilter>;
  annualManagementFee?: InputMaybe<DecimalFilter>;
  annualTrusteeFee?: InputMaybe<DecimalFilter>;
  assetAllocation?: InputMaybe<StringFilter>;
  benchmark?: InputMaybe<StringFilter>;
  cashManagement?: InputMaybe<BoolFilter>;
  category?: InputMaybe<EnumFundCategoriesFilter>;
  classes?: InputMaybe<StringFilter>;
  collectionBankAccount?: InputMaybe<CollectionBankAccountRelationFilter>;
  collectionBankAccountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  distributionPolicy?: InputMaybe<StringFilter>;
  distributionType?: InputMaybe<StringFilter>;
  esgFlag?: InputMaybe<BoolFilter>;
  financialYearEnd?: InputMaybe<StringFilter>;
  fundCode?: InputMaybe<StringFilter>;
  fundDocuments?: InputMaybe<FundDocumentListRelationFilter>;
  fundName?: InputMaybe<StringFilter>;
  fundPolicy?: InputMaybe<FundPolicyNullableRelationFilter>;
  fundPrices?: InputMaybe<FundPriceListRelationFilter>;
  fundRiskProfile?: InputMaybe<EnumFundRiskProfileFilter>;
  fundSize?: InputMaybe<IntFilter>;
  fundSizeCurrency?: InputMaybe<StringFilter>;
  fundSizeDate?: InputMaybe<DateTimeFilter>;
  fundStatus?: InputMaybe<StringFilter>;
  fundType?: InputMaybe<StringFilter>;
  geographicalLocation?: InputMaybe<StringFilter>;
  holidays?: InputMaybe<FundHolidayListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  investmentObj?: InputMaybe<StringFilter>;
  launchDate?: InputMaybe<DateTimeFilter>;
  launchPrice?: InputMaybe<DecimalFilter>;
  lipperMyClass?: InputMaybe<StringFilter>;
  localFlag?: InputMaybe<BoolFilter>;
  pricingBasic?: InputMaybe<StringFilter>;
  salesChargePercent?: InputMaybe<DecimalFilter>;
  shariahFlag?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  switchFee?: InputMaybe<DecimalFilter>;
  switchToTransactions?: InputMaybe<TransactionListRelationFilter>;
  totalUnitInCirculation?: InputMaybe<IntFilter>;
  totalUnitInCirculationDate?: InputMaybe<DateTimeFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  trusteeName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wholeSaleFlag?: InputMaybe<BoolFilter>;
};

export type FundWhereUniqueInput = {
  AND?: InputMaybe<Array<FundWhereInput>>;
  NOT?: InputMaybe<Array<FundWhereInput>>;
  OR?: InputMaybe<Array<FundWhereInput>>;
  accountPortfolios?: InputMaybe<AccountPortfolioListRelationFilter>;
  annualExpenseRatio?: InputMaybe<DecimalFilter>;
  annualExpenseRatioDate?: InputMaybe<DateTimeFilter>;
  annualManagementFee?: InputMaybe<DecimalFilter>;
  annualTrusteeFee?: InputMaybe<DecimalFilter>;
  assetAllocation?: InputMaybe<StringFilter>;
  benchmark?: InputMaybe<StringFilter>;
  cashManagement?: InputMaybe<BoolFilter>;
  category?: InputMaybe<EnumFundCategoriesFilter>;
  classes?: InputMaybe<StringFilter>;
  collectionBankAccount?: InputMaybe<CollectionBankAccountRelationFilter>;
  collectionBankAccountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  distributionPolicy?: InputMaybe<StringFilter>;
  distributionType?: InputMaybe<StringFilter>;
  esgFlag?: InputMaybe<BoolFilter>;
  financialYearEnd?: InputMaybe<StringFilter>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  fundDocuments?: InputMaybe<FundDocumentListRelationFilter>;
  fundName?: InputMaybe<StringFilter>;
  fundPolicy?: InputMaybe<FundPolicyNullableRelationFilter>;
  fundPrices?: InputMaybe<FundPriceListRelationFilter>;
  fundRiskProfile?: InputMaybe<EnumFundRiskProfileFilter>;
  fundSize?: InputMaybe<IntFilter>;
  fundSizeCurrency?: InputMaybe<StringFilter>;
  fundSizeDate?: InputMaybe<DateTimeFilter>;
  fundStatus?: InputMaybe<StringFilter>;
  fundType?: InputMaybe<StringFilter>;
  geographicalLocation?: InputMaybe<StringFilter>;
  holidays?: InputMaybe<FundHolidayListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  investmentObj?: InputMaybe<StringFilter>;
  launchDate?: InputMaybe<DateTimeFilter>;
  launchPrice?: InputMaybe<DecimalFilter>;
  lipperMyClass?: InputMaybe<StringFilter>;
  localFlag?: InputMaybe<BoolFilter>;
  pricingBasic?: InputMaybe<StringFilter>;
  salesChargePercent?: InputMaybe<DecimalFilter>;
  shariahFlag?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  switchFee?: InputMaybe<DecimalFilter>;
  switchToTransactions?: InputMaybe<TransactionListRelationFilter>;
  totalUnitInCirculation?: InputMaybe<IntFilter>;
  totalUnitInCirculationDate?: InputMaybe<DateTimeFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  trusteeName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wholeSaleFlag?: InputMaybe<BoolFilter>;
};

export type GeneratePresignedFileInput = {
  fileName: Scalars['String']['input'];
  /** The purpose of the upload, such as: profile_picture, ... */
  purpose: Scalars['String']['input'];
  referenceId?: InputMaybe<Scalars['String']['input']>;
};

export type GeographicalSummary = {
  __typename?: 'GeographicalSummary';
  geographical: Scalars['String']['output'];
  values: Array<AmountValue>;
};

export type GetSignedFileInput = {
  location: Scalars['String']['input'];
};

export enum IdType {
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  Nric = 'NRIC',
  Passport = 'Passport'
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum Interface {
  Admin = 'Admin',
  App = 'App'
}

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type ListAccountPortfoliosByUserFilter = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fundCategory?: InputMaybe<FundCategories>;
};

export type ListTransactionsByUserFilter = {
  accountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  publicStatus?: InputMaybe<TransactionPublicStatus>;
};

export type LoginInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export enum ModeOfContact {
  Email = 'Email',
  Phone = 'Phone'
}

export type Modification = {
  __typename?: 'Modification';
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['String']['output'];
  entityType: ModificationEntityType;
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  newValues?: Maybe<Scalars['String']['output']>;
  oldValues?: Maybe<Scalars['String']['output']>;
  referenceKeys: Scalars['JSON']['output'];
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  status: ModificationStatus;
  type: ModificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ModificationCountAggregate = {
  __typename?: 'ModificationCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  entityId: Scalars['Int']['output'];
  entityType: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modifiedAt: Scalars['Int']['output'];
  modifiedBy: Scalars['Int']['output'];
  newValues: Scalars['Int']['output'];
  oldValues: Scalars['Int']['output'];
  referenceKeys: Scalars['Int']['output'];
  reviewedAt: Scalars['Int']['output'];
  reviewedBy: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export enum ModificationEntityType {
  AdminRole = 'AdminRole',
  AdminUser = 'AdminUser',
  Announcement = 'Announcement',
  Fund = 'Fund',
  FundHoliday = 'FundHoliday',
  Order = 'Order',
  ProfileConsent = 'ProfileConsent',
  PublicHoliday = 'PublicHoliday',
  User = 'User'
}

export type ModificationMaxAggregate = {
  __typename?: 'ModificationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<ModificationEntityType>;
  id?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  newValues?: Maybe<Scalars['String']['output']>;
  oldValues?: Maybe<Scalars['String']['output']>;
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ModificationStatus>;
  type?: Maybe<ModificationType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ModificationMinAggregate = {
  __typename?: 'ModificationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<ModificationEntityType>;
  id?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  newValues?: Maybe<Scalars['String']['output']>;
  oldValues?: Maybe<Scalars['String']['output']>;
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ModificationStatus>;
  type?: Maybe<ModificationType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ModificationModel = {
  __typename?: 'ModificationModel';
  after: Scalars['JSON']['output'];
  before: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['String']['output'];
  entityType: ModificationEntityType;
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  modifyingUser?: Maybe<AdminUser>;
  newValues?: Maybe<Scalars['String']['output']>;
  oldValues?: Maybe<Scalars['String']['output']>;
  referenceKeys: Scalars['JSON']['output'];
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  reviewingUser?: Maybe<AdminUser>;
  status: ModificationStatus;
  type: ModificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ModificationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  entityType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modifiedAt?: InputMaybe<SortOrder>;
  modifiedBy?: InputMaybe<SortOrder>;
  newValues?: InputMaybe<SortOrderInput>;
  oldValues?: InputMaybe<SortOrderInput>;
  referenceKeys?: InputMaybe<SortOrder>;
  reviewedAt?: InputMaybe<SortOrderInput>;
  reviewedBy?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ModificationStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum ModificationType {
  Create = 'Create',
  Delete = 'Delete',
  Update = 'Update'
}

export type ModificationUncheckedCreateInput = {
  entityId: Scalars['String']['input'];
  entityType: ModificationEntityType;
  id?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedBy: Scalars['String']['input'];
  newValues?: InputMaybe<Scalars['String']['input']>;
  oldValues?: InputMaybe<Scalars['String']['input']>;
  referenceKeys?: InputMaybe<Scalars['JSON']['input']>;
  reviewedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewedBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ModificationStatus>;
  type: ModificationType;
};

export type ModificationUncheckedUpdateInput = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<ModificationEntityType>;
  id?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  newValues?: InputMaybe<Scalars['String']['input']>;
  oldValues?: InputMaybe<Scalars['String']['input']>;
  referenceKeys?: InputMaybe<Scalars['JSON']['input']>;
  reviewedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewedBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ModificationStatus>;
  type?: InputMaybe<ModificationType>;
};

export type ModificationWhereInput = {
  AND?: InputMaybe<Array<ModificationWhereInput>>;
  NOT?: InputMaybe<Array<ModificationWhereInput>>;
  OR?: InputMaybe<Array<ModificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringFilter>;
  entityType?: InputMaybe<EnumModificationEntityTypeFilter>;
  id?: InputMaybe<StringFilter>;
  modifiedAt?: InputMaybe<DateTimeFilter>;
  modifiedBy?: InputMaybe<StringFilter>;
  newValues?: InputMaybe<StringNullableFilter>;
  oldValues?: InputMaybe<StringNullableFilter>;
  referenceKeys?: InputMaybe<JsonFilter>;
  reviewedAt?: InputMaybe<DateTimeNullableFilter>;
  reviewedBy?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumModificationStatusFilter>;
  type?: InputMaybe<EnumModificationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModificationWhereUniqueInput = {
  AND?: InputMaybe<Array<ModificationWhereInput>>;
  NOT?: InputMaybe<Array<ModificationWhereInput>>;
  OR?: InputMaybe<Array<ModificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringFilter>;
  entityType?: InputMaybe<EnumModificationEntityTypeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<DateTimeFilter>;
  modifiedBy?: InputMaybe<StringFilter>;
  newValues?: InputMaybe<StringNullableFilter>;
  oldValues?: InputMaybe<StringNullableFilter>;
  referenceKeys?: InputMaybe<JsonFilter>;
  reviewedAt?: InputMaybe<DateTimeNullableFilter>;
  reviewedBy?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumModificationStatusFilter>;
  type?: InputMaybe<EnumModificationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminForgetPassword: RequestResponse;
  adminLogin: Auth;
  approveModification?: Maybe<ModificationModel>;
  approveOrder?: Maybe<Order>;
  createOneAccount: Account;
  createOneAccountPortfolio: AccountPortfolio;
  createOneAccountRequest: AccountRequest;
  createOneAdminRole: AdminRole;
  createOneAdminUser: AdminUser;
  createOneAnnouncement?: Maybe<Announcement>;
  createOneAuditTrail: AuditTrail;
  createOneCorporateAccountRequest: AccountRequest;
  createOneCorporateUser?: Maybe<User>;
  createOneDocument: Document;
  createOneFund?: Maybe<Fund>;
  createOneFundDocument: FundDocument;
  createOneFundHoliday?: Maybe<FundHoliday>;
  createOneFundPolicy: FundPolicy;
  createOneFundPrice: FundPrice;
  createOneModification: Modification;
  createOneOrder: Order;
  createOnePayment: Payment;
  createOneProfile: Profile;
  createOneProfileConsent?: Maybe<ProfileConsent>;
  createOnePublicHoliday?: Maybe<PublicHoliday>;
  createOneSignatory: Signatory;
  createOneStatement: Statement;
  createOneTransaction: Transaction;
  createOneUser: User;
  createOrderPayments?: Maybe<Order>;
  createSupportTicket?: Maybe<Scalars['Boolean']['output']>;
  deleteOneAccount?: Maybe<Account>;
  deleteOneAccountPortfolio?: Maybe<AccountPortfolio>;
  deleteOneAccountRequest?: Maybe<AccountRequest>;
  deleteOneAdminRole: AdminRole;
  deleteOneAdminUser?: Maybe<AdminUser>;
  deleteOneAnnouncement?: Maybe<Announcement>;
  deleteOneAuditTrail?: Maybe<AuditTrail>;
  deleteOneDocument?: Maybe<Document>;
  deleteOneFund?: Maybe<Fund>;
  deleteOneFundDocument?: Maybe<FundDocument>;
  deleteOneFundHoliday?: Maybe<FundHoliday>;
  deleteOneFundPolicy?: Maybe<FundPolicy>;
  deleteOneFundPrice?: Maybe<FundPrice>;
  deleteOneModification?: Maybe<Modification>;
  deleteOneOrder?: Maybe<Order>;
  deleteOnePayment?: Maybe<Payment>;
  deleteOneProfile?: Maybe<Profile>;
  deleteOneProfileConsent?: Maybe<ProfileConsent>;
  deleteOnePublicHoliday?: Maybe<PublicHoliday>;
  deleteOneStatement?: Maybe<Statement>;
  deleteOneTransaction?: Maybe<Transaction>;
  deleteOneUser?: Maybe<User>;
  generatePresignedFile: SignedFile;
  getSignedFile: SignedFile;
  login: Auth;
  refreshToken: Auth;
  register: Auth;
  rejectModification?: Maybe<ModificationModel>;
  rejectOrder?: Maybe<Order>;
  requestOtp: OtpChallenge;
  resetPassword: StatusResponse;
  submitOrder?: Maybe<Order>;
  updateOneAccount?: Maybe<Account>;
  updateOneAccountPortfolio?: Maybe<AccountPortfolio>;
  updateOneAccountRequest?: Maybe<AccountRequest>;
  updateOneAdminRole?: Maybe<AdminRole>;
  updateOneAdminUser?: Maybe<AdminUser>;
  updateOneAnnouncement?: Maybe<Announcement>;
  updateOneAuditTrail?: Maybe<AuditTrail>;
  updateOneDocument?: Maybe<Document>;
  updateOneFund?: Maybe<Fund>;
  updateOneFundDocument?: Maybe<FundDocument>;
  updateOneFundHoliday?: Maybe<FundHoliday>;
  updateOneFundPolicy?: Maybe<FundPolicy>;
  updateOneFundPrice?: Maybe<FundPrice>;
  updateOneModification?: Maybe<Modification>;
  updateOneOrder?: Maybe<Order>;
  updateOnePayment: Payment;
  updateOneProfile?: Maybe<Profile>;
  updateOneProfileConsent?: Maybe<ProfileConsent>;
  updateOnePublicHoliday?: Maybe<PublicHoliday>;
  updateOneStatement?: Maybe<Statement>;
  updateOneTransaction?: Maybe<Transaction>;
  updateOneUser?: Maybe<User>;
  verifyOtp: StatusResponse;
};


export type MutationAdminForgetPasswordArgs = {
  data: ForgetPasswordInput;
};


export type MutationAdminLoginArgs = {
  data: AdminLoginInput;
};


export type MutationApproveModificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationApproveOrderArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateOneAccountArgs = {
  data: AccountUncheckedCreateInput;
};


export type MutationCreateOneAccountPortfolioArgs = {
  data: AccountPortfolioUncheckedCreateInput;
};


export type MutationCreateOneAccountRequestArgs = {
  data: AccountRequestUncheckedCreateInput;
};


export type MutationCreateOneAdminRoleArgs = {
  data: AdminRoleCreateInputV2;
};


export type MutationCreateOneAdminUserArgs = {
  data: AdminUserCreateInputV2;
};


export type MutationCreateOneAnnouncementArgs = {
  data: AnnouncementUncheckedCreateInput;
};


export type MutationCreateOneAuditTrailArgs = {
  data: AuditTrailUncheckedCreateInput;
};


export type MutationCreateOneCorporateAccountRequestArgs = {
  data: CorporateAccountRequestCreateInput;
};


export type MutationCreateOneCorporateUserArgs = {
  data: CorporateUserUncheckedCreateInput;
};


export type MutationCreateOneDocumentArgs = {
  data: CreateOneDocumentInput;
};


export type MutationCreateOneFundArgs = {
  data: FundUncheckedCreateInput;
};


export type MutationCreateOneFundDocumentArgs = {
  data: FundDocumentUncheckedCreateInput;
};


export type MutationCreateOneFundHolidayArgs = {
  data: FundHolidayUncheckedCreateInput;
};


export type MutationCreateOneFundPolicyArgs = {
  data: FundPolicyUncheckedCreateInput;
};


export type MutationCreateOneFundPriceArgs = {
  data: FundPriceUncheckedCreateInput;
};


export type MutationCreateOneModificationArgs = {
  data: ModificationUncheckedCreateInput;
};


export type MutationCreateOneOrderArgs = {
  data: OrderUncheckedCreateInput;
};


export type MutationCreateOnePaymentArgs = {
  data: PaymentUncheckedCreateInput;
};


export type MutationCreateOneProfileArgs = {
  data: ProfileUncheckedCreateInput;
};


export type MutationCreateOneProfileConsentArgs = {
  data: ProfileConsentUncheckedCreateInput;
};


export type MutationCreateOnePublicHolidayArgs = {
  data: PublicHolidayUncheckedCreateInput;
};


export type MutationCreateOneSignatoryArgs = {
  data: SignatoryUncheckedCreateInput;
};


export type MutationCreateOneStatementArgs = {
  data: StatementUncheckedCreateInput;
};


export type MutationCreateOneTransactionArgs = {
  data: TransactionUncheckedCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserUncheckedCreateInput;
};


export type MutationCreateOrderPaymentsArgs = {
  orderId: Scalars['ID']['input'];
  payments: Array<PaymentInput>;
};


export type MutationCreateSupportTicketArgs = {
  data: SupportCreateInput;
};


export type MutationDeleteOneAccountArgs = {
  where: AccountWhereUniqueInput;
};


export type MutationDeleteOneAccountPortfolioArgs = {
  where: AccountPortfolioWhereUniqueInput;
};


export type MutationDeleteOneAccountRequestArgs = {
  where: AccountRequestWhereUniqueInput;
};


export type MutationDeleteOneAdminRoleArgs = {
  where: AdminRoleWhereUniqueInput;
};


export type MutationDeleteOneAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};


export type MutationDeleteOneAnnouncementArgs = {
  where: AnnouncementWhereUniqueInput;
};


export type MutationDeleteOneAuditTrailArgs = {
  where: AuditTrailWhereUniqueInput;
};


export type MutationDeleteOneDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type MutationDeleteOneFundArgs = {
  where: FundWhereUniqueInput;
};


export type MutationDeleteOneFundDocumentArgs = {
  where: FundDocumentWhereUniqueInput;
};


export type MutationDeleteOneFundHolidayArgs = {
  where: FundHolidayWhereUniqueInput;
};


export type MutationDeleteOneFundPolicyArgs = {
  where: FundPolicyWhereUniqueInput;
};


export type MutationDeleteOneFundPriceArgs = {
  where: FundPriceWhereUniqueInput;
};


export type MutationDeleteOneModificationArgs = {
  where: ModificationWhereUniqueInput;
};


export type MutationDeleteOneOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteOnePaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type MutationDeleteOneProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type MutationDeleteOneProfileConsentArgs = {
  where: ProfileConsentWhereUniqueInput;
};


export type MutationDeleteOnePublicHolidayArgs = {
  where: PublicHolidayWhereUniqueInput;
};


export type MutationDeleteOneStatementArgs = {
  where: StatementWhereUniqueInput;
};


export type MutationDeleteOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationGeneratePresignedFileArgs = {
  data: GeneratePresignedFileInput;
};


export type MutationGetSignedFileArgs = {
  data: GetSignedFileInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationRejectModificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationRejectOrderArgs = {
  id: Scalars['String']['input'];
};


export type MutationRequestOtpArgs = {
  data: RequestOtpInput;
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationSubmitOrderArgs = {
  order?: InputMaybe<OrderInput>;
};


export type MutationUpdateOneAccountArgs = {
  data: AccountUncheckedUpdateInput;
  where: AccountWhereUniqueInput;
};


export type MutationUpdateOneAccountPortfolioArgs = {
  data: AccountPortfolioUncheckedUpdateInput;
  where: AccountPortfolioWhereUniqueInput;
};


export type MutationUpdateOneAccountRequestArgs = {
  data: AccountRequestUncheckedUpdateInput;
  where: AccountRequestWhereUniqueInput;
};


export type MutationUpdateOneAdminRoleArgs = {
  data: AdminRoleUpdateInputV2;
  where: AdminRoleWhereUniqueInput;
};


export type MutationUpdateOneAdminUserArgs = {
  data: AdminUserUpdateInputV2;
  where: AdminUserWhereUniqueInput;
};


export type MutationUpdateOneAnnouncementArgs = {
  data: AnnouncementUncheckedUpdateInput;
  where: AnnouncementWhereUniqueInput;
};


export type MutationUpdateOneAuditTrailArgs = {
  data: AuditTrailUncheckedUpdateInput;
  where: AuditTrailWhereUniqueInput;
};


export type MutationUpdateOneDocumentArgs = {
  data: DocumentUncheckedUpdateInput;
  where: DocumentWhereUniqueInput;
};


export type MutationUpdateOneFundArgs = {
  data: FundUncheckedUpdateInput;
  where: FundWhereUniqueInput;
};


export type MutationUpdateOneFundDocumentArgs = {
  data: FundDocumentUncheckedUpdateInput;
  where: FundDocumentWhereUniqueInput;
};


export type MutationUpdateOneFundHolidayArgs = {
  data: FundHolidayUncheckedUpdateInput;
  where: FundHolidayWhereUniqueInput;
};


export type MutationUpdateOneFundPolicyArgs = {
  data: FundPolicyUncheckedUpdateInput;
  where: FundPolicyWhereUniqueInput;
};


export type MutationUpdateOneFundPriceArgs = {
  data: FundPriceUncheckedUpdateInput;
  where: FundPriceWhereUniqueInput;
};


export type MutationUpdateOneModificationArgs = {
  data: ModificationUncheckedUpdateInput;
  where: ModificationWhereUniqueInput;
};


export type MutationUpdateOneOrderArgs = {
  data: OrderUncheckedUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateOnePaymentArgs = {
  data: PaymentUncheckedUpdateInput;
  where: PaymentWhereUniqueInput;
};


export type MutationUpdateOneProfileArgs = {
  data: ProfileUncheckedUpdateInput;
  where: ProfileWhereUniqueInput;
};


export type MutationUpdateOneProfileConsentArgs = {
  data: ProfileConsentUncheckedUpdateInput;
  where: ProfileConsentWhereUniqueInput;
};


export type MutationUpdateOnePublicHolidayArgs = {
  data: PublicHolidayUncheckedUpdateInput;
  where: PublicHolidayWhereUniqueInput;
};


export type MutationUpdateOneStatementArgs = {
  data: StatementUncheckedUpdateInput;
  where: StatementWhereUniqueInput;
};


export type MutationUpdateOneTransactionArgs = {
  data: TransactionUncheckedUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUncheckedUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationVerifyOtpArgs = {
  data: VerifyOtpInput;
};

export type MyAccountSummary = {
  __typename?: 'MyAccountSummary';
  currentMarketValues: Array<AmountValue>;
  fundCategorySummaries: Array<FundCategorySummary>;
  geographicalSummaries: Array<GeographicalSummary>;
  profitAndLossValues: Array<ProfitAndLossValue>;
  totalInvestedValues: Array<AmountValue>;
};

export type MyConsentedProfileSummary = {
  __typename?: 'MyConsentedProfileSummary';
  companyPerformances: Array<CompanyPerformance>;
  currentMarketValues: Array<AmountValue>;
  fundCategorySummaries: Array<FundCategorySummary>;
  geographicalSummaries: Array<GeographicalSummary>;
  profitAndLossValues: Array<ProfitAndLossValue>;
  totalInvestedValues: Array<AmountValue>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBytesFilter = {
  equals?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<Scalars['Byte']['input']>>;
  not?: InputMaybe<NestedBytesFilter>;
  notIn?: InputMaybe<Array<Scalars['Byte']['input']>>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedEnumAccountTypeFilter = {
  equals?: InputMaybe<AccountType>;
  in?: InputMaybe<Array<AccountType>>;
  not?: InputMaybe<NestedEnumAccountTypeFilter>;
  notIn?: InputMaybe<Array<AccountType>>;
};

export type NestedEnumActionStatusFilter = {
  equals?: InputMaybe<ActionStatus>;
  in?: InputMaybe<Array<ActionStatus>>;
  not?: InputMaybe<NestedEnumActionStatusFilter>;
  notIn?: InputMaybe<Array<ActionStatus>>;
};

export type NestedEnumAdminUserStatusFilter = {
  equals?: InputMaybe<AdminUserStatus>;
  in?: InputMaybe<Array<AdminUserStatus>>;
  not?: InputMaybe<NestedEnumAdminUserStatusFilter>;
  notIn?: InputMaybe<Array<AdminUserStatus>>;
};

export type NestedEnumAnnouncementStatusFilter = {
  equals?: InputMaybe<AnnouncementStatus>;
  in?: InputMaybe<Array<AnnouncementStatus>>;
  not?: InputMaybe<NestedEnumAnnouncementStatusFilter>;
  notIn?: InputMaybe<Array<AnnouncementStatus>>;
};

export type NestedEnumApprovalStatusFilter = {
  equals?: InputMaybe<ApprovalStatus>;
  in?: InputMaybe<Array<ApprovalStatus>>;
  not?: InputMaybe<NestedEnumApprovalStatusFilter>;
  notIn?: InputMaybe<Array<ApprovalStatus>>;
};

export type NestedEnumConsentStatusFilter = {
  equals?: InputMaybe<ConsentStatus>;
  in?: InputMaybe<Array<ConsentStatus>>;
  not?: InputMaybe<NestedEnumConsentStatusFilter>;
  notIn?: InputMaybe<Array<ConsentStatus>>;
};

export type NestedEnumConsentTypeFilter = {
  equals?: InputMaybe<ConsentType>;
  in?: InputMaybe<Array<ConsentType>>;
  not?: InputMaybe<NestedEnumConsentTypeFilter>;
  notIn?: InputMaybe<Array<ConsentType>>;
};

export type NestedEnumCreationStatusFilter = {
  equals?: InputMaybe<CreationStatus>;
  in?: InputMaybe<Array<CreationStatus>>;
  not?: InputMaybe<NestedEnumCreationStatusFilter>;
  notIn?: InputMaybe<Array<CreationStatus>>;
};

export type NestedEnumFundCategoriesFilter = {
  equals?: InputMaybe<FundCategories>;
  in?: InputMaybe<Array<FundCategories>>;
  not?: InputMaybe<NestedEnumFundCategoriesFilter>;
  notIn?: InputMaybe<Array<FundCategories>>;
};

export type NestedEnumFundRiskProfileFilter = {
  equals?: InputMaybe<FundRiskProfile>;
  in?: InputMaybe<Array<FundRiskProfile>>;
  not?: InputMaybe<NestedEnumFundRiskProfileFilter>;
  notIn?: InputMaybe<Array<FundRiskProfile>>;
};

export type NestedEnumIdTypeFilter = {
  equals?: InputMaybe<IdType>;
  in?: InputMaybe<Array<IdType>>;
  not?: InputMaybe<NestedEnumIdTypeFilter>;
  notIn?: InputMaybe<Array<IdType>>;
};

export type NestedEnumInterfaceFilter = {
  equals?: InputMaybe<Interface>;
  in?: InputMaybe<Array<Interface>>;
  not?: InputMaybe<NestedEnumInterfaceFilter>;
  notIn?: InputMaybe<Array<Interface>>;
};

export type NestedEnumModeOfContactNullableFilter = {
  equals?: InputMaybe<ModeOfContact>;
  in?: InputMaybe<Array<ModeOfContact>>;
  not?: InputMaybe<NestedEnumModeOfContactNullableFilter>;
  notIn?: InputMaybe<Array<ModeOfContact>>;
};

export type NestedEnumModificationEntityTypeFilter = {
  equals?: InputMaybe<ModificationEntityType>;
  in?: InputMaybe<Array<ModificationEntityType>>;
  not?: InputMaybe<NestedEnumModificationEntityTypeFilter>;
  notIn?: InputMaybe<Array<ModificationEntityType>>;
};

export type NestedEnumModificationStatusFilter = {
  equals?: InputMaybe<ModificationStatus>;
  in?: InputMaybe<Array<ModificationStatus>>;
  not?: InputMaybe<NestedEnumModificationStatusFilter>;
  notIn?: InputMaybe<Array<ModificationStatus>>;
};

export type NestedEnumModificationTypeFilter = {
  equals?: InputMaybe<ModificationType>;
  in?: InputMaybe<Array<ModificationType>>;
  not?: InputMaybe<NestedEnumModificationTypeFilter>;
  notIn?: InputMaybe<Array<ModificationType>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type NestedEnumSignatoryProviderFilter = {
  equals?: InputMaybe<SignatoryProvider>;
  in?: InputMaybe<Array<SignatoryProvider>>;
  not?: InputMaybe<NestedEnumSignatoryProviderFilter>;
  notIn?: InputMaybe<Array<SignatoryProvider>>;
};

export type NestedEnumSignatoryStatusFilter = {
  equals?: InputMaybe<SignatoryStatus>;
  in?: InputMaybe<Array<SignatoryStatus>>;
  not?: InputMaybe<NestedEnumSignatoryStatusFilter>;
  notIn?: InputMaybe<Array<SignatoryStatus>>;
};

export type NestedEnumStatementStatusFilter = {
  equals?: InputMaybe<StatementStatus>;
  in?: InputMaybe<Array<StatementStatus>>;
  not?: InputMaybe<NestedEnumStatementStatusFilter>;
  notIn?: InputMaybe<Array<StatementStatus>>;
};

export type NestedEnumStatementTypeFilter = {
  equals?: InputMaybe<StatementType>;
  in?: InputMaybe<Array<StatementType>>;
  not?: InputMaybe<NestedEnumStatementTypeFilter>;
  notIn?: InputMaybe<Array<StatementType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumTransactionStatusFilter = {
  equals?: InputMaybe<TransactionStatus>;
  in?: InputMaybe<Array<TransactionStatus>>;
  not?: InputMaybe<NestedEnumTransactionStatusFilter>;
  notIn?: InputMaybe<Array<TransactionStatus>>;
};

export type NestedEnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Order = {
  __typename?: 'Order';
  _count?: Maybe<OrderCount>;
  createdAt: Scalars['DateTime']['output'];
  fatcaSignedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  originId?: Maybe<Scalars['String']['output']>;
  paymentConfigs: Array<PaymentConfig>;
  payments: Array<Payment>;
  referenceNumber: Scalars['String']['output'];
  signatories: Array<Signatory>;
  status: OrderStatus;
  tocSignedAt?: Maybe<Scalars['DateTime']['output']>;
  transactions: Array<Transaction>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};


export type OrderPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type OrderSignatoriesArgs = {
  cursor?: InputMaybe<SignatoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SignatoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SignatoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SignatoryWhereInput>;
};


export type OrderTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type OrderCount = {
  __typename?: 'OrderCount';
  payments: Scalars['Int']['output'];
  signatories: Scalars['Int']['output'];
  transactions: Scalars['Int']['output'];
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  fatcaSignedAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  originId: Scalars['Int']['output'];
  referenceNumber: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  tocSignedAt: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type OrderCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<OrderUncheckedCreateWithoutTransactionsInput>;
};

export type OrderCreateOrConnectWithoutTransactionsInput = {
  create: OrderUncheckedCreateWithoutTransactionsInput;
  where: OrderWhereUniqueInput;
};

export type OrderInput = {
  originId?: InputMaybe<Scalars['String']['input']>;
  payments: Array<PaymentInput>;
  purchaseTransactions: Array<PurchaseTransactionInput>;
  signatories: Array<SignatoryInput>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fatcaSignedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  originId?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  tocSignedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fatcaSignedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  originId?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  tocSignedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type OrderNullableRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export type OrderOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  fatcaSignedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  originId?: InputMaybe<SortOrderInput>;
  payments?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  referenceNumber?: InputMaybe<SortOrder>;
  signatories?: InputMaybe<SignatoryOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  tocSignedAt?: InputMaybe<SortOrderInput>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  CreatedAt = 'createdAt',
  FatcaSignedAt = 'fatcaSignedAt',
  Id = 'id',
  OriginId = 'originId',
  ReferenceNumber = 'referenceNumber',
  Status = 'status',
  TocSignedAt = 'tocSignedAt',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export enum OrderStatus {
  Approved = 'Approved',
  Completed = 'Completed',
  Created = 'Created',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Voided = 'Voided'
}

export type OrderUncheckedCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fatcaSignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  originId?: InputMaybe<Scalars['String']['input']>;
  payments?: InputMaybe<PaymentUncheckedCreateNestedManyWithoutOrderInput>;
  referenceNumber: Scalars['String']['input'];
  signatories?: InputMaybe<SignatoryUncheckedCreateNestedManyWithoutOrderInput>;
  status?: InputMaybe<OrderStatus>;
  tocSignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  transactions?: InputMaybe<TransactionUncheckedCreateNestedManyWithoutOrderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type OrderUncheckedCreateWithoutTransactionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fatcaSignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  originId?: InputMaybe<Scalars['String']['input']>;
  payments?: InputMaybe<PaymentUncheckedCreateNestedManyWithoutOrderInput>;
  referenceNumber: Scalars['String']['input'];
  signatories?: InputMaybe<SignatoryUncheckedCreateNestedManyWithoutOrderInput>;
  status?: InputMaybe<OrderStatus>;
  tocSignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type OrderUncheckedUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fatcaSignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  originId?: InputMaybe<Scalars['String']['input']>;
  payments?: InputMaybe<PaymentUncheckedUpdateManyWithoutOrderNestedInput>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  signatories?: InputMaybe<SignatoryUncheckedUpdateManyWithoutOrderNestedInput>;
  status?: InputMaybe<OrderStatus>;
  tocSignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  transactions?: InputMaybe<TransactionUncheckedUpdateManyWithoutOrderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fatcaSignedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  originId?: InputMaybe<StringNullableFilter>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  signatories?: InputMaybe<SignatoryListRelationFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  tocSignedAt?: InputMaybe<DateTimeNullableFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type OrderWhereUniqueInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fatcaSignedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  originId?: InputMaybe<Scalars['String']['input']>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  signatories?: InputMaybe<SignatoryListRelationFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  tocSignedAt?: InputMaybe<DateTimeNullableFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type OtpChallenge = {
  __typename?: 'OtpChallenge';
  consumer: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiredOn: Scalars['DateTime']['output'];
  flow: OtpFlow;
  id: Scalars['ID']['output'];
  method: OtpMethod;
  purpose: OtpPurpose;
  retryWaitSeconds: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OtpChallengeAvgAggregate = {
  __typename?: 'OtpChallengeAvgAggregate';
  remainingAttempts?: Maybe<Scalars['Float']['output']>;
};

export type OtpChallengeCountAggregate = {
  __typename?: 'OtpChallengeCountAggregate';
  _all: Scalars['Int']['output'];
  clientIp: Scalars['Int']['output'];
  code: Scalars['Int']['output'];
  consumer: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  expiredOn: Scalars['Int']['output'];
  flow: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  method: Scalars['Int']['output'];
  nextRequestOn: Scalars['Int']['output'];
  purpose: Scalars['Int']['output'];
  remainingAttempts: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  usedAt: Scalars['Int']['output'];
};

export type OtpChallengeMaxAggregate = {
  __typename?: 'OtpChallengeMaxAggregate';
  clientIp?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  consumer?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiredOn?: Maybe<Scalars['DateTime']['output']>;
  flow?: Maybe<OtpFlow>;
  id?: Maybe<Scalars['String']['output']>;
  method?: Maybe<OtpMethod>;
  nextRequestOn?: Maybe<Scalars['DateTime']['output']>;
  purpose?: Maybe<OtpPurpose>;
  remainingAttempts?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OtpChallengeMinAggregate = {
  __typename?: 'OtpChallengeMinAggregate';
  clientIp?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  consumer?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiredOn?: Maybe<Scalars['DateTime']['output']>;
  flow?: Maybe<OtpFlow>;
  id?: Maybe<Scalars['String']['output']>;
  method?: Maybe<OtpMethod>;
  nextRequestOn?: Maybe<Scalars['DateTime']['output']>;
  purpose?: Maybe<OtpPurpose>;
  remainingAttempts?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OtpChallengeSumAggregate = {
  __typename?: 'OtpChallengeSumAggregate';
  remainingAttempts?: Maybe<Scalars['Int']['output']>;
};

export enum OtpFlow {
  Code = 'Code',
  Link = 'Link'
}

export enum OtpMethod {
  Email = 'Email',
  Sms = 'SMS'
}

export enum OtpPurpose {
  Login = 'Login',
  PasswordReset = 'PasswordReset',
  Registration = 'Registration'
}

export type PaginatationMetadata = {
  __typename?: 'PaginatationMetadata';
  currentPage: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaginatedAccount = {
  __typename?: 'PaginatedAccount';
  items?: Maybe<Array<Account>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAccountPortfolio = {
  __typename?: 'PaginatedAccountPortfolio';
  items?: Maybe<Array<AccountPortfolio>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAccountRequest = {
  __typename?: 'PaginatedAccountRequest';
  items?: Maybe<Array<AccountRequest>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminPermission = {
  __typename?: 'PaginatedAdminPermission';
  items?: Maybe<Array<AdminPermission>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminRole = {
  __typename?: 'PaginatedAdminRole';
  items?: Maybe<Array<AdminRole>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminUser = {
  __typename?: 'PaginatedAdminUser';
  items?: Maybe<Array<AdminUser>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAnnouncement = {
  __typename?: 'PaginatedAnnouncement';
  items?: Maybe<Array<Announcement>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAuditTrail = {
  __typename?: 'PaginatedAuditTrail';
  items?: Maybe<Array<AuditTrail>>;
  metadata: PaginatationMetadata;
};

export type PaginatedDocument = {
  __typename?: 'PaginatedDocument';
  items?: Maybe<Array<Document>>;
  metadata: PaginatationMetadata;
};

export type PaginatedFund = {
  __typename?: 'PaginatedFund';
  items?: Maybe<Array<Fund>>;
  metadata: PaginatationMetadata;
};

export type PaginatedFundDocument = {
  __typename?: 'PaginatedFundDocument';
  items?: Maybe<Array<FundDocument>>;
  metadata: PaginatationMetadata;
};

export type PaginatedFundHoliday = {
  __typename?: 'PaginatedFundHoliday';
  items?: Maybe<Array<FundHoliday>>;
  metadata: PaginatationMetadata;
};

export type PaginatedFundPolicy = {
  __typename?: 'PaginatedFundPolicy';
  items?: Maybe<Array<FundPolicy>>;
  metadata: PaginatationMetadata;
};

export type PaginatedFundPrice = {
  __typename?: 'PaginatedFundPrice';
  items?: Maybe<Array<FundPrice>>;
  metadata: PaginatationMetadata;
};

export type PaginatedModificationModel = {
  __typename?: 'PaginatedModificationModel';
  items?: Maybe<Array<ModificationModel>>;
  metadata: PaginatationMetadata;
};

export type PaginatedOrder = {
  __typename?: 'PaginatedOrder';
  items?: Maybe<Array<Order>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPayment = {
  __typename?: 'PaginatedPayment';
  items?: Maybe<Array<Payment>>;
  metadata: PaginatationMetadata;
};

export type PaginatedProfile = {
  __typename?: 'PaginatedProfile';
  items?: Maybe<Array<Profile>>;
  metadata: PaginatationMetadata;
};

export type PaginatedProfileConsent = {
  __typename?: 'PaginatedProfileConsent';
  items?: Maybe<Array<ProfileConsent>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPublicHoliday = {
  __typename?: 'PaginatedPublicHoliday';
  items?: Maybe<Array<PublicHoliday>>;
  metadata: PaginatationMetadata;
};

export type PaginatedSignatory = {
  __typename?: 'PaginatedSignatory';
  items?: Maybe<Array<Signatory>>;
  metadata: PaginatationMetadata;
};

export type PaginatedStatement = {
  __typename?: 'PaginatedStatement';
  items?: Maybe<Array<Statement>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  items?: Maybe<Array<Transaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  items?: Maybe<Array<User>>;
  metadata: PaginatationMetadata;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  externalReferenceNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order: Order;
  orderId: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  paymentProof?: Maybe<Scalars['String']['output']>;
  paymentProofId?: Maybe<Scalars['String']['output']>;
  referenceNumber: Scalars['String']['output'];
  status: PaymentStatus;
  transactedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentAvgAggregate = {
  __typename?: 'PaymentAvgAggregate';
  amount?: Maybe<Scalars['Decimal']['output']>;
};

export type PaymentConfig = {
  __typename?: 'PaymentConfig';
  amount: Scalars['Float']['output'];
  metadata: PaymentConfigMetadata;
  paymentProvider: Scalars['String']['output'];
};

export type PaymentConfigMetadata = {
  __typename?: 'PaymentConfigMetadata';
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  beneficiaryName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  fundName: Scalars['String']['output'];
};

export type PaymentCountAggregate = {
  __typename?: 'PaymentCountAggregate';
  _all: Scalars['Int']['output'];
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  currency: Scalars['Int']['output'];
  externalReferenceNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  orderId: Scalars['Int']['output'];
  paymentMethod: Scalars['Int']['output'];
  paymentProofId: Scalars['Int']['output'];
  referenceNumber: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  transactedAt: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PaymentCreateManyOrderInput = {
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency: Scalars['String']['input'];
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: PaymentMethod;
  paymentProofId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  status?: InputMaybe<PaymentStatus>;
  transactedAt: Scalars['DateTime']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentCreateManyOrderInputEnvelope = {
  data: Array<PaymentCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentCreateOrConnectWithoutOrderInput = {
  create: PaymentUncheckedCreateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateWithoutOrderInput = {
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency: Scalars['String']['input'];
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: PaymentMethod;
  paymentProofId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  status?: InputMaybe<PaymentStatus>;
  transactedAt: Scalars['DateTime']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentInput = {
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  paymentProof?: InputMaybe<CreateOneDocumentInput>;
  transactionDate: Scalars['DateTime']['input'];
};

export type PaymentListRelationFilter = {
  every?: InputMaybe<PaymentWhereInput>;
  none?: InputMaybe<PaymentWhereInput>;
  some?: InputMaybe<PaymentWhereInput>;
};

export type PaymentMaxAggregate = {
  __typename?: 'PaymentMaxAggregate';
  amount?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  externalReferenceNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentProofId?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentStatus>;
  transactedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum PaymentMethod {
  BankCheque = 'BankCheque',
  Fpx = 'FPX'
}

export type PaymentMinAggregate = {
  __typename?: 'PaymentMinAggregate';
  amount?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  externalReferenceNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentProofId?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentStatus>;
  transactedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PaymentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  externalReferenceNumber?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  paymentProofId?: InputMaybe<SortOrderInput>;
  referenceNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transactedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PaymentScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  ExternalReferenceNumber = 'externalReferenceNumber',
  Id = 'id',
  OrderId = 'orderId',
  PaymentMethod = 'paymentMethod',
  PaymentProofId = 'paymentProofId',
  ReferenceNumber = 'referenceNumber',
  Status = 'status',
  TransactedAt = 'transactedAt',
  UpdatedAt = 'updatedAt'
}

export type PaymentScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentScalarWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  externalReferenceNumber?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentProofId?: InputMaybe<StringNullableFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  transactedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum PaymentStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type PaymentSumAggregate = {
  __typename?: 'PaymentSumAggregate';
  amount?: Maybe<Scalars['Decimal']['output']>;
};

export type PaymentUncheckedCreateInput = {
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency: Scalars['String']['input'];
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  paymentProof?: InputMaybe<CreateOneDocumentInput>;
  paymentProofId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  status?: InputMaybe<PaymentStatus>;
  transactedAt: Scalars['DateTime']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentUncheckedCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutOrderInput>>;
  createMany?: InputMaybe<PaymentCreateManyOrderInputEnvelope>;
};

export type PaymentUncheckedCreateWithoutOrderInput = {
  amount: Scalars['Decimal']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency: Scalars['String']['input'];
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: PaymentMethod;
  paymentProofId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  status?: InputMaybe<PaymentStatus>;
  transactedAt: Scalars['DateTime']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentUncheckedUpdateInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentProof?: InputMaybe<CreateOneDocumentInput>;
  paymentProofId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PaymentStatus>;
  transactedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentUncheckedUpdateManyWithoutOrderInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentProofId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PaymentStatus>;
  transactedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentUncheckedUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<PaymentCreateWithoutOrderInput>>;
  createMany?: InputMaybe<PaymentCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<PaymentUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<PaymentUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type PaymentUncheckedUpdateWithoutOrderInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentProofId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PaymentStatus>;
  transactedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentUpdateManyWithWhereWithoutOrderInput = {
  data: PaymentUncheckedUpdateManyWithoutOrderInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateWithWhereUniqueWithoutOrderInput = {
  data: PaymentUncheckedUpdateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutOrderInput = {
  create: PaymentUncheckedCreateWithoutOrderInput;
  update: PaymentUncheckedUpdateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentWhereInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  externalReferenceNumber?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentProofId?: InputMaybe<StringNullableFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  transactedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  externalReferenceNumber?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentProofId?: InputMaybe<StringNullableFilter>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  transactedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PermissionGrant = {
  __typename?: 'PermissionGrant';
  action: Scalars['String']['output'];
  code: Scalars['String']['output'];
  resource: Scalars['String']['output'];
};

export type ProcessFlow = {
  __typename?: 'ProcessFlow';
  label: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  _count?: Maybe<ProfileCount>;
  accounts: Array<Account>;
  amlSanctionScreeningPoint?: Maybe<Scalars['Int']['output']>;
  annualIncome?: Maybe<Scalars['Decimal']['output']>;
  bankAccounts: Array<BankAccount>;
  bumiputera?: Maybe<Scalars['Boolean']['output']>;
  city: Scalars['String']['output'];
  cityOfBirth?: Maybe<Scalars['String']['output']>;
  companyAddress?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  declaration: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  employmentStatus?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<Scalars['String']['output']>;
  estimatedNetWorth?: Maybe<Scalars['Decimal']['output']>;
  expectedInvestmentAmount?: Maybe<Scalars['Decimal']['output']>;
  frequencyOfTransaction?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  highNetWorth?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  idExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  idNumber: Scalars['String']['output'];
  idType: IdType;
  issuedConsents: Array<ProfileConsent>;
  jobIndustry?: Maybe<Scalars['String']['output']>;
  mailingAddress: Scalars['String']['output'];
  maritalStatus?: Maybe<Scalars['String']['output']>;
  marketingPush: Scalars['Boolean']['output'];
  monthlyIncome?: Maybe<Scalars['Decimal']['output']>;
  mothersMaidenName?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  natureOfBusiness?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  officePhoneNo?: Maybe<Scalars['String']['output']>;
  permanentAddress: Scalars['String']['output'];
  phoneNo: Scalars['String']['output'];
  politicalExposedPerson?: Maybe<Scalars['Boolean']['output']>;
  postcode: Scalars['String']['output'];
  profession?: Maybe<Scalars['String']['output']>;
  prominentRole?: Maybe<Scalars['Boolean']['output']>;
  purposeOfInvestment?: Maybe<Scalars['String']['output']>;
  race?: Maybe<Scalars['String']['output']>;
  receivedConsents: Array<ProfileConsent>;
  religion?: Maybe<Scalars['String']['output']>;
  safRating?: Maybe<Scalars['Int']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  sourceOfFund?: Maybe<Scalars['String']['output']>;
  sourceOfWealth?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  taxResidency?: Maybe<Scalars['String']['output']>;
  type: AccountType;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<User>;
};


export type ProfileAccountsArgs = {
  cursor?: InputMaybe<AccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountWhereInput>;
};


export type ProfileBankAccountsArgs = {
  cursor?: InputMaybe<BankAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<BankAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BankAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankAccountWhereInput>;
};


export type ProfileIssuedConsentsArgs = {
  cursor?: InputMaybe<ProfileConsentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfileConsentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfileConsentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileConsentWhereInput>;
};


export type ProfileReceivedConsentsArgs = {
  cursor?: InputMaybe<ProfileConsentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfileConsentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfileConsentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileConsentWhereInput>;
};


export type ProfileUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type ProfileAvgAggregate = {
  __typename?: 'ProfileAvgAggregate';
  amlSanctionScreeningPoint?: Maybe<Scalars['Float']['output']>;
  annualIncome?: Maybe<Scalars['Decimal']['output']>;
  estimatedNetWorth?: Maybe<Scalars['Decimal']['output']>;
  expectedInvestmentAmount?: Maybe<Scalars['Decimal']['output']>;
  monthlyIncome?: Maybe<Scalars['Decimal']['output']>;
  safRating?: Maybe<Scalars['Float']['output']>;
};

export type ProfileConsent = {
  __typename?: 'ProfileConsent';
  consentType: ConsentType;
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  issuerProfile?: Maybe<Profile>;
  issuerProfileId?: Maybe<Scalars['String']['output']>;
  recipientProfile?: Maybe<Profile>;
  recipientProfileId?: Maybe<Scalars['String']['output']>;
  status: ConsentStatus;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProfileConsentIssuerProfileArgs = {
  where?: InputMaybe<ProfileWhereInput>;
};


export type ProfileConsentRecipientProfileArgs = {
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileConsentCountAggregate = {
  __typename?: 'ProfileConsentCountAggregate';
  _all: Scalars['Int']['output'];
  consentType: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  issuerProfileId: Scalars['Int']['output'];
  recipientProfileId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ProfileConsentCreateManyIssuerProfileInput = {
  consentType: ConsentType;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  recipientProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentCreateManyIssuerProfileInputEnvelope = {
  data: Array<ProfileConsentCreateManyIssuerProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfileConsentCreateManyRecipientProfileInput = {
  consentType: ConsentType;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  issuerProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentCreateManyRecipientProfileInputEnvelope = {
  data: Array<ProfileConsentCreateManyRecipientProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfileConsentCreateNestedManyWithoutIssuerProfileInput = {
  connect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileConsentCreateOrConnectWithoutIssuerProfileInput>>;
  create?: InputMaybe<Array<ProfileConsentCreateWithoutIssuerProfileInput>>;
  createMany?: InputMaybe<ProfileConsentCreateManyIssuerProfileInputEnvelope>;
};

export type ProfileConsentCreateNestedManyWithoutRecipientProfileInput = {
  connect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileConsentCreateOrConnectWithoutRecipientProfileInput>>;
  create?: InputMaybe<Array<ProfileConsentCreateWithoutRecipientProfileInput>>;
  createMany?: InputMaybe<ProfileConsentCreateManyRecipientProfileInputEnvelope>;
};

export type ProfileConsentCreateOrConnectWithoutIssuerProfileInput = {
  create: ProfileConsentUncheckedCreateWithoutIssuerProfileInput;
  where: ProfileConsentWhereUniqueInput;
};

export type ProfileConsentCreateOrConnectWithoutRecipientProfileInput = {
  create: ProfileConsentUncheckedCreateWithoutRecipientProfileInput;
  where: ProfileConsentWhereUniqueInput;
};

export type ProfileConsentCreateWithoutIssuerProfileInput = {
  consentType: ConsentType;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  recipientProfile?: InputMaybe<ProfileCreateNestedOneWithoutReceivedConsentsInput>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentCreateWithoutRecipientProfileInput = {
  consentType: ConsentType;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  issuerProfile?: InputMaybe<ProfileCreateNestedOneWithoutIssuedConsentsInput>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentListRelationFilter = {
  every?: InputMaybe<ProfileConsentWhereInput>;
  none?: InputMaybe<ProfileConsentWhereInput>;
  some?: InputMaybe<ProfileConsentWhereInput>;
};

export type ProfileConsentMaxAggregate = {
  __typename?: 'ProfileConsentMaxAggregate';
  consentType?: Maybe<ConsentType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  issuerProfileId?: Maybe<Scalars['String']['output']>;
  recipientProfileId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ConsentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProfileConsentMinAggregate = {
  __typename?: 'ProfileConsentMinAggregate';
  consentType?: Maybe<ConsentType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  issuerProfileId?: Maybe<Scalars['String']['output']>;
  recipientProfileId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ConsentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProfileConsentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProfileConsentOrderByWithRelationInput = {
  consentType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  issuerProfile?: InputMaybe<ProfileOrderByWithRelationInput>;
  issuerProfileId?: InputMaybe<SortOrderInput>;
  recipientProfile?: InputMaybe<ProfileOrderByWithRelationInput>;
  recipientProfileId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ProfileConsentScalarFieldEnum {
  ConsentType = 'consentType',
  CreatedAt = 'createdAt',
  CreationStatus = 'creationStatus',
  DeletedAt = 'deletedAt',
  Id = 'id',
  IssuerProfileId = 'issuerProfileId',
  RecipientProfileId = 'recipientProfileId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ProfileConsentScalarWhereInput = {
  AND?: InputMaybe<Array<ProfileConsentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProfileConsentScalarWhereInput>>;
  OR?: InputMaybe<Array<ProfileConsentScalarWhereInput>>;
  consentType?: InputMaybe<EnumConsentTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  id?: InputMaybe<StringFilter>;
  issuerProfileId?: InputMaybe<StringNullableFilter>;
  recipientProfileId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumConsentStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProfileConsentUncheckedCreateInput = {
  consentType: ConsentType;
  issuerId: Scalars['String']['input'];
  recipientId: Scalars['String']['input'];
};

export type ProfileConsentUncheckedCreateNestedManyWithoutIssuerProfileInput = {
  connect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileConsentCreateOrConnectWithoutIssuerProfileInput>>;
  create?: InputMaybe<Array<ProfileConsentCreateWithoutIssuerProfileInput>>;
  createMany?: InputMaybe<ProfileConsentCreateManyIssuerProfileInputEnvelope>;
};

export type ProfileConsentUncheckedCreateNestedManyWithoutRecipientProfileInput = {
  connect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileConsentCreateOrConnectWithoutRecipientProfileInput>>;
  create?: InputMaybe<Array<ProfileConsentCreateWithoutRecipientProfileInput>>;
  createMany?: InputMaybe<ProfileConsentCreateManyRecipientProfileInputEnvelope>;
};

export type ProfileConsentUncheckedCreateWithoutIssuerProfileInput = {
  consentType: ConsentType;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  recipientProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentUncheckedCreateWithoutRecipientProfileInput = {
  consentType: ConsentType;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  issuerProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentUncheckedUpdateInput = {
  consentType?: InputMaybe<ConsentType>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  issuerProfileId?: InputMaybe<Scalars['String']['input']>;
  recipientProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentUncheckedUpdateManyWithoutIssuerProfileInput = {
  consentType?: InputMaybe<ConsentType>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  recipientProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentUncheckedUpdateManyWithoutIssuerProfileNestedInput = {
  connect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileConsentCreateOrConnectWithoutIssuerProfileInput>>;
  create?: InputMaybe<Array<ProfileConsentCreateWithoutIssuerProfileInput>>;
  createMany?: InputMaybe<ProfileConsentCreateManyIssuerProfileInputEnvelope>;
  delete?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfileConsentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfileConsentUpdateWithWhereUniqueWithoutIssuerProfileInput>>;
  updateMany?: InputMaybe<Array<ProfileConsentUpdateManyWithWhereWithoutIssuerProfileInput>>;
  upsert?: InputMaybe<Array<ProfileConsentUpsertWithWhereUniqueWithoutIssuerProfileInput>>;
};

export type ProfileConsentUncheckedUpdateManyWithoutRecipientProfileInput = {
  consentType?: InputMaybe<ConsentType>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  issuerProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentUncheckedUpdateManyWithoutRecipientProfileNestedInput = {
  connect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileConsentCreateOrConnectWithoutRecipientProfileInput>>;
  create?: InputMaybe<Array<ProfileConsentCreateWithoutRecipientProfileInput>>;
  createMany?: InputMaybe<ProfileConsentCreateManyRecipientProfileInputEnvelope>;
  delete?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfileConsentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfileConsentWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfileConsentUpdateWithWhereUniqueWithoutRecipientProfileInput>>;
  updateMany?: InputMaybe<Array<ProfileConsentUpdateManyWithWhereWithoutRecipientProfileInput>>;
  upsert?: InputMaybe<Array<ProfileConsentUpsertWithWhereUniqueWithoutRecipientProfileInput>>;
};

export type ProfileConsentUncheckedUpdateWithoutIssuerProfileInput = {
  consentType?: InputMaybe<ConsentType>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  recipientProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentUncheckedUpdateWithoutRecipientProfileInput = {
  consentType?: InputMaybe<ConsentType>;
  creationStatus?: InputMaybe<CreationStatus>;
  id?: InputMaybe<Scalars['String']['input']>;
  issuerProfileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConsentStatus>;
};

export type ProfileConsentUpdateManyWithWhereWithoutIssuerProfileInput = {
  data: ProfileConsentUncheckedUpdateManyWithoutIssuerProfileInput;
  where: ProfileConsentScalarWhereInput;
};

export type ProfileConsentUpdateManyWithWhereWithoutRecipientProfileInput = {
  data: ProfileConsentUncheckedUpdateManyWithoutRecipientProfileInput;
  where: ProfileConsentScalarWhereInput;
};

export type ProfileConsentUpdateWithWhereUniqueWithoutIssuerProfileInput = {
  data: ProfileConsentUncheckedUpdateWithoutIssuerProfileInput;
  where: ProfileConsentWhereUniqueInput;
};

export type ProfileConsentUpdateWithWhereUniqueWithoutRecipientProfileInput = {
  data: ProfileConsentUncheckedUpdateWithoutRecipientProfileInput;
  where: ProfileConsentWhereUniqueInput;
};

export type ProfileConsentUpsertWithWhereUniqueWithoutIssuerProfileInput = {
  create: ProfileConsentUncheckedCreateWithoutIssuerProfileInput;
  update: ProfileConsentUncheckedUpdateWithoutIssuerProfileInput;
  where: ProfileConsentWhereUniqueInput;
};

export type ProfileConsentUpsertWithWhereUniqueWithoutRecipientProfileInput = {
  create: ProfileConsentUncheckedCreateWithoutRecipientProfileInput;
  update: ProfileConsentUncheckedUpdateWithoutRecipientProfileInput;
  where: ProfileConsentWhereUniqueInput;
};

export type ProfileConsentWhereInput = {
  AND?: InputMaybe<Array<ProfileConsentWhereInput>>;
  NOT?: InputMaybe<Array<ProfileConsentWhereInput>>;
  OR?: InputMaybe<Array<ProfileConsentWhereInput>>;
  consentType?: InputMaybe<EnumConsentTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  id?: InputMaybe<StringFilter>;
  issuerProfile?: InputMaybe<ProfileNullableRelationFilter>;
  issuerProfileId?: InputMaybe<StringNullableFilter>;
  recipientProfile?: InputMaybe<ProfileNullableRelationFilter>;
  recipientProfileId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumConsentStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProfileConsentWhereUniqueInput = {
  AND?: InputMaybe<Array<ProfileConsentWhereInput>>;
  NOT?: InputMaybe<Array<ProfileConsentWhereInput>>;
  OR?: InputMaybe<Array<ProfileConsentWhereInput>>;
  consentType?: InputMaybe<EnumConsentTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  issuerProfile?: InputMaybe<ProfileNullableRelationFilter>;
  issuerProfileId?: InputMaybe<StringNullableFilter>;
  recipientProfile?: InputMaybe<ProfileNullableRelationFilter>;
  recipientProfileId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumConsentStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProfileCount = {
  __typename?: 'ProfileCount';
  accounts: Scalars['Int']['output'];
  bankAccounts: Scalars['Int']['output'];
  issuedConsents: Scalars['Int']['output'];
  receivedConsents: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};

export type ProfileCountAggregate = {
  __typename?: 'ProfileCountAggregate';
  _all: Scalars['Int']['output'];
  amlSanctionScreeningPoint: Scalars['Int']['output'];
  annualIncome: Scalars['Int']['output'];
  bumiputera: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  cityOfBirth: Scalars['Int']['output'];
  companyAddress: Scalars['Int']['output'];
  companyName: Scalars['Int']['output'];
  countryOfBirth: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  dateOfBirth: Scalars['Int']['output'];
  declaration: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  employmentStatus: Scalars['Int']['output'];
  employmentType: Scalars['Int']['output'];
  estimatedNetWorth: Scalars['Int']['output'];
  expectedInvestmentAmount: Scalars['Int']['output'];
  frequencyOfTransaction: Scalars['Int']['output'];
  fullName: Scalars['Int']['output'];
  gender: Scalars['Int']['output'];
  highNetWorth: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  idExpiryDate: Scalars['Int']['output'];
  idNumber: Scalars['Int']['output'];
  idType: Scalars['Int']['output'];
  jobIndustry: Scalars['Int']['output'];
  mailingAddress: Scalars['Int']['output'];
  maritalStatus: Scalars['Int']['output'];
  marketingPush: Scalars['Int']['output'];
  monthlyIncome: Scalars['Int']['output'];
  mothersMaidenName: Scalars['Int']['output'];
  nationality: Scalars['Int']['output'];
  natureOfBusiness: Scalars['Int']['output'];
  occupation: Scalars['Int']['output'];
  officePhoneNo: Scalars['Int']['output'];
  permanentAddress: Scalars['Int']['output'];
  phoneNo: Scalars['Int']['output'];
  politicalExposedPerson: Scalars['Int']['output'];
  postcode: Scalars['Int']['output'];
  profession: Scalars['Int']['output'];
  prominentRole: Scalars['Int']['output'];
  purposeOfInvestment: Scalars['Int']['output'];
  race: Scalars['Int']['output'];
  religion: Scalars['Int']['output'];
  safRating: Scalars['Int']['output'];
  salutation: Scalars['Int']['output'];
  sourceOfFund: Scalars['Int']['output'];
  sourceOfWealth: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  taxResidency: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ProfileCreateNestedOneWithoutIssuedConsentsInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutIssuedConsentsInput>;
  create?: InputMaybe<ProfileUncheckedCreateWithoutIssuedConsentsInput>;
};

export type ProfileCreateNestedOneWithoutReceivedConsentsInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutReceivedConsentsInput>;
  create?: InputMaybe<ProfileUncheckedCreateWithoutReceivedConsentsInput>;
};

export type ProfileCreateOrConnectWithoutIssuedConsentsInput = {
  create: ProfileUncheckedCreateWithoutIssuedConsentsInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutReceivedConsentsInput = {
  create: ProfileUncheckedCreateWithoutReceivedConsentsInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutUsersInput = {
  create: ProfileUncheckedCreateWithoutUsersInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateWithoutUsersInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bankAccounts?: InputMaybe<BankAccountCreateNestedManyWithoutProfileInput>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city: Scalars['String']['input'];
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber: Scalars['String']['input'];
  idType: IdType;
  issuedConsents?: InputMaybe<ProfileConsentCreateNestedManyWithoutIssuerProfileInput>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress: Scalars['String']['input'];
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  receivedConsents?: InputMaybe<ProfileConsentCreateNestedManyWithoutRecipientProfileInput>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type: AccountType;
};

export type ProfileListRelationFilter = {
  every?: InputMaybe<ProfileWhereInput>;
  none?: InputMaybe<ProfileWhereInput>;
  some?: InputMaybe<ProfileWhereInput>;
};

export type ProfileMaxAggregate = {
  __typename?: 'ProfileMaxAggregate';
  amlSanctionScreeningPoint?: Maybe<Scalars['Int']['output']>;
  annualIncome?: Maybe<Scalars['Decimal']['output']>;
  bumiputera?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityOfBirth?: Maybe<Scalars['String']['output']>;
  companyAddress?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  declaration?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<Scalars['String']['output']>;
  estimatedNetWorth?: Maybe<Scalars['Decimal']['output']>;
  expectedInvestmentAmount?: Maybe<Scalars['Decimal']['output']>;
  frequencyOfTransaction?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  highNetWorth?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idType?: Maybe<IdType>;
  jobIndustry?: Maybe<Scalars['String']['output']>;
  mailingAddress?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  marketingPush?: Maybe<Scalars['Boolean']['output']>;
  monthlyIncome?: Maybe<Scalars['Decimal']['output']>;
  mothersMaidenName?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  natureOfBusiness?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  officePhoneNo?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<Scalars['String']['output']>;
  phoneNo?: Maybe<Scalars['String']['output']>;
  politicalExposedPerson?: Maybe<Scalars['Boolean']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  profession?: Maybe<Scalars['String']['output']>;
  prominentRole?: Maybe<Scalars['Boolean']['output']>;
  purposeOfInvestment?: Maybe<Scalars['String']['output']>;
  race?: Maybe<Scalars['String']['output']>;
  religion?: Maybe<Scalars['String']['output']>;
  safRating?: Maybe<Scalars['Int']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  sourceOfFund?: Maybe<Scalars['String']['output']>;
  sourceOfWealth?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  taxResidency?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AccountType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProfileMinAggregate = {
  __typename?: 'ProfileMinAggregate';
  amlSanctionScreeningPoint?: Maybe<Scalars['Int']['output']>;
  annualIncome?: Maybe<Scalars['Decimal']['output']>;
  bumiputera?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityOfBirth?: Maybe<Scalars['String']['output']>;
  companyAddress?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  declaration?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<Scalars['String']['output']>;
  estimatedNetWorth?: Maybe<Scalars['Decimal']['output']>;
  expectedInvestmentAmount?: Maybe<Scalars['Decimal']['output']>;
  frequencyOfTransaction?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  highNetWorth?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idType?: Maybe<IdType>;
  jobIndustry?: Maybe<Scalars['String']['output']>;
  mailingAddress?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  marketingPush?: Maybe<Scalars['Boolean']['output']>;
  monthlyIncome?: Maybe<Scalars['Decimal']['output']>;
  mothersMaidenName?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  natureOfBusiness?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  officePhoneNo?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<Scalars['String']['output']>;
  phoneNo?: Maybe<Scalars['String']['output']>;
  politicalExposedPerson?: Maybe<Scalars['Boolean']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  profession?: Maybe<Scalars['String']['output']>;
  prominentRole?: Maybe<Scalars['Boolean']['output']>;
  purposeOfInvestment?: Maybe<Scalars['String']['output']>;
  race?: Maybe<Scalars['String']['output']>;
  religion?: Maybe<Scalars['String']['output']>;
  safRating?: Maybe<Scalars['Int']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  sourceOfFund?: Maybe<Scalars['String']['output']>;
  sourceOfWealth?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  taxResidency?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AccountType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProfileNullableRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export type ProfileOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProfileOrderByWithRelationInput = {
  accounts?: InputMaybe<AccountOrderByRelationAggregateInput>;
  amlSanctionScreeningPoint?: InputMaybe<SortOrderInput>;
  annualIncome?: InputMaybe<SortOrderInput>;
  bankAccounts?: InputMaybe<BankAccountOrderByRelationAggregateInput>;
  bumiputera?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrder>;
  cityOfBirth?: InputMaybe<SortOrderInput>;
  companyAddress?: InputMaybe<SortOrderInput>;
  companyName?: InputMaybe<SortOrderInput>;
  countryOfBirth?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  declaration?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  employmentStatus?: InputMaybe<SortOrderInput>;
  employmentType?: InputMaybe<SortOrderInput>;
  estimatedNetWorth?: InputMaybe<SortOrderInput>;
  expectedInvestmentAmount?: InputMaybe<SortOrderInput>;
  frequencyOfTransaction?: InputMaybe<SortOrderInput>;
  fullName?: InputMaybe<SortOrderInput>;
  gender?: InputMaybe<SortOrderInput>;
  highNetWorth?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  idExpiryDate?: InputMaybe<SortOrderInput>;
  idNumber?: InputMaybe<SortOrder>;
  idType?: InputMaybe<SortOrder>;
  issuedConsents?: InputMaybe<ProfileConsentOrderByRelationAggregateInput>;
  jobIndustry?: InputMaybe<SortOrderInput>;
  mailingAddress?: InputMaybe<SortOrder>;
  maritalStatus?: InputMaybe<SortOrderInput>;
  marketingPush?: InputMaybe<SortOrder>;
  monthlyIncome?: InputMaybe<SortOrderInput>;
  mothersMaidenName?: InputMaybe<SortOrderInput>;
  nationality?: InputMaybe<SortOrderInput>;
  natureOfBusiness?: InputMaybe<SortOrderInput>;
  occupation?: InputMaybe<SortOrderInput>;
  officePhoneNo?: InputMaybe<SortOrderInput>;
  permanentAddress?: InputMaybe<SortOrder>;
  phoneNo?: InputMaybe<SortOrder>;
  politicalExposedPerson?: InputMaybe<SortOrderInput>;
  postcode?: InputMaybe<SortOrder>;
  profession?: InputMaybe<SortOrderInput>;
  prominentRole?: InputMaybe<SortOrderInput>;
  purposeOfInvestment?: InputMaybe<SortOrderInput>;
  race?: InputMaybe<SortOrderInput>;
  receivedConsents?: InputMaybe<ProfileConsentOrderByRelationAggregateInput>;
  religion?: InputMaybe<SortOrderInput>;
  safRating?: InputMaybe<SortOrderInput>;
  salutation?: InputMaybe<SortOrderInput>;
  sourceOfFund?: InputMaybe<SortOrderInput>;
  sourceOfWealth?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  taxResidency?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type ProfileRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export enum ProfileScalarFieldEnum {
  AmlSanctionScreeningPoint = 'amlSanctionScreeningPoint',
  AnnualIncome = 'annualIncome',
  Bumiputera = 'bumiputera',
  City = 'city',
  CityOfBirth = 'cityOfBirth',
  CompanyAddress = 'companyAddress',
  CompanyName = 'companyName',
  CountryOfBirth = 'countryOfBirth',
  CreatedAt = 'createdAt',
  CreationStatus = 'creationStatus',
  DateOfBirth = 'dateOfBirth',
  Declaration = 'declaration',
  DeletedAt = 'deletedAt',
  Email = 'email',
  EmploymentStatus = 'employmentStatus',
  EmploymentType = 'employmentType',
  EstimatedNetWorth = 'estimatedNetWorth',
  ExpectedInvestmentAmount = 'expectedInvestmentAmount',
  FrequencyOfTransaction = 'frequencyOfTransaction',
  FullName = 'fullName',
  Gender = 'gender',
  HighNetWorth = 'highNetWorth',
  Id = 'id',
  IdExpiryDate = 'idExpiryDate',
  IdNumber = 'idNumber',
  IdType = 'idType',
  JobIndustry = 'jobIndustry',
  MailingAddress = 'mailingAddress',
  MaritalStatus = 'maritalStatus',
  MarketingPush = 'marketingPush',
  MonthlyIncome = 'monthlyIncome',
  MothersMaidenName = 'mothersMaidenName',
  Nationality = 'nationality',
  NatureOfBusiness = 'natureOfBusiness',
  Occupation = 'occupation',
  OfficePhoneNo = 'officePhoneNo',
  PermanentAddress = 'permanentAddress',
  PhoneNo = 'phoneNo',
  PoliticalExposedPerson = 'politicalExposedPerson',
  Postcode = 'postcode',
  Profession = 'profession',
  ProminentRole = 'prominentRole',
  PurposeOfInvestment = 'purposeOfInvestment',
  Race = 'race',
  Religion = 'religion',
  SafRating = 'safRating',
  Salutation = 'salutation',
  SourceOfFund = 'sourceOfFund',
  SourceOfWealth = 'sourceOfWealth',
  State = 'state',
  TaxResidency = 'taxResidency',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ProfileScalarWhereInput = {
  AND?: InputMaybe<Array<ProfileScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProfileScalarWhereInput>>;
  OR?: InputMaybe<Array<ProfileScalarWhereInput>>;
  amlSanctionScreeningPoint?: InputMaybe<IntNullableFilter>;
  annualIncome?: InputMaybe<DecimalNullableFilter>;
  bumiputera?: InputMaybe<BoolNullableFilter>;
  city?: InputMaybe<StringFilter>;
  cityOfBirth?: InputMaybe<StringNullableFilter>;
  companyAddress?: InputMaybe<StringNullableFilter>;
  companyName?: InputMaybe<StringNullableFilter>;
  countryOfBirth?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  declaration?: InputMaybe<BoolFilter>;
  email?: InputMaybe<StringFilter>;
  employmentStatus?: InputMaybe<StringNullableFilter>;
  employmentType?: InputMaybe<StringNullableFilter>;
  estimatedNetWorth?: InputMaybe<DecimalNullableFilter>;
  expectedInvestmentAmount?: InputMaybe<DecimalNullableFilter>;
  frequencyOfTransaction?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  highNetWorth?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  idExpiryDate?: InputMaybe<DateTimeNullableFilter>;
  idNumber?: InputMaybe<StringFilter>;
  idType?: InputMaybe<EnumIdTypeFilter>;
  jobIndustry?: InputMaybe<StringNullableFilter>;
  mailingAddress?: InputMaybe<StringFilter>;
  maritalStatus?: InputMaybe<StringNullableFilter>;
  marketingPush?: InputMaybe<BoolFilter>;
  monthlyIncome?: InputMaybe<DecimalNullableFilter>;
  mothersMaidenName?: InputMaybe<StringNullableFilter>;
  nationality?: InputMaybe<StringNullableFilter>;
  natureOfBusiness?: InputMaybe<StringNullableFilter>;
  occupation?: InputMaybe<StringNullableFilter>;
  officePhoneNo?: InputMaybe<StringNullableFilter>;
  permanentAddress?: InputMaybe<StringFilter>;
  phoneNo?: InputMaybe<StringFilter>;
  politicalExposedPerson?: InputMaybe<BoolNullableFilter>;
  postcode?: InputMaybe<StringFilter>;
  profession?: InputMaybe<StringNullableFilter>;
  prominentRole?: InputMaybe<BoolNullableFilter>;
  purposeOfInvestment?: InputMaybe<StringNullableFilter>;
  race?: InputMaybe<StringNullableFilter>;
  religion?: InputMaybe<StringNullableFilter>;
  safRating?: InputMaybe<IntNullableFilter>;
  salutation?: InputMaybe<StringNullableFilter>;
  sourceOfFund?: InputMaybe<StringNullableFilter>;
  sourceOfWealth?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringFilter>;
  taxResidency?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAccountTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProfileSumAggregate = {
  __typename?: 'ProfileSumAggregate';
  amlSanctionScreeningPoint?: Maybe<Scalars['Int']['output']>;
  annualIncome?: Maybe<Scalars['Decimal']['output']>;
  estimatedNetWorth?: Maybe<Scalars['Decimal']['output']>;
  expectedInvestmentAmount?: Maybe<Scalars['Decimal']['output']>;
  monthlyIncome?: Maybe<Scalars['Decimal']['output']>;
  safRating?: Maybe<Scalars['Int']['output']>;
};

export type ProfileUncheckedCreateInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutProfileInput>;
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bankAccounts?: InputMaybe<BankAccountUncheckedCreateNestedManyWithoutProfileInput>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city: Scalars['String']['input'];
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber: Scalars['String']['input'];
  idType: IdType;
  issuedConsents?: InputMaybe<ProfileConsentUncheckedCreateNestedManyWithoutIssuerProfileInput>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress: Scalars['String']['input'];
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  receivedConsents?: InputMaybe<ProfileConsentUncheckedCreateNestedManyWithoutRecipientProfileInput>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type: AccountType;
  users?: InputMaybe<UserUncheckedCreateNestedManyWithoutProfilesInput>;
};

export type ProfileUncheckedCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<ProfileCreateWithoutUsersInput>>;
};

export type ProfileUncheckedCreateWithoutIssuedConsentsInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutProfileInput>;
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bankAccounts?: InputMaybe<BankAccountUncheckedCreateNestedManyWithoutProfileInput>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city: Scalars['String']['input'];
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber: Scalars['String']['input'];
  idType: IdType;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress: Scalars['String']['input'];
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  receivedConsents?: InputMaybe<ProfileConsentUncheckedCreateNestedManyWithoutRecipientProfileInput>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type: AccountType;
  users?: InputMaybe<UserUncheckedCreateNestedManyWithoutProfilesInput>;
};

export type ProfileUncheckedCreateWithoutReceivedConsentsInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutProfileInput>;
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bankAccounts?: InputMaybe<BankAccountUncheckedCreateNestedManyWithoutProfileInput>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city: Scalars['String']['input'];
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber: Scalars['String']['input'];
  idType: IdType;
  issuedConsents?: InputMaybe<ProfileConsentUncheckedCreateNestedManyWithoutIssuerProfileInput>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress: Scalars['String']['input'];
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type: AccountType;
  users?: InputMaybe<UserUncheckedCreateNestedManyWithoutProfilesInput>;
};

export type ProfileUncheckedCreateWithoutUsersInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutProfileInput>;
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bankAccounts?: InputMaybe<BankAccountUncheckedCreateNestedManyWithoutProfileInput>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city: Scalars['String']['input'];
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber: Scalars['String']['input'];
  idType: IdType;
  issuedConsents?: InputMaybe<ProfileConsentUncheckedCreateNestedManyWithoutIssuerProfileInput>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress: Scalars['String']['input'];
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  receivedConsents?: InputMaybe<ProfileConsentUncheckedCreateNestedManyWithoutRecipientProfileInput>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type: AccountType;
};

export type ProfileUncheckedUpdateInput = {
  accounts?: InputMaybe<AccountUncheckedUpdateManyWithoutProfileNestedInput>;
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bankAccounts?: InputMaybe<BankAccountUncheckedUpdateManyWithoutProfileNestedInput>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<IdType>;
  issuedConsents?: InputMaybe<ProfileConsentUncheckedUpdateManyWithoutIssuerProfileNestedInput>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  phoneNo?: InputMaybe<Scalars['String']['input']>;
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  receivedConsents?: InputMaybe<ProfileConsentUncheckedUpdateManyWithoutRecipientProfileNestedInput>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AccountType>;
  users?: InputMaybe<UserUncheckedUpdateManyWithoutProfilesNestedInput>;
};

export type ProfileUncheckedUpdateManyWithoutUsersInput = {
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<IdType>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  phoneNo?: InputMaybe<Scalars['String']['input']>;
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AccountType>;
};

export type ProfileUncheckedUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<ProfileCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfileUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<ProfileUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<ProfileUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type ProfileUncheckedUpdateWithoutUsersInput = {
  accounts?: InputMaybe<AccountUncheckedUpdateManyWithoutProfileNestedInput>;
  amlSanctionScreeningPoint?: InputMaybe<Scalars['Int']['input']>;
  annualIncome?: InputMaybe<Scalars['Decimal']['input']>;
  bankAccounts?: InputMaybe<BankAccountUncheckedUpdateManyWithoutProfileNestedInput>;
  bumiputera?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  creationStatus?: InputMaybe<CreationStatus>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  declaration?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['String']['input']>;
  estimatedNetWorth?: InputMaybe<Scalars['Decimal']['input']>;
  expectedInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  frequencyOfTransaction?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  highNetWorth?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<IdType>;
  issuedConsents?: InputMaybe<ProfileConsentUncheckedUpdateManyWithoutIssuerProfileNestedInput>;
  jobIndustry?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  marketingPush?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Decimal']['input']>;
  mothersMaidenName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  natureOfBusiness?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  officePhoneNo?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  phoneNo?: InputMaybe<Scalars['String']['input']>;
  politicalExposedPerson?: InputMaybe<Scalars['Boolean']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  profession?: InputMaybe<Scalars['String']['input']>;
  prominentRole?: InputMaybe<Scalars['Boolean']['input']>;
  purposeOfInvestment?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<Scalars['String']['input']>;
  receivedConsents?: InputMaybe<ProfileConsentUncheckedUpdateManyWithoutRecipientProfileNestedInput>;
  religion?: InputMaybe<Scalars['String']['input']>;
  safRating?: InputMaybe<Scalars['Int']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sourceOfFund?: InputMaybe<Scalars['String']['input']>;
  sourceOfWealth?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxResidency?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AccountType>;
};

export type ProfileUpdateManyWithWhereWithoutUsersInput = {
  data: ProfileUncheckedUpdateManyWithoutUsersInput;
  where: ProfileScalarWhereInput;
};

export type ProfileUpdateWithWhereUniqueWithoutUsersInput = {
  data: ProfileUncheckedUpdateWithoutUsersInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileUpsertWithWhereUniqueWithoutUsersInput = {
  create: ProfileUncheckedCreateWithoutUsersInput;
  update: ProfileUncheckedUpdateWithoutUsersInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileWhereInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  amlSanctionScreeningPoint?: InputMaybe<IntNullableFilter>;
  annualIncome?: InputMaybe<DecimalNullableFilter>;
  bankAccounts?: InputMaybe<BankAccountListRelationFilter>;
  bumiputera?: InputMaybe<BoolNullableFilter>;
  city?: InputMaybe<StringFilter>;
  cityOfBirth?: InputMaybe<StringNullableFilter>;
  companyAddress?: InputMaybe<StringNullableFilter>;
  companyName?: InputMaybe<StringNullableFilter>;
  countryOfBirth?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  declaration?: InputMaybe<BoolFilter>;
  email?: InputMaybe<StringFilter>;
  employmentStatus?: InputMaybe<StringNullableFilter>;
  employmentType?: InputMaybe<StringNullableFilter>;
  estimatedNetWorth?: InputMaybe<DecimalNullableFilter>;
  expectedInvestmentAmount?: InputMaybe<DecimalNullableFilter>;
  frequencyOfTransaction?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  highNetWorth?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  idExpiryDate?: InputMaybe<DateTimeNullableFilter>;
  idNumber?: InputMaybe<StringFilter>;
  idType?: InputMaybe<EnumIdTypeFilter>;
  issuedConsents?: InputMaybe<ProfileConsentListRelationFilter>;
  jobIndustry?: InputMaybe<StringNullableFilter>;
  mailingAddress?: InputMaybe<StringFilter>;
  maritalStatus?: InputMaybe<StringNullableFilter>;
  marketingPush?: InputMaybe<BoolFilter>;
  monthlyIncome?: InputMaybe<DecimalNullableFilter>;
  mothersMaidenName?: InputMaybe<StringNullableFilter>;
  nationality?: InputMaybe<StringNullableFilter>;
  natureOfBusiness?: InputMaybe<StringNullableFilter>;
  occupation?: InputMaybe<StringNullableFilter>;
  officePhoneNo?: InputMaybe<StringNullableFilter>;
  permanentAddress?: InputMaybe<StringFilter>;
  phoneNo?: InputMaybe<StringFilter>;
  politicalExposedPerson?: InputMaybe<BoolNullableFilter>;
  postcode?: InputMaybe<StringFilter>;
  profession?: InputMaybe<StringNullableFilter>;
  prominentRole?: InputMaybe<BoolNullableFilter>;
  purposeOfInvestment?: InputMaybe<StringNullableFilter>;
  race?: InputMaybe<StringNullableFilter>;
  receivedConsents?: InputMaybe<ProfileConsentListRelationFilter>;
  religion?: InputMaybe<StringNullableFilter>;
  safRating?: InputMaybe<IntNullableFilter>;
  salutation?: InputMaybe<StringNullableFilter>;
  sourceOfFund?: InputMaybe<StringNullableFilter>;
  sourceOfWealth?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringFilter>;
  taxResidency?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAccountTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type ProfileWhereUniqueInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  amlSanctionScreeningPoint?: InputMaybe<IntNullableFilter>;
  annualIncome?: InputMaybe<DecimalNullableFilter>;
  bankAccounts?: InputMaybe<BankAccountListRelationFilter>;
  bumiputera?: InputMaybe<BoolNullableFilter>;
  city?: InputMaybe<StringFilter>;
  cityOfBirth?: InputMaybe<StringNullableFilter>;
  companyAddress?: InputMaybe<StringNullableFilter>;
  companyName?: InputMaybe<StringNullableFilter>;
  countryOfBirth?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  declaration?: InputMaybe<BoolFilter>;
  email?: InputMaybe<StringFilter>;
  employmentStatus?: InputMaybe<StringNullableFilter>;
  employmentType?: InputMaybe<StringNullableFilter>;
  estimatedNetWorth?: InputMaybe<DecimalNullableFilter>;
  expectedInvestmentAmount?: InputMaybe<DecimalNullableFilter>;
  frequencyOfTransaction?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  highNetWorth?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  idExpiryDate?: InputMaybe<DateTimeNullableFilter>;
  idNumber?: InputMaybe<StringFilter>;
  idType?: InputMaybe<EnumIdTypeFilter>;
  issuedConsents?: InputMaybe<ProfileConsentListRelationFilter>;
  jobIndustry?: InputMaybe<StringNullableFilter>;
  mailingAddress?: InputMaybe<StringFilter>;
  maritalStatus?: InputMaybe<StringNullableFilter>;
  marketingPush?: InputMaybe<BoolFilter>;
  monthlyIncome?: InputMaybe<DecimalNullableFilter>;
  mothersMaidenName?: InputMaybe<StringNullableFilter>;
  nationality?: InputMaybe<StringNullableFilter>;
  natureOfBusiness?: InputMaybe<StringNullableFilter>;
  occupation?: InputMaybe<StringNullableFilter>;
  officePhoneNo?: InputMaybe<StringNullableFilter>;
  permanentAddress?: InputMaybe<StringFilter>;
  phoneNo?: InputMaybe<StringFilter>;
  politicalExposedPerson?: InputMaybe<BoolNullableFilter>;
  postcode?: InputMaybe<StringFilter>;
  profession?: InputMaybe<StringNullableFilter>;
  prominentRole?: InputMaybe<BoolNullableFilter>;
  purposeOfInvestment?: InputMaybe<StringNullableFilter>;
  race?: InputMaybe<StringNullableFilter>;
  receivedConsents?: InputMaybe<ProfileConsentListRelationFilter>;
  religion?: InputMaybe<StringNullableFilter>;
  safRating?: InputMaybe<IntNullableFilter>;
  salutation?: InputMaybe<StringNullableFilter>;
  sourceOfFund?: InputMaybe<StringNullableFilter>;
  sourceOfWealth?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringFilter>;
  taxResidency?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAccountTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type ProfitAndLossValue = {
  __typename?: 'ProfitAndLossValue';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
  profitAndLossPercentage: Scalars['Decimal']['output'];
};

export type PublicHoliday = {
  __typename?: 'PublicHoliday';
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicHolidayCountAggregate = {
  __typename?: 'PublicHolidayCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PublicHolidayMaxAggregate = {
  __typename?: 'PublicHolidayMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PublicHolidayMinAggregate = {
  __typename?: 'PublicHolidayMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PublicHolidayOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PublicHolidayUncheckedCreateInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type PublicHolidayUncheckedUpdateInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PublicHolidayWhereInput = {
  AND?: InputMaybe<Array<PublicHolidayWhereInput>>;
  NOT?: InputMaybe<Array<PublicHolidayWhereInput>>;
  OR?: InputMaybe<Array<PublicHolidayWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PublicHolidayWhereUniqueInput = {
  AND?: InputMaybe<Array<PublicHolidayWhereInput>>;
  NOT?: InputMaybe<Array<PublicHolidayWhereInput>>;
  OR?: InputMaybe<Array<PublicHolidayWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PurchaseTransactionInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  distributionInstruction: Scalars['String']['input'];
  fundCode: Scalars['String']['input'];
  holdingUnit: Scalars['Float']['input'];
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountPortfolio?: Maybe<AccountPortfolio>;
  accountPortfolios: PaginatedAccountPortfolio;
  accountRequest?: Maybe<AccountRequest>;
  accountRequests: PaginatedAccountRequest;
  accounts: PaginatedAccount;
  adminPermission?: Maybe<AdminPermission>;
  adminPermissions: PaginatedAdminPermission;
  adminRole?: Maybe<AdminRole>;
  adminRoles: PaginatedAdminRole;
  adminUser?: Maybe<AdminUser>;
  adminUsers: PaginatedAdminUser;
  announcement?: Maybe<Announcement>;
  announcements: PaginatedAnnouncement;
  auditTrail?: Maybe<AuditTrail>;
  auditTrails: PaginatedAuditTrail;
  document?: Maybe<Document>;
  documents: PaginatedDocument;
  fund?: Maybe<Fund>;
  fundCategories?: Maybe<Array<FundCategory>>;
  fundDocument?: Maybe<FundDocument>;
  fundDocuments: PaginatedFundDocument;
  fundHoliday?: Maybe<FundHoliday>;
  fundHolidays: PaginatedFundHoliday;
  fundPolicies: PaginatedFundPolicy;
  fundPolicy?: Maybe<FundPolicy>;
  fundPrice?: Maybe<FundPrice>;
  fundPrices: PaginatedFundPrice;
  funds: PaginatedFund;
  health: Scalars['String']['output'];
  me: AuthUserUnion;
  modification?: Maybe<ModificationModel>;
  modifications: PaginatedModificationModel;
  myAccountPortfolios: PaginatedAccountPortfolio;
  myAccountSummary: MyAccountSummary;
  myConsentedProfileSummary?: Maybe<MyConsentedProfileSummary>;
  myConsentedProfiles?: Maybe<Array<ConsentedProfile>>;
  myProfile?: Maybe<Profile>;
  myProfiles?: Maybe<Array<Profile>>;
  myStatements: PaginatedStatement;
  myTransaction?: Maybe<Transaction>;
  myTransactions: PaginatedTransaction;
  order?: Maybe<Order>;
  orders: PaginatedOrder;
  payment?: Maybe<Payment>;
  payments: PaginatedPayment;
  profile?: Maybe<Profile>;
  profileConsent?: Maybe<ProfileConsent>;
  profileConsents: PaginatedProfileConsent;
  profiles: PaginatedProfile;
  publicAnnouncements?: Maybe<Array<Announcement>>;
  publicHoliday?: Maybe<PublicHoliday>;
  publicHolidays: PaginatedPublicHoliday;
  searchAdminUsers: PaginatedAdminUser;
  signatories: PaginatedSignatory;
  signatory?: Maybe<Signatory>;
  statement?: Maybe<Statement>;
  statements: PaginatedStatement;
  staticFaqs: Array<Faq>;
  transaction?: Maybe<Transaction>;
  transactions: PaginatedTransaction;
  user?: Maybe<User>;
  users: PaginatedUser;
  verifyCorporateAccountRequest?: Maybe<AccountRequest>;
};


export type QueryAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAccountPortfolioArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAccountPortfoliosArgs = {
  orderBy?: InputMaybe<Array<AccountPortfolioOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<AccountPortfolioWhereInput>;
};


export type QueryAccountRequestArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAccountRequestsArgs = {
  orderBy?: InputMaybe<Array<AccountRequestOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<AccountRequestWhereInput>;
};


export type QueryAccountsArgs = {
  orderBy?: InputMaybe<Array<AccountOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<AccountWhereInput>;
};


export type QueryAdminPermissionArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminPermissionsArgs = {
  orderBy?: InputMaybe<Array<AdminPermissionOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  where?: InputMaybe<AdminPermissionWhereInput>;
};


export type QueryAdminRoleArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminRolesArgs = {
  orderBy?: InputMaybe<Array<AdminRoleOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  where?: InputMaybe<AdminRoleWhereInput>;
};


export type QueryAdminUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminUsersArgs = {
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryAnnouncementArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAnnouncementsArgs = {
  orderBy?: InputMaybe<Array<AnnouncementOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<AnnouncementWhereInput>;
};


export type QueryAuditTrailArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAuditTrailsArgs = {
  orderBy?: InputMaybe<Array<AuditTrailOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<AuditTrailWhereInput>;
};


export type QueryDocumentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDocumentsArgs = {
  orderBy?: InputMaybe<Array<DocumentOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type QueryFundArgs = {
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFundDocumentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFundDocumentsArgs = {
  orderBy?: InputMaybe<Array<FundDocumentOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<FundDocumentWhereInput>;
};


export type QueryFundHolidayArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFundHolidaysArgs = {
  orderBy?: InputMaybe<Array<FundHolidayOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<FundHolidayWhereInput>;
};


export type QueryFundPoliciesArgs = {
  orderBy?: InputMaybe<Array<FundPolicyOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<FundPolicyWhereInput>;
};


export type QueryFundPolicyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFundPriceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFundPricesArgs = {
  orderBy?: InputMaybe<Array<FundPriceOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<FundPriceWhereInput>;
};


export type QueryFundsArgs = {
  orderBy?: InputMaybe<Array<FundOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<FundWhereInput>;
};


export type QueryModificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryModificationsArgs = {
  orderBy?: InputMaybe<Array<ModificationOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ModificationWhereInput>;
};


export type QueryMyAccountPortfoliosArgs = {
  filter?: InputMaybe<ListAccountPortfoliosByUserFilter>;
  orderBy?: InputMaybe<Array<AccountPortfolioOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<AccountPortfolioWhereInput>;
};


export type QueryMyAccountSummaryArgs = {
  accountId: Scalars['ID']['input'];
};


export type QueryMyProfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMyStatementsArgs = {
  orderBy?: InputMaybe<Array<StatementOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<StatementWhereInput>;
};


export type QueryMyTransactionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMyTransactionsArgs = {
  filter?: InputMaybe<ListTransactionsByUserFilter>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOrdersArgs = {
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryPaymentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPaymentsArgs = {
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryProfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProfileConsentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProfileConsentsArgs = {
  orderBy?: InputMaybe<Array<ProfileConsentOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ProfileConsentWhereInput>;
};


export type QueryProfilesArgs = {
  orderBy?: InputMaybe<Array<ProfileOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryPublicHolidayArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPublicHolidaysArgs = {
  orderBy?: InputMaybe<Array<PublicHolidayOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<PublicHolidayWhereInput>;
};


export type QuerySearchAdminUsersArgs = {
  keyword: Scalars['String']['input'];
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
};


export type QuerySignatoriesArgs = {
  orderBy?: InputMaybe<Array<SignatoryOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<SignatoryWhereInput>;
};


export type QuerySignatoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStatementArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStatementsArgs = {
  orderBy?: InputMaybe<Array<StatementOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<StatementWhereInput>;
};


export type QueryTransactionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVerifyCorporateAccountRequestArgs = {
  contactEmail: Scalars['String']['input'];
  contactNumber: Scalars['String']['input'];
  invitationCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Question = {
  __typename?: 'Question';
  answer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type RegisterInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  profiles?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutUsersInput>;
  status?: InputMaybe<Status>;
  username: Scalars['String']['input'];
};

export type RequestOtpInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  flow?: InputMaybe<OtpFlow>;
  isResend?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  purpose: OtpPurpose;
};

export type RequestResponse = {
  __typename?: 'RequestResponse';
  requestId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ResetPasswordInput = {
  otpChallengeCode: Scalars['String']['input'];
  otpChallengeId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Signatory = {
  __typename?: 'Signatory';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Order;
  orderId: Scalars['String']['output'];
  provider: SignatoryProvider;
  signature: Scalars['String']['output'];
  signatureId?: Maybe<Scalars['String']['output']>;
  status: SignatoryStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type SignatoryCountAggregate = {
  __typename?: 'SignatoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  orderId: Scalars['Int']['output'];
  provider: Scalars['Int']['output'];
  signatureId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type SignatoryCreateManyOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: SignatoryProvider;
  signatureId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SignatoryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SignatoryCreateManyOrderInputEnvelope = {
  data: Array<SignatoryCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SignatoryCreateOrConnectWithoutOrderInput = {
  create: SignatoryUncheckedCreateWithoutOrderInput;
  where: SignatoryWhereUniqueInput;
};

export type SignatoryCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: SignatoryProvider;
  signatureId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SignatoryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SignatoryInput = {
  name: Scalars['String']['input'];
  provider: SignatoryProvider;
  signature?: InputMaybe<CreateOneDocumentInput>;
};

export type SignatoryListRelationFilter = {
  every?: InputMaybe<SignatoryWhereInput>;
  none?: InputMaybe<SignatoryWhereInput>;
  some?: InputMaybe<SignatoryWhereInput>;
};

export type SignatoryMaxAggregate = {
  __typename?: 'SignatoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<SignatoryProvider>;
  signatureId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SignatoryStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SignatoryMinAggregate = {
  __typename?: 'SignatoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<SignatoryProvider>;
  signatureId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SignatoryStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SignatoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SignatoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  signatureId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SignatoryProvider {
  ESign = 'E_SIGN',
  Manual = 'MANUAL'
}

export enum SignatoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OrderId = 'orderId',
  Provider = 'provider',
  SignatureId = 'signatureId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type SignatoryScalarWhereInput = {
  AND?: InputMaybe<Array<SignatoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<SignatoryScalarWhereInput>>;
  OR?: InputMaybe<Array<SignatoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  provider?: InputMaybe<EnumSignatoryProviderFilter>;
  signatureId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumSignatoryStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum SignatoryStatus {
  Completed = 'Completed',
  Pending = 'Pending'
}

export type SignatoryUncheckedCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  provider: SignatoryProvider;
  signature?: InputMaybe<CreateOneDocumentInput>;
  signatureId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SignatoryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SignatoryUncheckedCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<SignatoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SignatoryCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<SignatoryCreateWithoutOrderInput>>;
  createMany?: InputMaybe<SignatoryCreateManyOrderInputEnvelope>;
};

export type SignatoryUncheckedCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: SignatoryProvider;
  signatureId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SignatoryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SignatoryUncheckedUpdateManyWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<SignatoryProvider>;
  signatureId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SignatoryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SignatoryUncheckedUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<SignatoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SignatoryCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<SignatoryCreateWithoutOrderInput>>;
  createMany?: InputMaybe<SignatoryCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<SignatoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SignatoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SignatoryWhereUniqueInput>>;
  set?: InputMaybe<Array<SignatoryWhereUniqueInput>>;
  update?: InputMaybe<Array<SignatoryUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<SignatoryUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<SignatoryUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type SignatoryUncheckedUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<SignatoryProvider>;
  signatureId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SignatoryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SignatoryUpdateManyWithWhereWithoutOrderInput = {
  data: SignatoryUncheckedUpdateManyWithoutOrderInput;
  where: SignatoryScalarWhereInput;
};

export type SignatoryUpdateWithWhereUniqueWithoutOrderInput = {
  data: SignatoryUncheckedUpdateWithoutOrderInput;
  where: SignatoryWhereUniqueInput;
};

export type SignatoryUpsertWithWhereUniqueWithoutOrderInput = {
  create: SignatoryUncheckedCreateWithoutOrderInput;
  update: SignatoryUncheckedUpdateWithoutOrderInput;
  where: SignatoryWhereUniqueInput;
};

export type SignatoryWhereInput = {
  AND?: InputMaybe<Array<SignatoryWhereInput>>;
  NOT?: InputMaybe<Array<SignatoryWhereInput>>;
  OR?: InputMaybe<Array<SignatoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  provider?: InputMaybe<EnumSignatoryProviderFilter>;
  signatureId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumSignatoryStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SignatoryWhereUniqueInput = {
  AND?: InputMaybe<Array<SignatoryWhereInput>>;
  NOT?: InputMaybe<Array<SignatoryWhereInput>>;
  OR?: InputMaybe<Array<SignatoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  provider?: InputMaybe<EnumSignatoryProviderFilter>;
  signatureId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumSignatoryStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SignedFile = {
  __typename?: 'SignedFile';
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type Statement = {
  __typename?: 'Statement';
  account: Account;
  accountId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  referenceNumber: Scalars['String']['output'];
  statementDate: Scalars['DateTime']['output'];
  statementLink?: Maybe<Scalars['String']['output']>;
  statementType: StatementType;
  status: StatementStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type StatementCountAggregate = {
  __typename?: 'StatementCountAggregate';
  _all: Scalars['Int']['output'];
  accountId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  displayName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  referenceNumber: Scalars['Int']['output'];
  statementDate: Scalars['Int']['output'];
  statementLink: Scalars['Int']['output'];
  statementType: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type StatementCreateManyAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  statementDate: Scalars['DateTime']['input'];
  statementLink?: InputMaybe<Scalars['String']['input']>;
  statementType: StatementType;
  status?: InputMaybe<StatementStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StatementCreateManyAccountInputEnvelope = {
  data: Array<StatementCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatementCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<StatementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatementCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<StatementCreateWithoutAccountInput>>;
  createMany?: InputMaybe<StatementCreateManyAccountInputEnvelope>;
};

export type StatementCreateOrConnectWithoutAccountInput = {
  create: StatementUncheckedCreateWithoutAccountInput;
  where: StatementWhereUniqueInput;
};

export type StatementCreateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  statementDate: Scalars['DateTime']['input'];
  statementLink?: InputMaybe<Scalars['String']['input']>;
  statementType: StatementType;
  status?: InputMaybe<StatementStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StatementListRelationFilter = {
  every?: InputMaybe<StatementWhereInput>;
  none?: InputMaybe<StatementWhereInput>;
  some?: InputMaybe<StatementWhereInput>;
};

export type StatementMaxAggregate = {
  __typename?: 'StatementMaxAggregate';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  statementDate?: Maybe<Scalars['DateTime']['output']>;
  statementLink?: Maybe<Scalars['String']['output']>;
  statementType?: Maybe<StatementType>;
  status?: Maybe<StatementStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StatementMinAggregate = {
  __typename?: 'StatementMinAggregate';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  statementDate?: Maybe<Scalars['DateTime']['output']>;
  statementLink?: Maybe<Scalars['String']['output']>;
  statementType?: Maybe<StatementType>;
  status?: Maybe<StatementStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StatementOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StatementOrderByWithRelationInput = {
  account?: InputMaybe<AccountOrderByWithRelationInput>;
  accountId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  referenceNumber?: InputMaybe<SortOrder>;
  statementDate?: InputMaybe<SortOrder>;
  statementLink?: InputMaybe<SortOrderInput>;
  statementType?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StatementScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  Id = 'id',
  ReferenceNumber = 'referenceNumber',
  StatementDate = 'statementDate',
  StatementLink = 'statementLink',
  StatementType = 'statementType',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type StatementScalarWhereInput = {
  AND?: InputMaybe<Array<StatementScalarWhereInput>>;
  NOT?: InputMaybe<Array<StatementScalarWhereInput>>;
  OR?: InputMaybe<Array<StatementScalarWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  statementDate?: InputMaybe<DateTimeFilter>;
  statementLink?: InputMaybe<StringNullableFilter>;
  statementType?: InputMaybe<EnumStatementTypeFilter>;
  status?: InputMaybe<EnumStatementStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum StatementStatus {
  Completed = 'Completed',
  Pending = 'Pending'
}

export enum StatementType {
  Monthly = 'monthly',
  TaxVoucher = 'taxVoucher',
  TxnAdviceSlip = 'txnAdviceSlip'
}

export type StatementUncheckedCreateInput = {
  accountId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  statementDate: Scalars['DateTime']['input'];
  statementLink?: InputMaybe<Scalars['String']['input']>;
  statementType: StatementType;
  status?: InputMaybe<StatementStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StatementUncheckedCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<StatementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatementCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<StatementCreateWithoutAccountInput>>;
  createMany?: InputMaybe<StatementCreateManyAccountInputEnvelope>;
};

export type StatementUncheckedCreateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  statementDate: Scalars['DateTime']['input'];
  statementLink?: InputMaybe<Scalars['String']['input']>;
  statementType: StatementType;
  status?: InputMaybe<StatementStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StatementUncheckedUpdateInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  statementDate?: InputMaybe<Scalars['DateTime']['input']>;
  statementLink?: InputMaybe<Scalars['String']['input']>;
  statementType?: InputMaybe<StatementType>;
  status?: InputMaybe<StatementStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StatementUncheckedUpdateManyWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  statementDate?: InputMaybe<Scalars['DateTime']['input']>;
  statementLink?: InputMaybe<Scalars['String']['input']>;
  statementType?: InputMaybe<StatementType>;
  status?: InputMaybe<StatementStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StatementUncheckedUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<StatementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatementCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<StatementCreateWithoutAccountInput>>;
  createMany?: InputMaybe<StatementCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<StatementWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StatementScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StatementWhereUniqueInput>>;
  set?: InputMaybe<Array<StatementWhereUniqueInput>>;
  update?: InputMaybe<Array<StatementUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<StatementUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<StatementUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type StatementUncheckedUpdateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  statementDate?: InputMaybe<Scalars['DateTime']['input']>;
  statementLink?: InputMaybe<Scalars['String']['input']>;
  statementType?: InputMaybe<StatementType>;
  status?: InputMaybe<StatementStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StatementUpdateManyWithWhereWithoutAccountInput = {
  data: StatementUncheckedUpdateManyWithoutAccountInput;
  where: StatementScalarWhereInput;
};

export type StatementUpdateWithWhereUniqueWithoutAccountInput = {
  data: StatementUncheckedUpdateWithoutAccountInput;
  where: StatementWhereUniqueInput;
};

export type StatementUpsertWithWhereUniqueWithoutAccountInput = {
  create: StatementUncheckedCreateWithoutAccountInput;
  update: StatementUncheckedUpdateWithoutAccountInput;
  where: StatementWhereUniqueInput;
};

export type StatementWhereInput = {
  AND?: InputMaybe<Array<StatementWhereInput>>;
  NOT?: InputMaybe<Array<StatementWhereInput>>;
  OR?: InputMaybe<Array<StatementWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  statementDate?: InputMaybe<DateTimeFilter>;
  statementLink?: InputMaybe<StringNullableFilter>;
  statementType?: InputMaybe<EnumStatementTypeFilter>;
  status?: InputMaybe<EnumStatementStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StatementWhereUniqueInput = {
  AND?: InputMaybe<Array<StatementWhereInput>>;
  NOT?: InputMaybe<Array<StatementWhereInput>>;
  OR?: InputMaybe<Array<StatementWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  statementDate?: InputMaybe<DateTimeFilter>;
  statementLink?: InputMaybe<StringNullableFilter>;
  statementType?: InputMaybe<EnumStatementTypeFilter>;
  status?: InputMaybe<EnumStatementStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum Status {
  Active = 'Active',
  Closed = 'Closed',
  Inactive = 'Inactive',
  ProfileLocked = 'ProfileLocked'
}

export type StatusResponse = {
  __typename?: 'StatusResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubCategory = {
  __typename?: 'SubCategory';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  questions: Array<Question>;
  sortOrder: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type SupportCreateInput = {
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type TomswinSyncAvgAggregate = {
  __typename?: 'TomswinSyncAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  lineNumber?: Maybe<Scalars['Float']['output']>;
};

export type TomswinSyncCountAggregate = {
  __typename?: 'TomswinSyncCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  data: Scalars['Int']['output'];
  fileName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lineNumber: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TomswinSyncDeadLetterAvgAggregate = {
  __typename?: 'TomswinSyncDeadLetterAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  lineNumber?: Maybe<Scalars['Float']['output']>;
};

export type TomswinSyncDeadLetterCountAggregate = {
  __typename?: 'TomswinSyncDeadLetterCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  errorMessage: Scalars['Int']['output'];
  fileName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lineNumber: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TomswinSyncDeadLetterMaxAggregate = {
  __typename?: 'TomswinSyncDeadLetterMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TomswinSyncDeadLetterMinAggregate = {
  __typename?: 'TomswinSyncDeadLetterMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TomswinSyncDeadLetterSumAggregate = {
  __typename?: 'TomswinSyncDeadLetterSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
};

export type TomswinSyncMaxAggregate = {
  __typename?: 'TomswinSyncMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TomswinSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TomswinSyncMinAggregate = {
  __typename?: 'TomswinSyncMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TomswinSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum TomswinSyncStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type TomswinSyncSumAggregate = {
  __typename?: 'TomswinSyncSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  account: Account;
  amount?: Maybe<Scalars['Decimal']['output']>;
  createdAt: Scalars['DateTime']['output'];
  detailedPublicStatus?: Maybe<TransactionDetailedPublicStatus>;
  fulfilledAmount?: Maybe<Scalars['Decimal']['output']>;
  fulfilledNavPrice?: Maybe<Scalars['Decimal']['output']>;
  fulfilledQuantity?: Maybe<Scalars['Decimal']['output']>;
  fund: Fund;
  fundCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  navPrice?: Maybe<Scalars['Decimal']['output']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']['output']>;
  publicStatus?: Maybe<TransactionPublicStatus>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  referenceNumber: Scalars['String']['output'];
  requestedAmount: Scalars['Decimal']['output'];
  requestedNavPrice: Scalars['Decimal']['output'];
  requestedQuantity: Scalars['Decimal']['output'];
  salesCharge: Scalars['Decimal']['output'];
  switchToFund?: Maybe<Fund>;
  switchToFundCode?: Maybe<Scalars['String']['output']>;
  transactionDatetime: Scalars['DateTime']['output'];
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  updatedAt: Scalars['DateTime']['output'];
};


export type TransactionOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type TransactionSwitchToFundArgs = {
  where?: InputMaybe<FundWhereInput>;
};

export type TransactionAvgAggregate = {
  __typename?: 'TransactionAvgAggregate';
  fulfilledAmount?: Maybe<Scalars['Decimal']['output']>;
  fulfilledNavPrice?: Maybe<Scalars['Decimal']['output']>;
  fulfilledQuantity?: Maybe<Scalars['Decimal']['output']>;
  requestedAmount?: Maybe<Scalars['Decimal']['output']>;
  requestedNavPrice?: Maybe<Scalars['Decimal']['output']>;
  requestedQuantity?: Maybe<Scalars['Decimal']['output']>;
  salesCharge?: Maybe<Scalars['Decimal']['output']>;
};

export type TransactionCountAggregate = {
  __typename?: 'TransactionCountAggregate';
  _all: Scalars['Int']['output'];
  accountId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  fulfilledAmount: Scalars['Int']['output'];
  fulfilledNavPrice: Scalars['Int']['output'];
  fulfilledQuantity: Scalars['Int']['output'];
  fundCode: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  orderId: Scalars['Int']['output'];
  referenceNumber: Scalars['Int']['output'];
  requestedAmount: Scalars['Int']['output'];
  requestedNavPrice: Scalars['Int']['output'];
  requestedQuantity: Scalars['Int']['output'];
  salesCharge: Scalars['Int']['output'];
  switchToFundCode: Scalars['Int']['output'];
  transactionDatetime: Scalars['Int']['output'];
  transactionStatus: Scalars['Int']['output'];
  transactionType: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TransactionCreateManyAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  requestedAmount: Scalars['Decimal']['input'];
  requestedNavPrice: Scalars['Decimal']['input'];
  requestedQuantity: Scalars['Decimal']['input'];
  salesCharge: Scalars['Decimal']['input'];
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime: Scalars['DateTime']['input'];
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType: TransactionType;
};

export type TransactionCreateManyAccountInputEnvelope = {
  data: Array<TransactionCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransactionCreateManyOrderInput = {
  accountId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  requestedAmount: Scalars['Decimal']['input'];
  requestedNavPrice: Scalars['Decimal']['input'];
  requestedQuantity: Scalars['Decimal']['input'];
  salesCharge: Scalars['Decimal']['input'];
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime: Scalars['DateTime']['input'];
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType: TransactionType;
};

export type TransactionCreateManyOrderInputEnvelope = {
  data: Array<TransactionCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransactionCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<TransactionCreateManyAccountInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutAccountInput = {
  create: TransactionUncheckedCreateWithoutAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutOrderInput = {
  create: TransactionUncheckedCreateWithoutOrderInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fund: FundCreateNestedOneWithoutTransactionsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutTransactionsInput>;
  referenceNumber: Scalars['String']['input'];
  requestedAmount: Scalars['Decimal']['input'];
  requestedNavPrice: Scalars['Decimal']['input'];
  requestedQuantity: Scalars['Decimal']['input'];
  salesCharge: Scalars['Decimal']['input'];
  switchToFund?: InputMaybe<FundCreateNestedOneWithoutSwitchToTransactionsInput>;
  transactionDatetime: Scalars['DateTime']['input'];
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType: TransactionType;
};

export type TransactionCreateWithoutOrderInput = {
  account: AccountCreateNestedOneWithoutTransactionInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fund: FundCreateNestedOneWithoutTransactionsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  requestedAmount: Scalars['Decimal']['input'];
  requestedNavPrice: Scalars['Decimal']['input'];
  requestedQuantity: Scalars['Decimal']['input'];
  salesCharge: Scalars['Decimal']['input'];
  switchToFund?: InputMaybe<FundCreateNestedOneWithoutSwitchToTransactionsInput>;
  transactionDatetime: Scalars['DateTime']['input'];
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType: TransactionType;
};

export enum TransactionDetailedPublicStatus {
  Completed = 'Completed',
  PendingPayment = 'PendingPayment',
  PendingReview = 'PendingReview',
  PendingSignature = 'PendingSignature',
  Processing = 'Processing',
  Rejected = 'Rejected'
}

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>;
  none?: InputMaybe<TransactionWhereInput>;
  some?: InputMaybe<TransactionWhereInput>;
};

export type TransactionMaxAggregate = {
  __typename?: 'TransactionMaxAggregate';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fulfilledAmount?: Maybe<Scalars['Decimal']['output']>;
  fulfilledNavPrice?: Maybe<Scalars['Decimal']['output']>;
  fulfilledQuantity?: Maybe<Scalars['Decimal']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Decimal']['output']>;
  requestedNavPrice?: Maybe<Scalars['Decimal']['output']>;
  requestedQuantity?: Maybe<Scalars['Decimal']['output']>;
  salesCharge?: Maybe<Scalars['Decimal']['output']>;
  switchToFundCode?: Maybe<Scalars['String']['output']>;
  transactionDatetime?: Maybe<Scalars['DateTime']['output']>;
  transactionStatus?: Maybe<TransactionStatus>;
  transactionType?: Maybe<TransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TransactionMinAggregate = {
  __typename?: 'TransactionMinAggregate';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fulfilledAmount?: Maybe<Scalars['Decimal']['output']>;
  fulfilledNavPrice?: Maybe<Scalars['Decimal']['output']>;
  fulfilledQuantity?: Maybe<Scalars['Decimal']['output']>;
  fundCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Decimal']['output']>;
  requestedNavPrice?: Maybe<Scalars['Decimal']['output']>;
  requestedQuantity?: Maybe<Scalars['Decimal']['output']>;
  salesCharge?: Maybe<Scalars['Decimal']['output']>;
  switchToFundCode?: Maybe<Scalars['String']['output']>;
  transactionDatetime?: Maybe<Scalars['DateTime']['output']>;
  transactionStatus?: Maybe<TransactionStatus>;
  transactionType?: Maybe<TransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithRelationInput = {
  account?: InputMaybe<AccountOrderByWithRelationInput>;
  accountId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fulfilledAmount?: InputMaybe<SortOrderInput>;
  fulfilledNavPrice?: InputMaybe<SortOrderInput>;
  fulfilledQuantity?: InputMaybe<SortOrderInput>;
  fund?: InputMaybe<FundOrderByWithRelationInput>;
  fundCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  referenceNumber?: InputMaybe<SortOrder>;
  requestedAmount?: InputMaybe<SortOrder>;
  requestedNavPrice?: InputMaybe<SortOrder>;
  requestedQuantity?: InputMaybe<SortOrder>;
  salesCharge?: InputMaybe<SortOrder>;
  switchToFund?: InputMaybe<FundOrderByWithRelationInput>;
  switchToFundCode?: InputMaybe<SortOrderInput>;
  transactionDatetime?: InputMaybe<SortOrder>;
  transactionStatus?: InputMaybe<SortOrder>;
  transactionType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TransactionProcessFlow = {
  __typename?: 'TransactionProcessFlow';
  purchase: Array<ProcessFlow>;
  withdrawal: Array<ProcessFlow>;
};

export enum TransactionPublicStatus {
  Completed = 'Completed',
  Processing = 'Processing',
  Rejected = 'Rejected'
}

export enum TransactionScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  FulfilledAmount = 'fulfilledAmount',
  FulfilledNavPrice = 'fulfilledNavPrice',
  FulfilledQuantity = 'fulfilledQuantity',
  FundCode = 'fundCode',
  Id = 'id',
  OrderId = 'orderId',
  ReferenceNumber = 'referenceNumber',
  RequestedAmount = 'requestedAmount',
  RequestedNavPrice = 'requestedNavPrice',
  RequestedQuantity = 'requestedQuantity',
  SalesCharge = 'salesCharge',
  SwitchToFundCode = 'switchToFundCode',
  TransactionDatetime = 'transactionDatetime',
  TransactionStatus = 'transactionStatus',
  TransactionType = 'transactionType',
  UpdatedAt = 'updatedAt'
}

export type TransactionScalarWhereInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fulfilledAmount?: InputMaybe<DecimalNullableFilter>;
  fulfilledNavPrice?: InputMaybe<DecimalNullableFilter>;
  fulfilledQuantity?: InputMaybe<DecimalNullableFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  requestedAmount?: InputMaybe<DecimalFilter>;
  requestedNavPrice?: InputMaybe<DecimalFilter>;
  requestedQuantity?: InputMaybe<DecimalFilter>;
  salesCharge?: InputMaybe<DecimalFilter>;
  switchToFundCode?: InputMaybe<StringNullableFilter>;
  transactionDatetime?: InputMaybe<DateTimeFilter>;
  transactionStatus?: InputMaybe<EnumTransactionStatusFilter>;
  transactionType?: InputMaybe<EnumTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum TransactionStatus {
  Approved = 'Approved',
  Failed = 'Failed',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
  Succeeded = 'Succeeded',
  Void = 'Void'
}

export type TransactionSumAggregate = {
  __typename?: 'TransactionSumAggregate';
  fulfilledAmount?: Maybe<Scalars['Decimal']['output']>;
  fulfilledNavPrice?: Maybe<Scalars['Decimal']['output']>;
  fulfilledQuantity?: Maybe<Scalars['Decimal']['output']>;
  requestedAmount?: Maybe<Scalars['Decimal']['output']>;
  requestedNavPrice?: Maybe<Scalars['Decimal']['output']>;
  requestedQuantity?: Maybe<Scalars['Decimal']['output']>;
  salesCharge?: Maybe<Scalars['Decimal']['output']>;
};

export enum TransactionType {
  Buy = 'Buy',
  Sell = 'Sell',
  Switch = 'Switch'
}

export type TransactionUncheckedCreateInput = {
  accountId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  requestedAmount: Scalars['Decimal']['input'];
  requestedNavPrice: Scalars['Decimal']['input'];
  requestedQuantity: Scalars['Decimal']['input'];
  salesCharge: Scalars['Decimal']['input'];
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime: Scalars['DateTime']['input'];
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType: TransactionType;
};

export type TransactionUncheckedCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<TransactionCreateManyAccountInputEnvelope>;
};

export type TransactionUncheckedCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TransactionCreateManyOrderInputEnvelope>;
};

export type TransactionUncheckedCreateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  requestedAmount: Scalars['Decimal']['input'];
  requestedNavPrice: Scalars['Decimal']['input'];
  requestedQuantity: Scalars['Decimal']['input'];
  salesCharge: Scalars['Decimal']['input'];
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime: Scalars['DateTime']['input'];
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType: TransactionType;
};

export type TransactionUncheckedCreateWithoutOrderInput = {
  accountId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber: Scalars['String']['input'];
  requestedAmount: Scalars['Decimal']['input'];
  requestedNavPrice: Scalars['Decimal']['input'];
  requestedQuantity: Scalars['Decimal']['input'];
  salesCharge: Scalars['Decimal']['input'];
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime: Scalars['DateTime']['input'];
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType: TransactionType;
};

export type TransactionUncheckedUpdateInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  requestedNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  requestedQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  salesCharge?: InputMaybe<Scalars['Decimal']['input']>;
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionUncheckedUpdateManyWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  requestedNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  requestedQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  salesCharge?: InputMaybe<Scalars['Decimal']['input']>;
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionUncheckedUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<TransactionCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type TransactionUncheckedUpdateManyWithoutOrderInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  requestedNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  requestedQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  salesCharge?: InputMaybe<Scalars['Decimal']['input']>;
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionUncheckedUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutOrderInput>>;
  createMany?: InputMaybe<TransactionCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type TransactionUncheckedUpdateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  requestedNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  requestedQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  salesCharge?: InputMaybe<Scalars['Decimal']['input']>;
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionUncheckedUpdateWithoutOrderInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fulfilledAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  fulfilledQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  fundCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  requestedNavPrice?: InputMaybe<Scalars['Decimal']['input']>;
  requestedQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  salesCharge?: InputMaybe<Scalars['Decimal']['input']>;
  switchToFundCode?: InputMaybe<Scalars['String']['input']>;
  transactionDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionUpdateManyWithWhereWithoutAccountInput = {
  data: TransactionUncheckedUpdateManyWithoutAccountInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutOrderInput = {
  data: TransactionUncheckedUpdateManyWithoutOrderInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateWithWhereUniqueWithoutAccountInput = {
  data: TransactionUncheckedUpdateWithoutAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutOrderInput = {
  data: TransactionUncheckedUpdateWithoutOrderInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutAccountInput = {
  create: TransactionUncheckedCreateWithoutAccountInput;
  update: TransactionUncheckedUpdateWithoutAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutOrderInput = {
  create: TransactionUncheckedCreateWithoutOrderInput;
  update: TransactionUncheckedUpdateWithoutOrderInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fulfilledAmount?: InputMaybe<DecimalNullableFilter>;
  fulfilledNavPrice?: InputMaybe<DecimalNullableFilter>;
  fulfilledQuantity?: InputMaybe<DecimalNullableFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  requestedAmount?: InputMaybe<DecimalFilter>;
  requestedNavPrice?: InputMaybe<DecimalFilter>;
  requestedQuantity?: InputMaybe<DecimalFilter>;
  salesCharge?: InputMaybe<DecimalFilter>;
  switchToFund?: InputMaybe<FundNullableRelationFilter>;
  switchToFundCode?: InputMaybe<StringNullableFilter>;
  transactionDatetime?: InputMaybe<DateTimeFilter>;
  transactionStatus?: InputMaybe<EnumTransactionStatusFilter>;
  transactionType?: InputMaybe<EnumTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransactionWhereUniqueInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fulfilledAmount?: InputMaybe<DecimalNullableFilter>;
  fulfilledNavPrice?: InputMaybe<DecimalNullableFilter>;
  fulfilledQuantity?: InputMaybe<DecimalNullableFilter>;
  fund?: InputMaybe<FundRelationFilter>;
  fundCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<DecimalFilter>;
  requestedNavPrice?: InputMaybe<DecimalFilter>;
  requestedQuantity?: InputMaybe<DecimalFilter>;
  salesCharge?: InputMaybe<DecimalFilter>;
  switchToFund?: InputMaybe<FundNullableRelationFilter>;
  switchToFundCode?: InputMaybe<StringNullableFilter>;
  transactionDatetime?: InputMaybe<DateTimeFilter>;
  transactionStatus?: InputMaybe<EnumTransactionStatusFilter>;
  transactionType?: InputMaybe<EnumTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTime']['output'];
  creationStatus: CreationStatus;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orders: Array<Order>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  profiles: Array<Profile>;
  status: Status;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};


export type UserOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type UserProfilesArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProfileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProfileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  orders: Scalars['Int']['output'];
  profiles: Scalars['Int']['output'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  creationStatus: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  password: Scalars['Int']['output'];
  profilePicture: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  username: Scalars['Int']['output'];
};

export type UserCreateOrConnectWithoutProfilesInput = {
  create: UserUncheckedCreateWithoutProfilesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutProfilesInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  username: Scalars['String']['input'];
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creationStatus?: Maybe<CreationStatus>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  creationStatus?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  password?: InputMaybe<SortOrderInput>;
  profilePicture?: InputMaybe<SortOrderInput>;
  profiles?: InputMaybe<ProfileOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreationStatus = 'creationStatus',
  DeletedAt = 'deletedAt',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  Password = 'password',
  ProfilePicture = 'profilePicture',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export enum UserType {
  Admin = 'Admin',
  Customer = 'Customer'
}

export type UserUncheckedCreateInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  profiles?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutUsersInput>;
  status?: InputMaybe<Status>;
  username: Scalars['String']['input'];
};

export type UserUncheckedCreateNestedManyWithoutProfilesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutProfilesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutProfilesInput>>;
};

export type UserUncheckedCreateWithoutProfilesInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  username: Scalars['String']['input'];
};

export type UserUncheckedUpdateInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  profiles?: InputMaybe<ProfileUncheckedUpdateManyWithoutUsersNestedInput>;
  status?: InputMaybe<Status>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserUncheckedUpdateManyWithoutProfilesInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserUncheckedUpdateManyWithoutProfilesNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutProfilesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutProfilesInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutProfilesInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutProfilesInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutProfilesInput>>;
};

export type UserUncheckedUpdateWithoutProfilesInput = {
  creationStatus?: InputMaybe<CreationStatus>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateManyWithWhereWithoutProfilesInput = {
  data: UserUncheckedUpdateManyWithoutProfilesInput;
  where: UserScalarWhereInput;
};

export type UserUpdateWithWhereUniqueWithoutProfilesInput = {
  data: UserUncheckedUpdateWithoutProfilesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutProfilesInput = {
  create: UserUncheckedCreateWithoutProfilesInput;
  update: UserUncheckedUpdateWithoutProfilesInput;
  where: UserWhereUniqueInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  profiles?: InputMaybe<ProfileListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creationStatus?: InputMaybe<EnumCreationStatusFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  profiles?: InputMaybe<ProfileListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyOtpInput = {
  challengeId: Scalars['String']['input'];
  code: Scalars['String']['input'];
};

export type CreateAdminRoleMutationVariables = Exact<{
  data: AdminRoleCreateInputV2;
}>;


export type CreateAdminRoleMutation = { __typename?: 'Mutation', createOneAdminRole: { __typename?: 'AdminRole', id: string } };

export type CreateAdminUserMutationVariables = Exact<{
  data: AdminUserCreateInputV2;
}>;


export type CreateAdminUserMutation = { __typename?: 'Mutation', createOneAdminUser: { __typename?: 'AdminUser', id: string } };

export type UpdateAdminRoleMutationVariables = Exact<{
  data: AdminRoleUpdateInputV2;
  where: AdminRoleWhereUniqueInput;
}>;


export type UpdateAdminRoleMutation = { __typename?: 'Mutation', updateOneAdminRole?: { __typename?: 'AdminRole', id: string } | null };

export type UpdateAdminUserMutationVariables = Exact<{
  data: AdminUserUpdateInputV2;
  where: AdminUserWhereUniqueInput;
}>;


export type UpdateAdminUserMutation = { __typename?: 'Mutation', updateOneAdminUser?: { __typename?: 'AdminUser', id: string } | null };


export const CreateAdminRoleDocument = gql`
    mutation createAdminRole($data: AdminRoleCreateInputV2!) {
  createOneAdminRole(data: $data) {
    id
  }
}
    `;
export const CreateAdminUserDocument = gql`
    mutation createAdminUser($data: AdminUserCreateInputV2!) {
  createOneAdminUser(data: $data) {
    id
  }
}
    `;
export const UpdateAdminRoleDocument = gql`
    mutation updateAdminRole($data: AdminRoleUpdateInputV2!, $where: AdminRoleWhereUniqueInput!) {
  updateOneAdminRole(data: $data, where: $where) {
    id
  }
}
    `;
export const UpdateAdminUserDocument = gql`
    mutation updateAdminUser($data: AdminUserUpdateInputV2!, $where: AdminUserWhereUniqueInput!) {
  updateOneAdminUser(data: $data, where: $where) {
    id
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createAdminRole(variables: CreateAdminRoleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAdminRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAdminRoleMutation>(CreateAdminRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAdminRole', 'mutation', variables);
    },
    createAdminUser(variables: CreateAdminUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAdminUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAdminUserMutation>(CreateAdminUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAdminUser', 'mutation', variables);
    },
    updateAdminRole(variables: UpdateAdminRoleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateAdminRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAdminRoleMutation>(UpdateAdminRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAdminRole', 'mutation', variables);
    },
    updateAdminUser(variables: UpdateAdminUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateAdminUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAdminUserMutation>(UpdateAdminUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAdminUser', 'mutation', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;