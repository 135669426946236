import * as React from 'react';
import { HiPlusCircle } from 'react-icons/hi';

import {
  Button,
  ButtonProps,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@amfintech/react-admin-ui';
import {
  Content,
  CreateResourceEditPageOptions,
  CreateDataInput,
  ResourceTableConfig,
  CreateResourceEditDialogReturns,
  CreateResourceCreatePageOptions,
} from '@amfintech/refine-react-admin';

import { ConsentType, Profile, ProfileConsent } from '~/api';
import { resourceNames } from '~/resources/resource-names';

import { ProfileConsentPage } from './profile-consent';
import { ProfileInfoPage } from './profile-info';
import { ProfileMaintenancePage } from './profile-maintenance';
import { ProfileTransactionsPage } from './profile-transactions';

export const ProfileShowPage = ({
  profile,
  createTableComponent,
  createResourceEditDialog,
  createResourceCreateButton,
}: {
  profile?: Profile;
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  createResourceEditDialog: <T extends object, DefaultValues extends CreateDataInput<T>>(
    options: CreateResourceEditPageOptions<T, DefaultValues>
  ) => CreateResourceEditDialogReturns;
  createResourceCreateButton: <T extends object, DefaultValues extends CreateDataInput<T>>(
    options: CreateResourceCreatePageOptions<T, DefaultValues>
  ) => (props: ButtonProps) => React.ReactNode;
}) => {
  const [currentTab, setCurrentTab] = React.useState('personal');

  const CreateButton = createResourceCreateButton<ProfileConsent, CreateDataInput<ProfileConsent>>({
    resourceName: resourceNames.profileConsent,
    resourceLabel: 'Profile Consent',
    defaultValues: {
      recipientId: profile?.idNumber,
    },
    controls: {
      components: {
        issuerId: {
          type: 'text',
          config: {
            label: 'Issuer Identity Number',
            placeholder: 'Enter issuer identity number',
          },
        },
        recipientId: {
          type: 'text',
          config: {
            label: 'Recipient Identity Number',
            disabled: true,
          },
        },
        consentType: {
          type: 'select',
          config: {
            label: 'Consent Type',
            placeholder: 'Select consent type',
            options: Object.values(ConsentType).map((consentType) => ({
              label: consentType,
              value: consentType,
            })),
          },
        },
      },
    },
  });

  return (
    profile && (
      <div className="w-full">
        <Content
          title="Customer Profile"
          extra={
            currentTab === 'consent' ? (
              <CreateButton
                variant="solid"
                size="sm"
                className="min-w-[7rem]"
                icon={<HiPlusCircle />}
              />
            ) : undefined
          }
        >
          <Tabs defaultValue="personal" onValueChange={setCurrentTab}>
            <TabsList>
              <TabsTrigger value="personal">Personal Information</TabsTrigger>
              <TabsTrigger value="maintenance">Maintenance</TabsTrigger>
              <TabsTrigger value="transactions">Transactions</TabsTrigger>
              <TabsTrigger value="consent">Profile Consent</TabsTrigger>
            </TabsList>

            <TabsContent value="personal">
              <ProfileInfoPage profile={profile} />
            </TabsContent>

            <TabsContent value="maintenance">
              <ProfileMaintenancePage
                createTableComponent={createTableComponent}
                createResourceEditDialog={createResourceEditDialog}
                profileId={profile?.id}
              />
            </TabsContent>

            <TabsContent value="transactions">
              <ProfileTransactionsPage
                createTableComponent={createTableComponent}
                profileId={profile?.id}
              />
            </TabsContent>

            <TabsContent value="consent">
              <ProfileConsentPage
                createTableComponent={createTableComponent}
                profileId={profile?.id}
              />
            </TabsContent>
          </Tabs>
        </Content>
      </div>
    )
  );
};
