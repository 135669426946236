export const resourceNames = {
  fund: 'funds',
  user: 'users',
  customer: 'customers',
  adminUser: 'admin-users',
  adminRole: 'admin-roles',
  account: 'accounts',
  accountRequest: 'account-requests',
  leadGeneration: 'lead-generations',  
  accountOpening: 'account-openings',
  transaction: 'transactions',
  fundInfo: 'funds',
  fundPrice: 'fund-prices',
  fundHoliday: 'fund-holidays',
  publicHoliday: 'public-holidays',
  auditTrails: 'audit-trails',
  adminAudit: 'admin-audits',
  customerAudit: 'customer-audits',
  announcement: 'announcements',
  modifications: 'modifications',
  profile: 'profiles',
  order: 'orders',
  payment: 'payments',
  signatory: 'signatories',
  fundDocument: 'fund-documents',
  profileConsent: 'profile-consents',
  document: 'documents',
} as const;
