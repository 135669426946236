import { StatusField } from "@amfintech/react-admin-ui";
import { CreateDataInput, CreateResourceEditDialogReturns, CreateResourceEditPageOptions, ResourceTableConfig } from "@amfintech/refine-react-admin";

import { ModificationEntityType, Status, User } from "~/api";
import { formatUserStatus } from "~/resources/customer-resource";
import { resourceNames } from "~/resources/resource-names";

export const ProfileMaintenancePage = ({
  createTableComponent,
  createResourceEditDialog,
  profileId,
}: {
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  createResourceEditDialog: <T extends object, DefaultValues extends CreateDataInput<T>>(
    options: CreateResourceEditPageOptions<T, DefaultValues>
  ) => CreateResourceEditDialogReturns;
  profileId?: string;
}) => {
  const tableComponent = createTableComponent<User>({
    resource: resourceNames.user,
    fields: ['id', 'name', 'username', 'email', 'status'],
    columns: () => [
      {
        field: 'username',
        header: 'Username',
        accessorKey: 'username',
      },
      {
        field: 'name',
        header: 'Name',
        accessorKey: 'name',
      },
      {
        field: 'email',
        header: 'Email',
        accessorKey: 'email',
      },
      {
        field: 'status',
        header: 'Status',
        accessorKey: 'status',
        cell: (data) => {
          const status = data.cell.getValue<Status>();
          return <StatusField status={formatUserStatus(status)} label={status} />;
        },
      },
      {
        field: 'action',
        header: 'Action',
        cell: (data) => {
          const EditButton =createResourceEditDialog<User, CreateDataInput<User>>({
            resourceName: resourceNames.user,
            fields: ['id', 'username', 'email', 'status'],
            defaultValues: {
              email: data.row.original.email,
              status: data.row.original.status,
            },
            controls: {
              components: {
                email: {
                  type: 'text',
                  config: {
                    label: 'Email',
                    disabled: true,
                  },
                },
                status: {
                  type: 'select',
                  config: {
                    label: 'Status',
                    options: Object.values(Status).map((status) => ({
                      label: status,
                      value: status,
                    })),
                  },
                },
              },
            },
            makerChecker: {
              entityType: ModificationEntityType.User,
            }
          }).Button;
          return <EditButton resourceId={data.row.original.id} variant="solid" size="sm" className="min-w-[6rem]" />;
        },
      },
    ],
    filterControls: {
      email: {
        type: 'text',
        config: {
          label: 'Email',
        },
        operator: 'contains',
      },
    },
    filterConfig: {
      permanent: [
        {
          field: '.profiles.some.id' as any,
          operator: 'eq',
          value: profileId,
        },
      ],
    },
  });

  return tableComponent();
};

