import * as React from 'react';

import { assertExists, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { type AdminRole, AdminUser, getSdk, Modification } from '~/api';
import { AdminRoleListPage } from '~/pages/admin-role/admin-role-list';
import { RoleList } from '~/pages/edit-role/role-list';

import { resourceNames } from './resource-names';

const {
  defineFields,
  defineCustomPage,
  defineRelatedResourceSection,
  defineCardSection,
  defineShowPage,
  renderModificationTable,
} = createHelpers<AdminRole>({
  resourceName: resourceNames.adminRole,
});

const defaultValues = {
  name: '',
  description: '',
  permissions: [] as string[],
};

export const adminRoleResource = createResource({
  name: resourceNames.adminRole,
  label: 'User Groups & Permissions',
  fields: defineFields(['id', 'name', 'description', 'permissions']),
  defaultValues,
  dataProvider: {
    create: ({ client, variables }) =>
      getSdk(client)
        .createAdminRole({
          data: variables,
        })
        .then((res) => ({
          data: res.createOneAdminRole,
        })),
    update: ({ client, variables, id }) =>
      getSdk(client)
        .updateAdminRole({
          data: variables,
          where: {
            id: Number(id),
          },
        })
        .then((res) => {
          assertExists(res.updateOneAdminRole);

          return {
            data: res.updateOneAdminRole,
          };
        }),
  },
  controls: {
    components: {
      description: {
        type: 'text',
        config: {
          label: 'Description',
        },
      },
      permissions: {
        type: 'custom',
        config: {
          label: 'Permissions',
          required: false,
          span: 2,
          render: (field) => {
            const [appliedPermissions, setAppliedPermissions] = React.useState(
              new Set<string>(field.value)
            );

            React.useEffect(() => {
              setAppliedPermissions(new Set<string>(field.value));
            }, [field.value]);

            const handleAccessChange = (code: string) => {
              appliedPermissions.has(code)
                ? appliedPermissions.delete(code)
                : appliedPermissions.add(code);
              setAppliedPermissions(appliedPermissions);
              field.onChange(Array.from(appliedPermissions));
            };

            return (
              <div className="w-full">
                <RoleList
                  appliedPermissions={appliedPermissions}
                  handleAccessChange={handleAccessChange}
                />
              </div>
            );
          },
        },
      },
    },
    componentConfigDefaults: {
      required: true,
      span: 2,
    },
  },
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: () => [],
  allowEdit: true,
  allowDelete: true,
  list: defineCustomPage({
    title: 'User Groups & Permissions',
    render: () => (
      <AdminRoleListPage
        title="User Groups & Permissions"
        renderModificationTable={renderModificationTable<Modification>}
      />
    ),
  }),
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'General',
        fields: ['name', 'description', 'permissions'],
        displays: {
          permissions: {
            label: 'Applied Permissions',
            span: 2,
            render: (resource) => {
              const appliedPermissions = new Set(resource.permissions);

              return (
                <>
                  {appliedPermissions.size > 0 ? (
                    <RoleList
                      appliedPermissions={appliedPermissions}
                      handleAccessChange={() => {}}
                      onlyShowAppliedPermissions
                    />
                  ) : (
                    <div className="text-left text-black text-sm">No permissions applied</div>
                  )}
                </>
              );
            },
          },
        },
      }),
      defineRelatedResourceSection<AdminUser>({
        relatedResourceName: resourceNames.adminUser,
        tableVariant: 'border',
        fields: ['id'],
        relatedResourceFields: ['username', 'name', 'email', 'status'],
        columns: ({ LinkToDetails }) => [
          {
            id: 'username',
            header: 'LAN ID',
            accessorKey: 'username',
            cell: (data) => {
              const username = data.cell.getValue<string>();

              return (
                <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
                  {username}
                </LinkToDetails>
              );
            },
          },
          {
            id: 'name',
            header: 'Name',
            accessorKey: 'name',
          },
          {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
          },
          {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            cell: (data) => {
              const status = data.cell.getValue<string>();

              return (
                <StatusField status={status === 'Active' ? 'success' : 'danger'} label={status} />
              );
            },
          },
        ],
        filterControls: {
          username: {
            type: 'text',
            config: {
              label: 'LAN ID',
            },
            operator: 'contains',
          },
        },
        getFilterConfig: ({ id }) => ({
          collapsible: 'collapse-by-default',
          permanent: [
            {
              field: 'roleId',
              operator: 'eq',
              value: Number(id) as any,
            },
          ],
        }),
      }),
    ],
  }),
});
