import { ResourceTableConfig } from "@amfintech/refine-react-admin";

import { AccountType, CreationStatus, ProfileConsent } from "~/api";
import { resourceNames } from "~/resources/resource-names";

export const ProfileConsentPage = ({
  createTableComponent,
  profileId,  
}: {
  createTableComponent: <T extends object>(
    props: ResourceTableConfig<T>,
  ) => () => React.ReactNode;
  profileId?: string;
}) => {
  const tableComponent = createTableComponent<ProfileConsent>({
    resource: resourceNames.profileConsent,
    fields: ['id', 'consentType', { issuerProfile: ['id', 'fullName', 'companyName', 'type'] }, { recipientProfile: ['id', 'fullName', 'companyName', 'type'] }, 'status'],
    columns: () => [
      {
        id: 'consentType',
        header: 'Consent Type',
        accessorKey: 'consentType',
      },
      {
        id: 'issuerProfile.companyName',
        header: 'Issuer Profile',
        accessorKey: 'issuerProfile.companyName',
        cell: ({ row }) => {
          if (row.original.issuerProfile?.type === AccountType.Corporate) {
            return row.original.issuerProfile.companyName;
          }
          return row.original.issuerProfile?.fullName;
        }
      },
      {
        id: 'recipientProfile.companyName',
        header: 'Recipient Profile',
        accessorKey: 'recipientProfile.companyName',
        cell: ({ row }) => {
          if (row.original.recipientProfile?.type === AccountType.Corporate) {
            return row.original.recipientProfile.companyName;
          }
          return row.original.recipientProfile?.fullName;
        }
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
      },
    ],
    filterConfig: {
      permanent: [
        {
          field: 'recipientProfileId' as any,
          operator: 'eq',
          value: profileId,
        },
        {
          field: 'creationStatus',
          operator: 'eq',
          value: CreationStatus.Approved,
        }
      ],
    }
  });

  return tableComponent();
};

