import { formatDate, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { AccountType, ApprovalStatus, type AccountRequest } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<AccountRequest>({
  resourceName: resourceNames.accountRequest,
});

export const formatApprovalStatus = (status: ApprovalStatus) => {
  switch (status) {
    case ApprovalStatus.Approved:
      return 'success';
    case ApprovalStatus.Rejected:
      return 'danger';
    default:
      return 'pending';
  }
};

export const leadGenerationResource = createResource({
  identifier: resourceNames.leadGeneration,
  name: resourceNames.accountRequest,
  label: 'Lead Generation',
  list: {
    filters: {
      collapsible: 'expand-by-default',
      permanent: [
        {
          field: 'type',
          operator: 'eq',
          value: AccountType.Corporate,
        },
      ],
    },
  },
  fields: defineFields([
    'id',
    'type',
    'name',
    'accountCreatedDate',
    'approvalStatus',
    'contactName',
    'contactNumber',
    'contactEmail',
    'createdAt',
    'deletedAt',
    'idNumber',
    'idType',
    'referenceNumber',
    'modeOfContact',
    'updatedAt',
  ]),
  defaultValues: {},
  allowCreate: false,
  allowDownload: true,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  filterControls: {
    referenceNumber: {
      type: 'text',
      config: {
        label: 'Reference Number',
      },
      operator: 'contains',
    },
    name: {
      type: 'text',
      config: {
        label: 'Company Name',
      },
      operator: 'contains',
    },
    createdAt: {
      type: 'daterange',
      config: {
        label: 'Application Date',
      },
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'referenceNumber',
      header: 'Transaction Reference Number',
      accessorKey: 'referenceNumber',
      cell: (data) => {
        const referenceNumber = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {referenceNumber}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'name',
      header: 'Company Name',
      accessorKey: 'name',
    },
    {
      id: 'contactName',
      header: 'Contact Person Name',
      accessorKey: 'contactName',
    },
    {
      id: 'contactNumber',
      header: 'Contact Number',
      accessorKey: 'contactNumber',
    },
    {
      id: 'contactEmail',
      header: 'Contact Email',
      accessorKey: 'contactEmail',
    },
    {
      id: 'modeOfContact',
      header: 'Mode of Contact',
      accessorKey: 'modeOfContact',
    },
    {
      id: 'createdAt',
      header: 'Submitted Date',
      accessorKey: 'createdAt',
      cell: (data) => {
        const createdAt = data.cell.getValue<Date>();
        return <>{formatDate(createdAt, { formatType: 'dateAndTime' })}</>;
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Contact details',
        collapsible: 'expand-by-default',
        fields: [
          'referenceNumber',
          'name',
          'contactName',
          'contactNumber',
          'contactEmail',
          'modeOfContact',
          'createdAt',
        ],
        displays: {
          createdAt: {
            label: 'Submitted Date',
            span: 2,
            render: (resource) => {
              const createdAt = resource.createdAt;
              return <>{formatDate(createdAt, { formatType: 'dateAndTime' })}</>;
            },
          },
        },
      }),
    ],
  }),
});
