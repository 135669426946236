import { HiOutlineEye } from 'react-icons/hi';

import { Button, StatusField, Switch } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { Fund, ModificationEntityType } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection, defineRelatedResourceSection } =
  createHelpers<Fund>({
    resourceName: resourceNames.fundInfo,
  });

export const formatFundInfoStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Inactive':
      return 'danger';
    default:
      return 'pending';
  }
};

export const fundInfoResource = createResource({
  name: resourceNames.fundInfo,
  label: 'Fund Info',
  defaultValues: {},
  fields: defineFields([
    'id',
    'fundName',
    'fundCode',
    'shariahFlag',
    'esgFlag',
    'wholeSaleFlag',
    'localFlag',
    'navPrice',
    { fundPolicy: ['currencyCode'] },
    { fundPrices: ['price', 'date'] },
    'cashManagement',
    'trusteeName',
    'launchDate',
    'launchPrice',
    'financialYearEnd',
    'fundSize',
    'fundSizeCurrency',
    'fundSizeDate',
    'totalUnitInCirculation',
    'totalUnitInCirculationDate',
    'annualExpenseRatio',
    'annualExpenseRatioDate',
    'geographicalLocation',
    'fundRiskProfile',
    'distributionType',
    'pricingBasic',
    'annualManagementFee',
    'annualTrusteeFee',
    'distributionPolicy',
    'investmentObj',
    'status',
    'pricingBasic',
    'category',
    'fundType',
    'lipperMyClass',
    'classes',
    'benchmark',
    'assetAllocation',
    'createdAt',
    'updatedAt',
  ]),
  allowDownload: true,
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityType: ModificationEntityType.Fund,
    },
  },
  controls: {
    sections: [
      {
        title: 'Fund Details',
        items: [
          'fundName',
          'fundCode',
          'launchDate',
          'launchPrice',
          'financialYearEnd',
          'investmentObj',
          'trusteeName',
          'status',
        ],
      },
      {
        title: 'Fund Size & Circulation',
        items: [
          'fundSize',
          'fundSizeCurrency',
          'fundSizeDate',
          'totalUnitInCirculation',
          'totalUnitInCirculationDate',
          'annualExpenseRatio',
          'annualExpenseRatioDate',
        ],
      },
      {
        title: 'Fund Characteristics',
        items: [
          'geographicalLocation',
          'fundRiskProfile',
          'distributionType',
          'pricingBasic',
          'annualManagementFee',
          'shariahFlag',
          'esgFlag',
          'wholeSaleFlag',
          'localFlag',
          'cashManagement',
          'category',
          'fundType',
          'lipperMyClass',
          'classes',
          'benchmark',
          'assetAllocation',
        ],
      },
      {
        title: 'Fund Policies',
        items: ['distributionPolicy'],
      },
    ],
    components: {
      fundName: {
        type: 'text',
        config: {
          label: 'Fund Name',
          required: true,
        },
      },
      fundCode: {
        type: 'text',
        config: {
          label: 'Fund Code',
          required: true,
        },
      },
      launchDate: {
        type: 'date',
        config: {
          label: 'Launch Date',
          required: true,
        },
      },
      launchPrice: {
        type: 'number',
        config: {
          label: 'Launch Price',
          required: true,
        },
      },
      fundSize: {
        type: 'number',
        config: {
          label: 'Fund Size',
          required: true,
        },
      },
      fundSizeCurrency: {
        type: 'text',
        config: {
          label: 'Fund Size Currency',
          required: true,
        },
      },
      fundSizeDate: {
        type: 'date',
        config: {
          label: 'Fund Size Date',
          required: true,
        },
      },
      totalUnitInCirculation: {
        type: 'number',
        config: {
          label: 'Total Unit In Circulation',
          required: true,
        },
      },
      totalUnitInCirculationDate: {
        type: 'number',
        config: {
          label: 'Total Unit In Circulation Date',
          required: true,
        },
      },
      annualExpenseRatio: {
        type: 'number',
        config: {
          label: 'Annual Expense Ratio',
          required: true,
        },
      },
      annualExpenseRatioDate: {
        type: 'date',
        config: {
          label: 'Annual Expense Ratio Date',
          required: true,
        },
      },
      geographicalLocation: {
        type: 'text',
        config: {
          label: 'Geographical Location',
          required: true,
        },
      },
      fundRiskProfile: {
        type: 'text',
        config: {
          label: 'Fund Risk Profile',
          required: true,
        },
      },
      distributionType: {
        type: 'text',
        config: {
          label: 'Distribution Type',
          required: true,
        },
      },
      pricingBasic: {
        type: 'text',
        config: {
          label: 'Pricing Basic',
          required: true,
        },
      },
      annualManagementFee: {
        type: 'number',
        config: {
          label: 'Annual Management Fee',
          required: true,
        },
      },
      distributionPolicy: {
        type: 'text',
        config: {
          label: 'Distribution Policy',
          required: true,
        },
      },
      financialYearEnd: {
        type: 'date',
        config: {
          label: 'Financial Year End',
          required: true,
        },
      },
      trusteeName: {
        type: 'text',
        config: {
          label: 'Trustee Name',
          required: true,
        },
      },
      investmentObj: {
        type: 'textarea',
        config: {
          label: 'Investment Objective',
          required: true,
        },
      },
      status: {
        type: 'select',
        config: {
          label: 'Status',
          options: [
            { label: 'Active', value: 'Active' },
            { label: 'Inactive', value: 'Inactive' },
          ],
          required: true,
        },
      },
      shariahFlag: {
        type: 'switch',
        config: {
          label: 'Shariah',
          required: true,
        },
      },
      esgFlag: {
        type: 'switch',
        config: {
          label: 'ESG',
          required: true,
        },
      },
      wholeSaleFlag: {
        type: 'switch',
        config: {
          label: 'Wholesale',
          required: true,
        },
      },
      localFlag: {
        type: 'switch',
        config: {
          label: 'Local',
          required: true,
        },
      },
      cashManagement: {
        type: 'switch',
        config: {
          label: 'Cash Management',
          required: true,
        },
      },
      category: {
        type: 'text',
        config: {
          label: 'Category',
          required: true,
        },
      },
      fundType: {
        type: 'text',
        config: {
          label: 'Fund Type',
          required: true,
        },
      },
      lipperMyClass: {
        type: 'text',
        config: {
          label: 'Lipper MY Class',
          required: true,
        },
      },
      classes: {
        type: 'text',
        config: {
          label: 'Classes',
          required: true,
        },
      },
      benchmark: {
        type: 'text',
        config: {
          label: 'Benchmark',
          required: true,
        },
      },
      assetAllocation: {
        type: 'text',
        config: {
          label: 'Asset Allocation',
          required: true,
        },
      },
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'fundName',
      header: 'Fund Name',
      accessorKey: 'fundName',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.cell.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'fundCode',
      header: 'Fund Code',
      accessorKey: 'fundCode',
    },
    {
      id: 'shariahFlag',
      header: 'Shariah Flag',
      accessorKey: 'shariahFlag',
      cell: (data) => <Switch checked={data.cell.getValue<boolean>()} disabled />,
    },
    {
      id: 'esgFlag',
      header: 'ESG Flag',
      accessorKey: 'esgFlag',
      cell: (data) => <Switch checked={data.cell.getValue<boolean>()} disabled />,
    },
    {
      id: 'wholeSaleFlag',
      header: 'Wholesale Flag',
      accessorKey: 'wholeSaleFlag',
      cell: (data) => <Switch checked={data.cell.getValue<boolean>()} disabled />,
    },
    {
      id: 'localFlag',
      header: 'Local Flag',
      accessorKey: 'localFlag',
      cell: (data) => <Switch checked={data.cell.getValue<boolean>()} disabled />,
    },
    {
      id: 'fundRiskProfile',
      header: 'Risk Profile',
      accessorKey: 'fundRiskProfile',
    },
    {
      id: 'navPrice',
      header: 'Net Asset Value (NAV)',
      accessorKey: 'navPrice',
      cell: (data) =>
        Number(data.cell.getValue<number>()).toLocaleString('en-MY', {
          style: 'currency',
          currency: data.row.original.fundPolicy?.currencyCode,
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }),
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.cell.getValue<string>();
        return <StatusField status={formatFundInfoStatus(status)} label={status} />;
      },
    },
  ],
  filterControls: {
    fundName: {
      type: 'text',
      config: {
        label: 'Fund Name',
      },
      operator: 'contains',
    },
    fundCode: {
      type: 'text',
      config: {
        label: 'Fund Code',
      },
      operator: 'contains',
    },
    shariahFlag: {
      type: 'select',
      config: {
        label: 'Shariah Flag',
        valueType: 'boolean',
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
      operator: 'eq',
    },
    wholeSaleFlag: {
      type: 'select',
      config: {
        label: 'Wholesale Flag',
        valueType: 'boolean',
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
      operator: 'eq',
    },
    localFlag: {
      type: 'select',
      config: {
        label: 'Local Flag',
        valueType: 'boolean',
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
      operator: 'eq',
    },
    esgFlag: {
      type: 'select',
      config: {
        label: 'ESG Flag',
        valueType: 'boolean',
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
      operator: 'eq',
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: [
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
        ],
      },
      operator: 'eq',
    },
  },
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Fund Details',
        collapsible: 'expand-by-default',
        fields: [
          'fundName',
          'fundCode',
          'navPrice',
          'launchDate',
          'launchPrice',
          'financialYearEnd',
          'investmentObj',
          'trusteeName',
        ],
        displays: {
          navPrice: {
            label: 'Net Asset Value (NAV)',
            render: (data: Fund) =>
              Number(data.navPrice)?.toLocaleString('en-MY', {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              }),
          },
        },
      }),
      defineCardSection({
        title: 'Fund Size & Circulation',
        collapsible: 'expand-by-default',
        fields: [
          'fundSize',
          'fundSizeCurrency',
          'fundSizeDate',
          'totalUnitInCirculation',
          'totalUnitInCirculationDate',
          'annualExpenseRatio',
          'annualExpenseRatioDate',
        ],
        displays: {
          fundSize: {
            label: 'Fund Size',
            render: (data: Fund) =>
              Number(data.fundSize)?.toLocaleString('en-MY', {
                style: 'currency',
                currency: data.fundSizeCurrency,
              }),
          },
          totalUnitInCirculation: {
            label: 'Total Unit In Circulation',
            render: (data: Fund) => Number(data.totalUnitInCirculation)?.toLocaleString('en-MY'),
          },
        },
      }),
      defineCardSection({
        title: 'Fund Characteristics',
        collapsible: 'expand-by-default',
        fields: [
          'geographicalLocation',
          'fundRiskProfile',
          'distributionType',
          'pricingBasic',
          'annualManagementFee',
          'annualTrusteeFee',
          'shariahFlag',
          'esgFlag',
          'wholeSaleFlag',
          'localFlag',
          'cashManagement',
          'category',
          'fundType',
          'lipperMyClass',
          'classes',
          'benchmark',
          'assetAllocation',
        ],
        displays: {
          shariahFlag: {
            label: 'Shariah',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.shariahFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.shariahFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.shariahFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          esgFlag: {
            label: 'ESG Flag',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.esgFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.esgFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.esgFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          wholeSaleFlag: {
            label: 'Wholesale Flag',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.wholeSaleFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.wholeSaleFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.wholeSaleFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          localFlag: {
            label: 'Local Flag',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.localFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.localFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.localFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          cashManagement: {
            label: 'Cash Management',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.cashManagement} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.cashManagement === true ? 'Yes' : 'No'}
                  newValue={newValues?.cashManagement === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          lipperMyClass: {
            label: 'Lipper Malaysian Classification',
          },
        },
      }),
      defineCardSection({
        title: 'Fund Policies',
        collapsible: 'expand-by-default',
        fields: [
          'distributionPolicy',
          {
            fundPolicy: [
              'currencyCode',
              'minInitInvestment',
              'minSubsequentInvestment',
              'minRedemptionUnit',
              'minHoldingUnit',
            ],
          },
        ],
        displays: {
          'fundPolicy.minInitInvestment': {
            label: 'Min Initial Investment',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minInitInvestment)?.toLocaleString('en-MY'),
          },
          'fundPolicy.minSubsequentInvestment': {
            label: 'Min Subsequent Investment',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minSubsequentInvestment)?.toLocaleString('en-MY'),
          },
          'fundPolicy.minRedemptionUnit': {
            label: 'Min Redemption Unit',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minRedemptionUnit)?.toLocaleString('en-MY'),
          },
          'fundPolicy.minHoldingUnit': {
            label: 'Min Holding Unit',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minHoldingUnit)?.toLocaleString('en-MY'),
          },
        },
      }),
      defineRelatedResourceSection({
        title: 'Fund Documents',
        relatedResourceName: 'FundDocument',
        relatedResourceFields: ['displayName', 'documentLink'],
        fields: ['id', 'fundCode'],
        filterConfig: {
          collapsible: 'expand-by-default',
        },
        getFilterConfig: ({ resource }) => ({
          permanent: [
            {
              field: 'fundCode',
              operator: 'eq',
              value: resource?.fundCode,
            },
          ],
        }),
        columns: () => [
          {
            id: 'displayName',
            header: 'Display Name',
            accessorKey: 'displayName',
          },
          {
            id: 'documentLink',
            header: 'Document Link',
            accessorKey: 'documentLink',
            cell: (data) => {
              return (
                <a href={data.cell.getValue<string>()} target="_blank" rel="noopener noreferrer">
                  <Button variant={'solid'} size="sm" className="gap-2">
                    <HiOutlineEye className="h-4 w-4" />
                    View
                  </Button>
                </a>
              );
            },
          },
        ],
      }),
    ],
  }),
});
