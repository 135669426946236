import { Card, CardContent } from '@amfintech/react-admin-ui';

import { Profile } from '~/api';

export const ProfileInfoPage = ({ profile }: { profile?: Profile }) => {
  return (
    profile && (
      <Card>
        <CardContent className="p-4">
          <div className="flex items-center space-x-4 mb-4">
            <div className="flex-1">
              <h3 className="text-lg font-semibold">Profile Details</h3>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            {Object.entries(profile).map(([key, value]) => {
              if (key === 'accounts' || key === 'users') {
                return null;
              }
              return (
                <div key={key}>
                  <span className="block text-sm font-medium text-gray-700 mb-1">{key}</span>
                  <p className="text-sm text-gray-600">{value?.toString() ?? '-'}</p>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
    )
  );
};
